import React from 'react';
import { connect } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';
import Preloader from '../Preloader';
import moment from 'moment';
import { HiOutlineBolt, HiOutlinePaperClip, HiOutlineXMark, HiPaperAirplane } from 'react-icons/hi2';
import ChatMediaPreview from './ChatMediaPreview';
import { uploadMedia } from '../../services/upload';
import { timerRenderer } from '../../services/dates';
import EmojiPicker from '../Base/EmojiPicker';
import Countdown from 'react-countdown';
import ConfirmModal from '../Base/ConfirmModal';
import QuickReplyList from '../QuickReplies/QuickReplyList';
import { Tooltip } from '@material-ui/core';

class QuickMessage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messageData: {
                text: '',
                media: []
            },
            sending: false,
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            confirmQuickReply: false
        };

        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMediaTrigger = this.handleMediaTrigger.bind(this);
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
        this.handleEmojiPick = this.handleEmojiPick.bind(this);
        this.handleConfirmQuickReply = this.handleConfirmQuickReply.bind(this);
        this.handleQuickReplySelect = this.handleQuickReplySelect.bind(this);
        this.handleQuickRepliesToggle = this.handleQuickRepliesToggle.bind(this);
    }

    handleMessageChange(e){
        if(e.target.value.toLowerCase() === '/r'){
            return this.setState({
                messageData: {
                    text: ''
                },
                quickRepliesShown: true
            });
        }

        this.setState(prevState => ({ messageData: {
            ...prevState.messageData,
            text: e.target.value
        }}));
    }

    async handleSubmit(e){
        e.preventDefault();
        this.setState({ sending: true, confirmQuickReply: false });

        let media = null;
        if(this.state.mediaFile && !this.state.mediaFile.url){
            const uploadResponse = await uploadMedia(this.props.auth.selectedChatbot.token, this.state.mediaFile);
            if(uploadResponse.url){
                media = {
                    url: uploadResponse.url,
                    name: this.state.mediaFile.name,
                    type: this.state.mediaFile.type
                };
            }
        }

        if(this.state.mediaFile && this.state.mediaFile.url){
            media = this.state.mediaFile;
        }

        if(media){
            this.setState(prevState => ({ messageData: { ...prevState.messageData, media: [media] } }));
        }

        await this.props.onSend(this.state.messageData, this.props.conversation);
        this.setState({ messageData: { text: '', media: [] }, sending: false });
    }

    handleMediaTrigger(input){
        input.click();
    }

    handleConfirmQuickReply(e){
        e.preventDefault();
        this.setState(prevState => ({ confirmQuickReply: !prevState.confirmQuickReply }))
    }

    handleMediaChange(e){
        this.setState({ hasMedia: true, loadingMediaPreview: true });
        if(e.target.files && e.target.files[0]){
            const file = e.target.files[0];
            this.setState({ mediaFile: file });
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ mediaPreview: e.target.result, loadingMediaPreview: false });
            }

            reader.readAsDataURL(file);
        }
    } 
    
    handleRemoveMedia(){
        this.setState({
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            loadingMediaPreview: false
        });
    }

    handleEmojiPick(emojiData){
        this.setState(prevState => ({
            messageData: {
                ...prevState.messageData,
                text: prevState.messageData.text + emojiData.emoji
            }
        }))
    }

    handleQuickRepliesToggle(){
        this.setState(prevState => ({ quickRepliesShown: !prevState.quickRepliesShown }), () => {
            if(this.state.quickRepliesShown){
                this.chatMessageTextArea.focus();
            }
        })
    }

    handleQuickReplySelect(quickReply){
        this.handleQuickRepliesToggle();
        this.setState(prevState => ({
            messageData: {
                ...prevState.messageData,
                text: quickReply.message_data.text || prevState.message
            },
            hasMedia: quickReply.message_data.media && quickReply.message_data.media.length > 0 ? quickReply.message_data.media[0] : false,
            mediaFile: (quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0],
            mediaPreview: (quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0].url,
        }));
    }

    render(){
        const placeholder = this.state.quickRepliesShown ? 'Type to search quick replies' : (this.props.placeholder || 'Enter your message to user');
        return (
            <div className="quick_message__outer">
                {this.props.label && <label className="flex-middle">{this.props.label} - Time remaining: <div className="queue_badge timer"><Countdown date={moment(this.props.helpRequest.createdAt).add(23.5, 'hours').toDate()} renderer={timerRenderer}/></div> </label>}
                {(this.state.hasMedia && this.state.mediaPreview) && <ChatMediaPreview handleRemoveMedia={this.handleRemoveMedia} loadingMediaPreview={this.state.loadingMediaPreview} mediaPreview={this.state.mediaPreview} mediaFile={this.state.mediaFile}/>}
                <form className="quick_message internal_chat_room__input_area" onSubmit={this.handleConfirmQuickReply}>
                    <div className='quick_message_text_area'>
                        {(this.state.quickRepliesShown) && <QuickReplyList messageValue={this.state.messageData.text} currentConversation={this.props.conversation} onSelect={this.handleQuickReplySelect}/>}
                        <TextareaAutosize ref={(input) => { this.chatMessageTextArea = input; }} disabled={this.state.sending} value={this.state.messageData.text} onChange={this.handleMessageChange} placeholder={!this.state.sending ? placeholder : 'Sending message...'}/>                    
                    </div>
                    <button type='submit' className='send_button' disabled={this.state.sending || (this.state.messageData.text.trim().length === 0)}>{!this.state.sending ? <HiPaperAirplane/> : <Preloader color="white" size="15"/>}</button>
                </form> 
                <div className='chat_message_bar__actions'>
                    <Tooltip title="Attach file" arrow placement='top'><div className="chat_message_bar__action" onClick={() => { this.handleMediaTrigger(this.mediaUploadElement) }}><HiOutlinePaperClip/></div></Tooltip>
                    <Tooltip title={this.state.quickRepliesShown ? 'Close quick replies' : 'Show quick replies'} arrow placement='top'><div className="chat_message_bar__action" onClick={this.handleQuickRepliesToggle}>{!this.state.quickRepliesShown ? <HiOutlineBolt/> : <HiOutlineXMark/>}</div></Tooltip>          
                    <EmojiPicker handleEmojiPick={this.handleEmojiPick}/>
                </div>
                <input ref={input => this.mediaUploadElement = input} type="file" style={{display: 'none'}} onChange={this.handleMediaChange}/>                
                <ConfirmModal confirmText="Send and Resolve" title="Send and Resolve" description="Please note sending this quick reply will mark this help request as resolved and the text bar will be removed." isOpen={this.state.confirmQuickReply} onConfirm={this.handleSubmit} onRequestClose={this.handleConfirmQuickReply}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(QuickMessage);