
import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import NotFoundPage from '../components/NotFoundPage';
import LoginPage from '../components/Login/LoginPage';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ChatsPage from '../components/Chats/ChatsPage';
import LeadsPage from '../components/Leads/LeadsPage';
import SetPasswordPage from '../components/Password Set/SetPasswordPage';
import CompanySelectPage from '../components/CompanySelectPage';
import { history } from '../helpers/history';
import AgentPage from '../components/Agents/AgentPage';
import HelpRequestPage from '../components/HelpRequests/HelpRequestPage';
import MeetingRoom from '../components/Meetings/MeetingRoom';
import SettingsPage from '../components/Settings/SettingsPage';
import AnalyticsPage from '../components/Analytics/AnalyticsPage';
import AdminRoute from './AdminRoute';
import AdminSettingsPage from '../components/AdminSettings/AdminSettingsPage';
import PasswordResetPage from '../components/PasswordReset/PasswordResetPage';
import ResetPassword from '../components/PasswordReset/ResetPassword';
import UnansweredQuestionsPage from '../components/UnansweredQuestions/UnansweredQuestionsPage';
import PendingQuestionsPage from '../components/UnansweredQuestions/PendingQuestionsPage';
import RejectedQuestionsPage from '../components/UnansweredQuestions/RejectedQuestionsPage';
import ErrorPage from '../components/Errors/ErrorPage';
import WhatsAppTemplateSenderPage from '../components/WhatsAppTemplateSender/WhatsAppTemplateSenderPage';
import WhatsAppTemplateCreatePage from '../components/WhatsAppTemplateSender/WhatsAppTemplateCreatePage';
import WhatsAppTemplatePage from '../components/WhatsAppTemplateSender/WhatsAppTemplatePage';
import WhatsAppPushNotifcationNewJobPage from '../components/WhatsAppTemplateSender/WhatsAppPushNotifcationNewJobPage';

const AppRouter = () => (
    <Router history={history}>
        <div>
            <Switch>
                <PrivateRoute path="/" exact={true} component={ChatsPage}/>
                <PrivateRoute path="/chats" component={ChatsPage} allowedRoles={['admin', 'manager', 'agent']}/>
                <PrivateRoute path="/leads" component={LeadsPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/profile" component={AgentPage} allowedRoles={['admin', 'manager', 'agent']}/>
                <PrivateRoute path="/help-requests/:helpRequestId" component={HelpRequestPage}/>
                <PrivateRoute path="/help-requests" component={HelpRequestPage}/>
                <PrivateRoute path="/unanswered-questions/pending" component={PendingQuestionsPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/unanswered-questions/rejected" component={RejectedQuestionsPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/unanswered-questions" component={UnansweredQuestionsPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/settings" component={SettingsPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/analytics" component={AnalyticsPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/whatsapp-push-notifications/send" component={WhatsAppPushNotifcationNewJobPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/whatsapp-push-notifications/templates/create" component={WhatsAppTemplateCreatePage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/whatsapp-push-notifications/templates" component={WhatsAppTemplatePage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/whatsapp-push-notifications" component={WhatsAppTemplateSenderPage} allowedRoles={['admin', 'manager']}/>
                <PrivateRoute path="/errors" component={ErrorPage} allowedRoles={['admin']}/>
                <AdminRoute path="/admin" component={AdminSettingsPage}/>
                <Route path="/set-password" component={SetPasswordPage}/>
                <Route path="/company" component={CompanySelectPage}/>
                <PublicRoute path="/login" exact={true} component={LoginPage}/>
                <PublicRoute path="/reset-password/:id" component={ResetPassword}/>
                <PublicRoute path="/reset-password" component={PasswordResetPage}/>
                <Route path="/meeting/:meetingId" exact={true} component={MeetingRoom}/>
                <Route path="/meeting" component={MeetingRoom}/>
                <Route component={NotFoundPage}/>
            </Switch>
        </div>
    </Router>
);

export default AppRouter;