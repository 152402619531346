import React from 'react';
import AgentHistory from '../AgentHistory';
import Preloader from '../Preloader';
import ChatNotes from './ChatNotes';
import HelpRequestWidget from './HelpRequestWidget';
import UserInfoList from './UserInfoList';
import Widget from './Widget';
import { HiOutlineChevronLeft, HiOutlineChevronRight, HiOutlineXMark, HiOutlineTrash } from 'react-icons/hi2';
import EventsWidget from './EventsWidget';
import TagWidget from '../Tags/TagWidget';

export default class ChatSidebar extends React.Component{
    render(){
        const widgets = [
            {
                title: 'User information',
                component: <UserInfoList showActions={true} handleDeleteTrigger={this.props.handleDeleteTrigger} conversation={this.props.currentConversation}/>,
                actions: this.props.currentAgent && this.props.currentAgent.role === 'admin' ? [{
                    title: 'Delete Conversation',
                    icon: <HiOutlineTrash/>,
                    onClick: () => { this.props.handleDeleteTrigger(this.props.currentConversation) }
                }] : undefined
            },
            {
                title: 'Notes',
                component: <ChatNotes chat={this.props.currentConversation} setCurrentConversation={this.props.setCurrentConversation}/>
            },
            {
                title: 'Tags',
                component: <TagWidget chat={this.props.currentConversation}/>
            },
            {
                title: 'Agents',
                component: <AgentHistory conversation={this.props.currentConversation}/>,
                hidden: !(this.props.currentConversation && this.props.currentConversation.agents_connected && this.props.currentConversation.agents_connected.length > 0) && !(this.props.currentConversation && this.props.currentConversation.agent_history && this.props.currentConversation.agent_history.length > 0)
            },
            {
                title: 'Last Help Request',
                component: <HelpRequestWidget helpRequests={(this.props.currentConversation && this.props.currentConversation.helpRequests) && this.props.currentConversation.helpRequests}/>,
                hidden: !(this.props.currentConversation && this.props.currentConversation.helpRequests && this.props.currentConversation.helpRequests.length > 0)
            },
            {
                title: 'Events',
                component: <EventsWidget events={(this.props.currentConversation && this.props.currentConversation.events) && this.props.currentConversation.events}/>,
                hidden: !(this.props.currentConversation && this.props.currentConversation.events && this.props.currentConversation.events.length > 0)
            },
        ]

        return (
            <div className={`chats_sidebar ${this.props.mobileSidebarExpanded ? 'mobile_expanded' : ''} ${this.props.sidebarExpanded ? '' : 'collapsed'}`}>
                { !this.props.loadingConversation ?
                    this.props.currentConversation && (
                        <div className="chats_sidebar_inner">
                            <div className="chats_sidebar_widgets">
                                <div className="chats_sidebar_collapse" onClick={this.props.handleSidebarTrigger}>{this.props.sidebarExpanded ? <HiOutlineChevronRight/> : <HiOutlineChevronLeft/>}</div>
                                <div className="chats_sidebar_mobile_collapse" onClick={this.props.handleMobileSidebarTrigger}><HiOutlineXMark/></div>
                                {widgets.map((widget, index) => {
                                    return !widget.hidden && <Widget key={index} title={widget.title} component={widget.component} actions={widget.actions}/>
                                })}
                            </div>
                        </div>
                    ) : <Preloader/>}
            </div>
        );
    };
}