import React from 'react';
import { connect } from 'react-redux';
import { HiOutlineXMark, HiOutlineArrowLeft } from 'react-icons/hi2';
import Preloader from '../Preloader';
import { companyService } from '../../services/company';
import { newChatHandle, groupChatCreate } from '../../services/internalChats';
import InternalAgentListItem from './InternalAgentsListItem';
import InternalAgentSearch from './InternalAgentSearch';
import GroupChatIcon from './GroupChatIcon';
import CreateGroupChatModal from './CreateGroupChatModal';
import LoadingModal from '../Base/LoadingModal';

class InternalChatNewChat extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            initAgents: [],
            agents: [],
            isSearching: false,
            loadingAgents: true,
            searchTerm: '',
            createGroupChatOpen: false,
            creatingGroupChat: false
        }; 

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleNewChatItemClick = this.handleNewChatItemClick.bind(this);
        this.handleCreateGroupChat = this.handleCreateGroupChat.bind(this);
        this.handleGroupChatCreateToggle = this.handleGroupChatCreateToggle.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentDidMount(){
        companyService.getCompanyAgents(this.props.auth.selectedCompany, this.props.auth.agent.token).then(agents => {
            const agentsWithoutCurrent = agents.filter(agent => agent._id !== this.props.auth.agent.agent._id);
            this.setState({
                loadingAgents: false,
                agents: agentsWithoutCurrent,
                initAgents: agentsWithoutCurrent
            });
        });
    }

    handleSearchChange(e){
        this.setState({ searchTerm: e.target.value }, () => {
            const searchTerm = this.state.searchTerm;
            if(searchTerm.trim().length > 0){
                this.setState(prevState => ({ isSearching: true, agents: prevState.initAgents.filter(agent => {
                    let isMatch = false;
                    if(agent.first_name.toLowerCase().includes(searchTerm) || agent.last_name.toLowerCase().includes(searchTerm) || agent.email.toLowerCase().includes(searchTerm)){
                        isMatch = true;
                    }
    
                    return isMatch;
                }) }));
            }else{
                this.clearSearch();
            }
        });
    }

    clearSearch(){
        this.setState(prevState => ({ searchTerm: '', isSearching: false, agents: prevState.initAgents }));
    }

    handleNewChatItemClick(agent){
        this.setState({ loadingAgents: true })
        newChatHandle(this.props.auth.agent.agent._id, agent._id, this.props.auth.selectedChatbot.token).then((chat) => {
            this.props.handleSelectChat(chat);
        });
    }

    handleCreateGroupChat(participants){
        this.setState({ creatingGroupChat: true, createGroupChatOpen: false })
        groupChatCreate([...participants, this.props.auth.agent.agent._id], this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id).then((chat) => {
            this.props.handleSelectChat(chat);
            this.setState({ creatingGroupChat: false })
        });
    }

    handleGroupChatCreateToggle(createGroupChatOpen){
        this.setState({ createGroupChatOpen })
    }

    render(){
        return (
            <div className="internal_chat_new_chat">                         
                <div className="internal_chat_widget__title">New Chat <div className="internal_chat_widget__title__actions"><div className="internal_chat_widget__close_chat" onClick={this.props.handleNewChatClick} title="Back to chats"><HiOutlineArrowLeft/></div><div className="internal_chat_widget__close_chat" title="Close" onClick={this.props.handleToggleChat}><HiOutlineXMark/></div></div></div>   
                <div className="internal_chat_widget__content">
                    {!this.state.loadingAgents ? 
                        <div className="internal_chat_widget__content_inner">
                            <div className="internal_chat_top_bar">
                                <InternalAgentSearch searchTerm={this.state.searchTerm} clearSearch={this.clearSearch} isSearching={this.state.isSearching} handleSearchChange={this.handleSearchChange}/>
                            </div>
                            <div className="internal_agent_list">
                                <div className="internal_agent_list_item" onClick={() => { this.handleGroupChatCreateToggle(true) }}>
                                    <GroupChatIcon size="50"/>
                                    <div className="internal_agent_list_item__details">
                                        <div className="internal_agent_list_item__details__name text_truncate">Create group chat</div>
                                        <div className="status_display"><span className="timestamp">Chat with multiple agents.</span></div>
                                    </div>
                                </div>
                                {this.state.agents.map(agent => <InternalAgentListItem key={agent._id} handleNewChatItemClick={this.handleNewChatItemClick} agent={agent}/>)}
                            </div>
                        </div> : 
                    <Preloader/>} 
                </div>
                <CreateGroupChatModal isOpen={this.state.createGroupChatOpen} handleCreateGroupChat={this.handleCreateGroupChat} agents={this.state.agents} onRequestClose={() => { this.handleGroupChatCreateToggle(false) }}/>
                <LoadingModal text="Creating group chat" isOpen={this.state.creatingGroupChat}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalChatNewChat);