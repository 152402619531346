import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { agentService } from '../../services/agents';
import AlertBox from '../AlertBox';
import { validatePassword } from '../../services/password';

class AgentResetForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            currentPassword: '',
            currentPasswordValid: false,
            errorMessage: undefined,
            validatingPassword: false,
            newPassword: '',
            newPasswordConfirm: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCurrentPasswordSubmit = this.handleCurrentPasswordSubmit.bind(this);
        this.handleSetNewPasswordSubmit = this.handleSetNewPasswordSubmit.bind(this);
    }

    handleChange(e){
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCurrentPasswordSubmit(e){
        e.preventDefault();
        this.setState({ validatingPassword: true, errorMessage: undefined });
        agentService.validatePassword(this.props.auth.agent.agent.email, this.state.currentPassword).then(agent => { 
            this.setState({ currentPasswordValid: true, validatingPassword: false })
        }).catch(e => { 
            this.setState({ errorMessage: 'Password is incorrect', validatingPassword: false })
        })
    }

    handleSetNewPasswordSubmit(e){
        e.preventDefault();
        this.setState({ settingPassword: true, errorMessage: undefined });
        agentService.setPassword(this.props.auth.agent.token, this.state.newPassword).then(() => {
            this.setState({ settingPassword: false, passwordSet: true });
        }).catch(e => {
            this.setState({ settingPassword: false, passwordSet: false, errorMessage: 'Failed to reset password' });
        });
    }

    render(){
        let passwordValid = validatePassword(this.state.newPassword, this.state.newPasswordConfirm);
        return (
            <div className="agent_password_reset">
                {!this.state.currentPasswordValid ? (
                    <form autoComplete="new-password" onSubmit={this.handleCurrentPasswordSubmit}>
                        <div className="form-group">
                            <label htmlFor="currentPassword">Current Password</label>
                            <input type="password" name="currentPassword" disabled={this.state.validatingPassword} autoComplete="new-password" value={this.state.currentPassword} placeholder="Enter your current password" onChange={this.handleChange}/>
                            {this.state.errorMessage && <AlertBox type="alert-danger">{this.state.errorMessage}</AlertBox>}
                        </div>
                        <button className="btn" disabled={this.state.currentPassword.trim().length === 0 || this.state.validatingPassword}>{!this.state.validatingPassword ? 'Submit' : <Preloader color="white" size="14px" padding="0px 1rem"/>}</button>
                    </form>
                ) : (
                    <div>
                        {!this.state.passwordSet ? (
                            <form onSubmit={this.handleSetNewPasswordSubmit}>
                                <div className="form-group">
                                    <div className="form-group">
                                        <label htmlFor="newPassword">New Password</label>
                                        <input type="password" name="newPassword" autoComplete="new-password" value={this.state.newPassword} placeholder="Enter your new password" onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newPasswordConfirm">Confirm New Password</label>
                                    <input type="password" name="newPasswordConfirm" autoComplete="new-password" value={this.state.newPasswordConfirm} placeholder="Confirm your new password" onChange={this.handleChange}/>                             
                                    {passwordValid.message && <AlertBox type="alert-danger">{passwordValid.message}</AlertBox>}
                                    {this.state.errorMessage && <AlertBox type="alert-danger">{this.state.errorMessage}</AlertBox>}
                                </div>
                                <button className="btn" disabled={!passwordValid.isValid}>{!this.state.validatingPassword ? 'Submit' : <Preloader color="white" size="14px" padding="0px 1rem"/>}</button>               
                            </form>
                        ) : (
                            <div>
                                <AlertBox type="alert-success"><b>Success:</b> Your password has been successfully updated.</AlertBox>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AgentResetForm);