import { HiOutlineFunnel, HiMagnifyingGlass } from 'react-icons/hi2';

const getNumberOfFilters = (filters) => {
    let count = 0;
    Object.keys(filters).forEach(e => {
        if(filters[e] != null && e !== 'agentView'){
            count++;
        }
    })

    return count;
}

const ChatListActions = (props) => {
    let filtersApplied = getNumberOfFilters(props.filters);
    return (
        <div className="chat_list_actions">
            {(props.filters && filtersApplied > 0) && <div className='badge small_badge'>{filtersApplied} filter{filtersApplied !== 1 && 's'} applied</div>}
            {!props.isSearched ? (
                <div className="chat_list_actions__inner">
                    <div className="chat_list_actions__container">                        
                        <div className={`chat_list__action ${props.sortShown ? 'selected' : ''}`} title="Sort" onClick={props.handleSortTrigger}><HiOutlineFunnel/>Sort</div>
                        <div className={`chat_list__action ${props.filtersShown ? 'selected' : ''}`} title="Filter" onClick={props.handleFilterTrigger}><HiOutlineFunnel/>Filter</div>
                        <div className={`chat_list__action ${props.searchShown ? 'selected' : ''}`} title="Search" onClick={props.handleSearchTrigger}><HiMagnifyingGlass/>Search</div>
                    </div>
                </div>
            ) : <div className="chat_list_actions_text">{props.searching ? <div>Search in progress...</div> : <div>Showing results for <b>{props.termSearched}</b></div>}</div>}
        </div>
    );
};

export default ChatListActions;