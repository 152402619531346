import ScrollableFeed from "react-scrollable-feed";
import { HiChatBubbleLeftRight, HiOutlineChevronDoubleUp } from 'react-icons/hi2';
import InternalChatRoomMessageItem from "./InternalChatRoomMessageItem";
import ChatDateBadge from "../Chats/ChatDateBadge";
import LoadMore from "../Base/LoadMore";
import React from 'react';
import { toChatDate } from "../../services/dates";

class InternalChatRoomMessages extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messages: this.props.messages,
            loadingMore: false
        };

        this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
        this.scrollableRef = React.createRef();
    }

    componentDidUpdate(prevProps){
        if(prevProps.messages !== this.props.messages){
            const messages = this.props.messages.map(currentMessage => ({ ...currentMessage, new_message: true }));;
            this.setState({ messages });
        }
    }

    async handleLoadMoreClick(){
        this.setState({ loadingMore: true })

        if(this.scrollableRef.current){
            const chatsElement = this.scrollableRef.current.wrapperRef.current;
            const scrollHeight = chatsElement.scrollHeight;

            await this.props.handleLoadMoreMessages();
            this.setState({ loadingMore: false })
            this.scrollableRef.current.props.animateScroll(chatsElement, (chatsElement.scrollHeight - scrollHeight));
        }
    }   

    render(){
        const renderedDates = [];
        return (
            <div className="internal_chat_room__message_container" >
                {this.state.messages.length > 0 ? 
                    <ScrollableFeed className="internal_chat_room__message_list" ref={this.scrollableRef}>
                        {this.props.hasMoreMessages && <LoadMore icon={<HiOutlineChevronDoubleUp/>} loadingMore={this.state.loadingMore} onClick={this.handleLoadMoreClick}/>}
                        {this.state.messages.map((message, index) => {
                            let dateToRender = toChatDate(message.createdAt);
                            if(!renderedDates.includes(dateToRender)){
                                renderedDates.push(dateToRender);
                            }else{
                                dateToRender = undefined;
                            }
                            return (
                                <div key={index}>
                                    {dateToRender && <ChatDateBadge date={dateToRender}/>}
                                    <InternalChatRoomMessageItem key={message._id} currentAgent={this.props.currentAgent} message={message}/>
                                </div>
                            )
                        })}
                    </ScrollableFeed> : (
                    <div className="internal_chats_list__message_badge">
                        <HiChatBubbleLeftRight className="internal_chats_list__message_badge__icon"/> 
                        No messages yet
                    </div>
                )}
            </div>
        );
    }
};

export default InternalChatRoomMessages;