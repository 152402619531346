import Select from 'react-select';

const HelpRequestFilters = (props) => {
    const orderByOptions = [ { value: 'desc', label: 'Newest to Oldest'}, { value: 'asc', label: 'Oldest to Newest'}];
    const showOptions = [ { value: true, label: 'Resolved'}, { value: false, label: 'Unresolved'}];

    return (
        <div className="filters">
            <div className="top_bar_filter"><label>Order by</label><Select classNamePrefix="custom_select"  className="filter form_select" onChange={(e) => { props.filterChange(e, 'orderBy') }} value={orderByOptions.find(filterOption => props.filters.orderBy === filterOption.value)} options={orderByOptions}/></div>
            <div className="top_bar_filter"><label>Show</label><Select classNamePrefix="custom_select"  className="filter form_select" isClearable={true} onChange={(e) => { props.filterChange(e, 'resolved') }} value={showOptions.find(filterOption => props.filters.resolved === filterOption.value)} options={showOptions}/></div>
            <div className="top_bar_filter"><label>Queue</label><Select classNamePrefix="custom_select"  className="filter form_select" isClearable={true} isLoading={props.loadingQueues} onChange={(e) => { props.filterChange(e, "queue") }} options={props.queues && props.queues.map(queueItem => ({ value: queueItem._id, label: queueItem.name }))}/></div>
        </div>
    );
};

export default HelpRequestFilters;