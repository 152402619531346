import React from 'react';
import { connect } from 'react-redux';
import { HiChatBubbleLeftRight, HiOutlinePlus } from 'react-icons/hi2';
import InternalChatListItem from './InternalChatListItem';
import LoadMore from '../Base/LoadMore';

class InternalChatsList extends React.Component{
    render(){
        return (
            <div className="internal_chats_list">
                {this.props.internalChats.chats && this.props.internalChats.chats.length > 0 ? (
                    <div>
                    {this.props.internalChats.chats.map(internalChat => <InternalChatListItem key={internalChat._id} handleSelectChat={this.props.handleSelectChat} chat={internalChat} currentAgent={this.props.auth.agent.agent}/>)}
                    {this.props.internalChats.hasMore && <LoadMore onClick={this.props.handleLoadMore} loadingMore={this.props.internalChats.loadingMore}/>}
                    </div>
                ) : (
                    <div className="internal_chats_list__message_badge">
                        <HiChatBubbleLeftRight className="internal_chats_list__message_badge__icon"/> 
                        No chats yet
                        <button className="btn" onClick={this.props.handleNewChatClick}><HiOutlinePlus/>New Conversation</button>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalChatsList);