import Toggle from 'react-toggle'; 
import "react-toggle/style.css" // for ES6 modules
import { HiVideoCamera, HiMicrophone } from 'react-icons/hi2';

const VideoAudioSettings = (props) => {
    return (
        <div className="video_audio_settings">
            <label className="toggle_box">
                <HiVideoCamera/>
                <Toggle disabled={props.togglingVideo} onChange={() => { props.handleVideoToggle(!props.videoEnabled) }} checked={props.videoEnabled}/>
            </label>
            <label className="toggle_box">
                <HiMicrophone/>
                <Toggle onChange={() => { props.handleAudioToggle(!props.microphoneEnabled) }} checked={props.microphoneEnabled}/>
            </label>
        </div>
    );
};

export default VideoAudioSettings;