import React from 'react';
import { connect } from 'react-redux';
import { getJobLogs } from '../../services/job';
import { titleCase } from '../../services/misc';
import LoadMore from '../Base/LoadMore';
import Preloader from '../Preloader';
import onClickOutside from "react-onclickoutside";

class JobLogViewer extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            limit: 30,
            offset: 30,
            loadingLogs: true,
            filters: {

            },
            error: 'Failed to retrieve'
        }

        this.loadJobLogs = this.loadJobLogs.bind(this);
        this.loadMoreClick = this.loadMoreClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(){
        this.loadJobLogs();
    }

    handleClickOutside = evt => {
        this.props.handleClose()
    };

    async loadJobLogs(){
        const logs = await getJobLogs(this.props.auth.selectedChatbot.token, this.props.jobId, this.state.limit + 1, this.state.filters, 'desc');

        const hasMore = logs.length > this.state.limit;
        if(hasMore){
            logs.pop();
        }

        this.setState({ loadingLogs: false, logs, hasMore })
    }

    async loadMoreClick(){
        this.setState(prevState => ({ loadingMore: true, limit: prevState.limit + prevState.offset }), async () => {
            await this.loadJobLogs();
            this.setState({ loadingMore: false });
        });
    }

    render(){
        return (
            <div className='job_log_container'>
                {!this.state.loadingLogs ? (this.state.logs.length ? (
                    <table className='table'>
                        <thead>
                            <tr>
                                <td>Trace ID</td>
                                <td>Message</td>
                                <td>Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.logs.map(log => {
                                let status = log.type.toLowerCase().replace('_log', '').trim();
                                return (
                                    <tr>
                                        <td>{log._id}</td>
                                        <td>{log.message}</td>
                                        <td><div className={`status_badge ${status}`}>{titleCase(status)}</div></td>
                                    </tr>
                                )
                            })}
                            {this.state.hasMore && <td colSpan={4} className='loadMoreColumn'><LoadMore loadingMore={this.state.loadingMore} onClick={this.loadMoreClick}/></td>}
                        </tbody>
                        
                    </table>
                ) : <div className='job_log_no_logs'>No logs found</div>) : <Preloader/>}
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(onClickOutside(JobLogViewer));  