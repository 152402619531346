import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import { agentActions } from '../actions/agents';
import { useLocation } from 'react-router-dom'
import { companyService } from '../services/company';
import LoadingPage from '../components/LoadingPage';
import Dashboard from '../components/Dashboard';

export const PrivateRoute = ({ dispatch, isAuthenticated, component: Component, loggedIn, auth, agent, allowedRoles, ...rest }) => {
    let componentToRoute = undefined;
    const location = useLocation();
    if(agent && !auth.selectedCompany && agent.companies.length === 1){
        dispatch(agentActions.setCurrentCompany(agent.companies[0]));
    }

    if(!localStorage.getItem('user')){
        componentToRoute = <Redirect to="/login" />;
    }else{
        if(agent && !agent.agent_settings.password_set){
            if(location.pathname !== '/set-password'){
                componentToRoute = <Redirect to="/set-password" />;
            }
        }else if(!auth.selectedCompany){
            if(location.pathname !== '/company'){
                componentToRoute = <Redirect to="/company" />;
            }
        }
    }

    if(auth.selectedCompany){
        if(!auth.selectedChatbot){
            componentToRoute = <LoadingPage/>
            companyService.getCompanyChatbots(auth.selectedCompany, auth.agent.token).then((chatbots) => {
                dispatch(agentActions.setCurrentChatbot(chatbots[0]));
            });
        }else{
            if(allowedRoles && !allowedRoles.includes(agent.role)){
                componentToRoute = <Redirect to="/" />;
            }

            if(location.pathname === '/'){
                componentToRoute = <Redirect to="/chats" />;
            }
        }
    }
    

    return (
        <Route {...rest} component={(props) => (
            <div>
                {componentToRoute ? componentToRoute : <Dashboard component={<Component {...props}/>}/>}
            </div>
        )}/>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid,
    auth: state.auth ? state.auth : undefined,
    agent: state.auth.agent ? state.auth.agent.agent : undefined
})

export default connect(mapStateToProps)(PrivateRoute)