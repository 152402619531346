import React from "react";
import ChatTransferListItem from "./ChatTransferListItem";
import { RiUserSharedLine } from 'react-icons/ri';
import { HiOutlineUserGroup } from 'react-icons/hi2';

class ChatTransferList extends React.Component {
    render(){
        let sortedQueues = [
            ...this.props.queues.filter(queue => queue.agents.filter(agent => agent.agent_settings.status === 'available').length > 0 ),
            ...this.props.queues.filter(queue => queue.agents.filter(agent => agent.agent_settings.status === 'available').length === 0)
        ];

        let sortedAgents = [
            ...this.props.agents.filter(agent => agent.agent_settings.status === 'available' && agent._id !== this.props.currentAgent._id),
            ...this.props.agents.filter(agent => agent.agent_settings.status === 'busy' && agent._id !== this.props.currentAgent._id),
            ...this.props.agents.filter(agent => agent.agent_settings.status !== 'busy' && agent.agent_settings.status !== 'available' && agent._id !== this.props.currentAgent._id)
        ];
        if(this.props.searchTerm && this.props.searchTerm.length > 0){
            sortedQueues = sortedQueues.filter(queue => queue.name.toLowerCase().includes(this.props.searchTerm));
            sortedAgents = sortedAgents.filter(agent => agent.first_name.toLowerCase().includes(this.props.searchTerm.toLowerCase()) || agent.last_name.toLowerCase().includes(this.props.searchTerm));
            return (
                <div className="chat_transfer_list">
                    {!this.props.agentPicker && sortedQueues.length > 0 && <div className="chat_transfer_list__subtitle">Queues</div>}
                    {!this.props.agentPicker && sortedQueues.length > 0 && sortedQueues.map((queue) => <ChatTransferListItem currentAgent={this.props.currentAgent} allowConnected={this.props.allowConnected} key={queue._id} allowOffline={this.props.allowOffline}  handleTransfer={this.props.handleTransfer} queue={queue} conversation={this.props.conversation}/>)}
                    {!this.props.queuePicker && sortedAgents.length > 0 && <div className="chat_transfer_list__subtitle">Agents</div>}
                    {!this.props.queuePicker && sortedAgents.length > 0 && sortedAgents.map((agent) => <ChatTransferListItem allowConnected={this.props.allowConnected} key={agent._id} allowOffline={this.props.allowOffline} agentPicker={this.props.agentPicker} handleTransfer={this.props.handleTransfer} agent={agent}  conversation={this.props.conversation}/>)}
                </div>
            );
        }else if(this.props.showing === 'queues'){
            return (
                <div className="chat_transfer_list">
                    {!this.props.queuePicker && <div className="chat_transfer_list__item" onClick={this.props.handleSwitchViewing}><div className="transfer_to_user" title="Transfer to agent"><RiUserSharedLine/></div><div className="chat_transfer_list__item__details"><div className="queues_title">Transfer to agent</div></div></div>}
                    <ChatTransferListItem allowOffline={this.props.allowOffline} handleTransfer={this.props.handleTransfer} queue={{ name: 'All', agents: sortedAgents }}/>
                    {sortedQueues.map((queue) => <ChatTransferListItem allowOffline={this.props.allowOffline} currentAgent={this.props.currentAgent} allowConnected={this.props.allowConnected} key={queue._id} handleTransfer={this.props.handleTransfer} queue={queue} conversation={this.props.conversation}/>)}
                </div>
            );
        }else if(this.props.showing === 'agent'){
            return (
                <div className="chat_transfer_list">
                    {!this.props.agentPicker && <div className="chat_transfer_list__item" onClick={this.props.handleSwitchViewing}><div className="transfer_to_user" title="Transfer to queue"><HiOutlineUserGroup/></div><div className="chat_transfer_list__item__details"><div className="queues_title">Transfer to queue</div></div></div>}
                    {sortedAgents.map((agent) => <ChatTransferListItem allowConnected={this.props.allowConnected} key={agent._id} allowOffline={this.props.allowOffline} agentPicker={this.props.agentPicker} handleTransfer={this.props.handleTransfer} agent={agent}  conversation={this.props.conversation}/>)}
                </div>
            );
        }
    };
};

export default ChatTransferList;