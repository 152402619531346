const initialState = { notificationsExpanded: false, notifications: [] } 

export default function notifications(state = initialState, action){
    switch(action.type){
        case 'NOTIFICATIONS_SET':
            return {
                ...state,
                notifications: action.notifications
            }
        case 'NOTIFICATIONS_SET_EXPANDED':
            return {
                ...state,
                notificationsExpanded: action.notificationsExpanded
            }
        case 'NOTIFICATIONS_CLEAR':
            return initialState;
        default: 
            return state;
    }
}