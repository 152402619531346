import React from 'react';
import { utils } from '../../services/communicationSevices';
import Preloader from '../Preloader';
const { CommunicationIdentityClient } = require('@azure/communication-administration');

export default class Authenticate extends React.Component{
    constructor(props){
        super(props);
        this.userDetailsResponse = undefined;

        this.state = {
            showUserProvisioningAndSdkInitializationCode: false,
            showSpinner: false,
            loggedIn: false
        };
    }

    componentDidMount(){
        this.provisionNewUser()
    }

    provisionNewUser = async () => {
        try{
            this.setState({ showSpinner: true });
            // This code demonstrates how to fetch your connection string
            // from an environment variable.
            const connectionString = 'endpoint=' + this.props.asc_connection_string;
            // Instantiate the identity client
            const identityClient = new CommunicationIdentityClient(connectionString);

            let identityResponse = await identityClient.createUser({  });

            // Issue an access token with the "voip" scope for an identity
            let tokenResponse = await identityClient.issueToken(identityResponse, ["voip"]);
            const { token, user } = tokenResponse;
            this.setState({ id: utils.getIdentifierText(user) });
            await this.props.onLoggedIn({ id: this.state.id, token });
            this.setState({ loggedIn: true });
        } catch(e){
        } finally {
            this.setState({ showSpinner: false });
        }
    }

    render(){
        return (
            <div className="meeting_loader">
                {this.props.company.logo && <img className="meeting_room__display_name_logo" src={this.props.company.logo} alt="Company logo"/>}
                <div className="meeting_room__content_box__body">Please be patient while you are authenticated. Ensure you allow camera and microphone permissions when prompted.</div>
                {this.state.showSpinner && (
                    <Preloader/>
                )}
            </div>
        );
    }
}