import React  from 'react';
import ReactLoading from 'react-loading';

class Preloader extends React.Component {
    render(){
        const color = this.props.color || '#555';
        const size = this.props.size || '25';
        const padding = this.props.padding || '1rem 0';
        const width = this.props.fitToContent ? '100%' : 'auto';
        const height = this.props.fitToContent ? '100%' : 'auto';
        const position = this.props.fitToContent ? 'absolute' : 'inherit';
        const left = this.props.fitToContent ? '0' : 'unset';
        const top = this.props.fitToContent ? '0' : 'unset';
        return (
            <div className="preloader" style={{ padding, width, height, position, left, top }}><ReactLoading type='spin' color={color} height={size + "px"} width={size + "px"}/>{this.props.text && <div className="preloader_text">{this.props.text}</div>}</div>
        );
    }
};

export default Preloader;