import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import { HiOutlineArrowRight, HiOutlineBriefcase, HiOutlineDocumentText, HiOutlineKey, HiOutlinePlus } from 'react-icons/hi2'
import { GrAnnounce } from 'react-icons/gr'
import Select from 'react-select';
import WhatsAppTemplateBodyEditor from './WhatsAppTemplateBodyEditor';
import { createWhatsAppTemplate, toWhatsAppBody } from '../../services/whatsAppTemplateSender';
import WhastAppTemplatePreview from './WhastAppTemplatePreview';
import { MdOutlineImage, MdPlayCircleOutline } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import AlertBox from '../AlertBox';
import ConfirmModal from '../Base/ConfirmModal';
import { history } from '../../helpers/history';
import LoadingModal from '../Base/LoadingModal';

class WhatsAppTemplateCreatePage extends React.Component {
    constructor(props){
        super(props);

        this.languages = [{
            code: 'en',
            label: 'English'
        },{
            code: 'af',
            label: 'Afrikaans'
        },{
            code: 'zu',
            label: 'Zulu'
        }]

        this.headerTypes = ['None', 'Text', 'Media'];
        this.buttonTypes = ['None', 'Call to Action', 'Quick Reply'];

        this.callToActionTypes = ['Visit Website', 'Call Phone Number'];
        this.buttonUrlTypes = ['Static', 'Dynamic'];

        this.maxLengths = {
            templateName: 512,
            headerText: 60,
            body: 1024,
            footer: 60,
            buttonText: 25,
            buttonUrl: 2000,
            buttonPhoneNumber: 20
        }

        this.state = {
            step: 1,
            category: 'transcational',
            headerType: 'None',
            headerMediaType: '',
            buttonType: 'None',
            variables: [],
            callToActionsButtons: [],
            quickReplyButtons: [],
            confirmCreate: false,
            creatingTemplate: false
        }

        this.handleTemplateNameChange = this.handleTemplateNameChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handleHeaderTypeChange = this.handleHeaderTypeChange.bind(this);
        this.handleHeaderTextChange = this.handleHeaderTextChange.bind(this);
        this.handleMediaType = this.handleMediaType.bind(this);
        this.handleButtonType = this.handleButtonType.bind(this);
        this.handleBodyChange = this.handleBodyChange.bind(this);
        this.handleFooterChange = this.handleFooterChange.bind(this);
        this.handleAddNewButton = this.handleAddNewButton.bind(this);
        this.handleCallToActionButtonUpdate = this.handleCallToActionButtonUpdate.bind(this);
        this.handleQuickReplyUpdate = this.handleQuickReplyUpdate.bind(this);
        this.handleTemplateSubmit = this.handleTemplateSubmit.bind(this);
        this.handleSubmitConfrimTrigger = this.handleSubmitConfrimTrigger.bind(this);
        this.isValid = this.isValid.bind(this);

        this.isStepOneComplete = this.isStepOneComplete.bind(this);
    }

    handleTemplateNameChange(e){
        const formatted = e.target.value.replace(' ', '_').toLowerCase()
        this.setState({ templateName: formatted.substring(0, this.maxLengths.templateName ) })
    }
    
    handleCategoryChange(category){
        this.setState({ category })
    }

    handleLanguageChange(language){
        this.setState({ language })
    }

    isStepOneComplete(){
        let isComplete = false;
        if(this.state.category && this.state.templateName && this.state.language){
            isComplete = true
        }

        return isComplete;
    }
    
    handleStepChange(step){
        this.setState({ step })
    }

    handleHeaderTypeChange(e){
        this.setState({ headerType: e.value })
    }

    handleHeaderTextChange(e){
        this.setState({ headerText: e.target.value.substring(0, this.maxLengths.headerText) })
    }

    handleMediaType(e){
        this.setState({ headerMediaType: e })
    }
    
    handleButtonType(e){
        this.setState({ buttonType: e.value }, () => {
            if(this.state.callToActionsButtons.length === 0){
                this.handleAddNewButton('call_to_action');
            }
            
            if(this.state.quickReplyButtons.length === 0){
                this.handleAddNewButton('quick_reply');
            }
        })
    }

    handleAddNewButton(type){
        if(type === 'call_to_action'){
            this.setState(prevState => ({ callToActionsButtons: [...prevState.callToActionsButtons, {
                actionType: 'Visit Website',
                urlType: 'Static',
                text: '',
                websiteUrl: '',
                phoneNumber: ''
            }] }))
        }else{
            this.setState(prevState => ({ quickReplyButtons: [...prevState.quickReplyButtons, {
                actionType: 'Quick Reply',
                text: ''
            }] }))
        }
    }

    handleBodyChange(e){
        if(toWhatsAppBody(e).length <= this.maxLengths.body){
            this.setState({ body: e, error: null })
        }else{
            this.setState({ error: 'Body cannot exceed ' + this.maxLengths.body + ' characters'})
        }
    }

    handleCallToActionButtonUpdate(index, name, value){
        let buttons = this.state.callToActionsButtons;
        buttons[index] = {
            ...buttons[index],
            [name]: value
        };

        this.setState({ callToActionsButtons: buttons })
    }

    handleQuickReplyUpdate(index, name, value){
        let buttons = this.state.quickReplyButtons;
        buttons[index] = {
            ...buttons[index],
            [name]: value
        };

        this.setState({ quickReplyButtons: buttons })
    }
    
    handleFooterChange(e){
        this.setState({ footer: e.target.value.substring(0, this.maxLengths.footer) })
    }

    isValid(){
        let isComplete = true;

        if(this.state.headerType.toLowerCase() === 'text' && !(this.state.headerText && this.state.headerText.trim().length > 0)){
            isComplete = false;
        }

        if(this.state.headerType.toLowerCase() === 'media' && !this.state.headerMediaType){
            isComplete = false;
        }

        if(!(this.state.body && toWhatsAppBody(this.state.body).trim().length > 0)){
            isComplete = false;
        }

        if(this.state.buttonType.toLowerCase() === 'call to action'){
            if(!this.state.callToActionsButtons || this.state.callToActionsButtons.length === 0){ isComplete = false; }
            else {
                this.state.callToActionsButtons.forEach(button => {
                    if(button.actionType.toLowerCase() === 'visit website'){
                        if(!(button.text && button.text.length > 0) || !(button.websiteUrl && button.websiteUrl.length > 0)){ isComplete = false }
                    }else if(button.actionType.toLowerCase() === 'call phone number'){
                        if(!(button.text && button.text.length > 0) || !(button.phoneNumber && button.phoneNumber.length > 0)){ isComplete = false }
                    }
                })
            }
        }else if(this.state.buttonType.toLowerCase() === 'quick reply'){
            if(!this.state.quickReplyButtons || this.state.quickReplyButtons.length === 0){ isComplete = false; }
            else {
                this.state.quickReplyButtons.forEach(button => {
                    if(!(button.text && button.text.length > 0)){ isComplete = false }
                })
            }
        }

        return isComplete;
    }

    handleSubmitConfrimTrigger(){
        this.setState(prevState => ({ confirmCreate: !prevState.confirmCreate }))
    }

    async handleTemplateSubmit(){
        try{
            this.setState({ error: null, confirmCreate: false, creatingTemplate: true })
            let components = [];
            let templateBody = {
                name: this.state.templateName,
                category: this.state.category.toUpperCase(),
                language: this.state.language.value
            };

            if(this.state.headerType === 'Text' && this.state.headerText && this.state.headerText.length > 0){
                components.push({
                    type: 'HEADER',
                    format: 'TEXT',
                    text: this.state.headerText
                });
            }else if(this.state.headerType === 'Media' && this.state.headerMediaType && this.state.headerMediaType.length > 0){
                components.push({
                    type: 'HEADER',
                    format: this.state.headerMediaType.toUpperCase()
                });
            }

            components.push({
                type: 'BODY',
                text: toWhatsAppBody(this.state.body)
            })
            
            if(this.state.footer && this.state.footer.length > 0){
                components.push({
                    type: 'FOOTER',
                    text: this.state.footer
                });
            }

            let buttons = null;
            if(this.state.buttonType.toLowerCase() === 'call to action' && this.state.callToActionsButtons && this.state.callToActionsButtons.length > 0){
                let formattedButtons = [];
                this.state.callToActionsButtons.forEach(button => {
                    let formattedButton = {
                        type: button.actionType.toLowerCase() === 'call phone number' ? 'PHONE_NUMBER' : 'URL',
                        text: button.text
                    }

                    if(button.actionType.toLowerCase() === 'call phone number'){
                        formattedButton.phone_number = button.phoneNumber
                    }else{
                        formattedButton.url = button.websiteUrl

                        if(button.urlType.toLowerCase() === 'dynamic'){
                            formattedButton.url += '{{1}}'
                        }
                    }

                    formattedButtons.push(formattedButton)
                });

                buttons = formattedButtons
            }else if(this.state.buttonType.toLowerCase() === 'quick reply' && this.state.quickReplyButtons && this.state.quickReplyButtons.length > 0){
                let formattedButtons = [];
                this.state.quickReplyButtons.forEach(button => {
                    let formattedButton = {
                        type: 'QUICK_REPLY',
                        text: button.text
                    }

                    formattedButtons.push(formattedButton)
                });

                buttons = formattedButtons
            }

            if(buttons){ 
                components.push({
                    type: 'BUTTONS',
                    buttons
                }) 
            }

            templateBody.components = components;
            await createWhatsAppTemplate(this.props.auth.selectedChatbot.token, templateBody);
            history.push('/whatsapp-push-notifications/templates')
        }catch(e){
            this.setState({ error: e.message, creatingTemplate: false });
        }
    }

    render(){
        return (
            <PageContainer>
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">Create New Template</div>
                        <div className='whatsapp_template_builder'>
                            {this.state.step === 1 && (
                                <div className='whatsapp_template_step'>
                                    <div className="form-group">
                                        <label htmlFor="template_name">Category</label>
                                        <div className='whatsapp_template_builder_option_list'>
                                            <div className={`whatsapp_template_builder_option_list_option ${this.state.category === 'utility' ? 'checked' : ''}`} onClick={() => { this.handleCategoryChange('utility') }}>
                                                <div className='form_checkbox'></div>
                                                <div className='whatsapp_template_builder_option_list_option_icon'><HiOutlineBriefcase/></div>
                                                <div className='whatsapp_template_builder_option_list_option_label'>
                                                    <div className='whatsapp_template_builder_option_list_option_label__label'>Utility</div>
                                                    <div className='whatsapp_template_builder_option_list_option_label__description'>Send account updates, order updates, alerts and more to share important information.</div>
                                                </div>
                                            </div>
                                            <div className={`whatsapp_template_builder_option_list_option ${this.state.category === 'marketing' ? 'checked' : ''}`} onClick={() => { this.handleCategoryChange('marketing') }}>
                                                <div className='form_checkbox'></div>
                                                <div className='whatsapp_template_builder_option_list_option_icon'><GrAnnounce/></div>
                                                <div className='whatsapp_template_builder_option_list_option_label'>
                                                    <div className='whatsapp_template_builder_option_list_option_label__label'>Marketing</div>
                                                    <div className='whatsapp_template_builder_option_list_option_label__description'>Send promotional offers, product announcements and more to increase awareness and engagement.</div>
                                                </div>
                                            </div>
                                            <div className={`whatsapp_template_builder_option_list_option ${this.state.category === 'authentication' ? 'checked' : ''}`} onClick={() => { this.handleCategoryChange('authentication') }}>
                                                <div className='form_checkbox'></div>
                                                <div className='whatsapp_template_builder_option_list_option_icon'><HiOutlineKey/></div>
                                                <div className='whatsapp_template_builder_option_list_option_label'>
                                                    <div className='whatsapp_template_builder_option_list_option_label__label'>Authentication</div>
                                                    <div className='whatsapp_template_builder_option_list_option_label__description'>Send codes that allow your customers to access their accounts.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.submitted && !this.state.category && <div className="help-block">Category is required</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="template_name">Template Name</label>
                                        <div className='contained_input'><input type="text" name="templateName" value={this.state.templateName} onChange={this.handleTemplateNameChange} placeholder="Enter your template name" required/><div className='max-length'>{this.state.templateName ? this.state.templateName.length : 0}/{this.maxLengths.templateName}</div></div>
                                        {this.state.submitted && !this.state.template_name && <div className="help-block">Template name is required</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="lanuage">Language</label>
                                        <Select classNamePrefix="custom_select"  className="form_select" onChange={this.handleLanguageChange} value={this.state.language ? { value: this.state.language.code, label: this.state.language.label } : null} options={this.languages.map(language => ({ value: language.code, label: language.label }))}/>
                                        {this.state.submitted && !this.state.template_name && <div className="help-block">Template name is required</div>}
                                    </div>
                                    <div className='whatsapp_template_step__actions'>
                                        <NavLink className='btn' to='/whatsapp-push-notifications/templates'>Back</NavLink>
                                        <button className='btn' disabled={!this.isStepOneComplete()} onClick={() => { this.handleStepChange(2) }}>Next step<HiOutlineArrowRight/></button>
                                    </div>
                                </div>
                            )}
                            {this.state.step === 2 && (
                                <div className='whatsapp_template_step'>
                                    <div className='whatsapp_template_composer'>
                                        {this.editor}
                                        <div className='whatsapp_template_composer_items'>
                                            <div className="form-group">
                                                <div className='whatsapp_template_composer__label' htmlFor="header">Header<div className='whatsapp_template_composer__optional_badge'>Optional</div></div>
                                                <div className='whatsapp_template_composer__label_description'>Add a title or choose which type of media you'll use for this header.</div>
                                                <Select classNamePrefix="custom_select"  className="form_select" isSearchable={false} onChange={this.handleHeaderTypeChange} value={{ value: this.state.headerType, label: this.state.headerType }} options={this.headerTypes.map(hOption => ({ value: hOption, label: hOption }))}/>
                                                {this.state.headerType === 'Text' && (
                                                    <div className="form-group">
                                                        <div className='contained_input'><input type="text" placeholder="Enter your header text" value={this.state.headerText} onChange={this.handleHeaderTextChange} required/><div className='max-length'>{this.state.headerText ? this.state.headerText.length : 0}/{this.maxLengths.headerText}</div></div>
                                                    </div>
                                                )}
                                                {this.state.headerType === 'Media' && (
                                                    <div className='whatsapp_template_builder_option_list'>
                                                        <label>Media Type</label>
                                                        <div className={`whatsapp_template_builder_option_list_option ${this.state.headerMediaType === 'image' ? 'checked' : ''}`} onClick={() => { this.handleMediaType('image') }}>
                                                            <div className='form_checkbox'></div>
                                                            <div className='whatsapp_template_builder_option_list_option_icon'><MdOutlineImage/></div>
                                                            <div className='whatsapp_template_builder_option_list_option_label'>
                                                                <div className='whatsapp_template_builder_option_list_option_label__label'>Image</div>
                                                            </div>
                                                        </div>
                                                        <div className={`whatsapp_template_builder_option_list_option ${this.state.headerMediaType === 'video' ? 'checked' : ''}`} onClick={() => { this.handleMediaType('video') }}>
                                                            <div className='form_checkbox'></div>
                                                            <div className='whatsapp_template_builder_option_list_option_icon'><MdPlayCircleOutline/></div>
                                                            <div className='whatsapp_template_builder_option_list_option_label'>
                                                                <div className='whatsapp_template_builder_option_list_option_label__label'>Video</div>
                                                            </div>
                                                        </div>
                                                        <div className={`whatsapp_template_builder_option_list_option ${this.state.headerMediaType === 'document' ? 'checked' : ''}`} onClick={() => { this.handleMediaType('document') }}>
                                                            <div className='form_checkbox'></div>
                                                            <div className='whatsapp_template_builder_option_list_option_icon'><HiOutlineDocumentText/></div>
                                                            <div className='whatsapp_template_builder_option_list_option_label'>
                                                                <div className='whatsapp_template_builder_option_list_option_label__label'>Document</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <div className='whatsapp_template_composer__label' htmlFor="body">Body</div>
                                                <div className='whatsapp_template_composer__label_description'>Enter the text for your message in the language that you've selected.</div>
                                                <WhatsAppTemplateBodyEditor text={this.state.body} variables={this.state.variables} handleTextChange={this.handleBodyChange} maxLengthText={this.state.body && toWhatsAppBody(this.state.body).length > 10 && <div className='max-length'>{this.state.body && this.state.body.length ? toWhatsAppBody(this.state.body.trim()).length : 0}/{this.maxLengths.body}</div>}/>
                                            </div>
                                            <div className="form-group">
                                                <div className='whatsapp_template_composer__label' htmlFor="footer">Footer<div className='whatsapp_template_composer__optional_badge'>Optional</div></div>
                                                <div className='whatsapp_template_composer__label_description'>Add a short line of text to the bottom of your message template. If you add the marketing opt-out button, the associated footer will be shown here by default.</div>
                                                <div className='contained_input'><input type="text" placeholder="Enter your footer text" onChange={this.handleFooterChange} value={this.state.footer} required/><div className='max-length'>{this.state.footer ? this.state.footer.length : 0}/{this.maxLengths.footer}</div></div>
                                            </div>
                                            <div className="form-group">
                                                <div className='whatsapp_template_composer__label' htmlFor="buttons">Buttons<div className='whatsapp_template_composer__optional_badge'>Optional</div></div>
                                                <div className='whatsapp_template_composer__label_description'>Create buttons that let customers respond to your message or take action.</div>
                                                <Select classNamePrefix="custom_select"  className="form_select" isSearchable={false} onChange={this.handleButtonType} value={{ value: this.state.buttonType, label: this.state.buttonType }} options={this.buttonTypes.map(bOption => ({ value: bOption, label: bOption }))}/>
                                                {this.state.submitted && !this.state.template_name && <div className="help-block">Template name is required</div>}
                                            </div>
                                            {this.state.buttonType.toLowerCase() === 'call to action' && (
                                                <div className='button_editor'>
                                                    {this.state.callToActionsButtons && this.state.callToActionsButtons.map((button, index) => (
                                                        <div className='button_editor_item'>
                                                            <div className='button_editor_item_field'>
                                                                <label>Type of action</label>
                                                                <Select classNamePrefix="custom_select"  className="form_select" isSearchable={false} onChange={(e) => { this.handleCallToActionButtonUpdate(index, 'actionType', e.value) }} value={{ value: button.actionType, label: button.actionType }} options={this.callToActionTypes.map(bOption => ({ value: bOption, label: bOption }))}/>
                                                            </div>
                                                            <div className='button_editor_item_field'>
                                                                <label>Button text</label>
                                                                <div className='contained_input'><input type="text" onChange={(e) => { this.handleCallToActionButtonUpdate(index, 'text', e.target.value.substring(0, this.maxLengths.buttonText)) }} value={button.text} required/><div className='max-length'>{button.text ? button.text.length : 0}/{this.maxLengths.buttonText}</div></div>
                                                            </div>
                                                            {button.actionType === 'Call Phone Number' && (
                                                                <div className='button_editor_item_field'>
                                                                    <label>Phone number</label>
                                                                    <div className='contained_input'><input type="text" onChange={(e) => { this.handleCallToActionButtonUpdate(index, 'phoneNumber', e.target.value.substring(0, this.maxLengths.buttonPhoneNumber)) }} value={button.phoneNumber} required/><div className='max-length'>{button.phoneNumber ? button.phoneNumber.length : 0}/{this.maxLengths.buttonPhoneNumber}</div></div>
                                                                </div>
                                                            )}
                                                            {button.actionType === 'Visit Website' && (
                                                                <div className='button_editor_item_field'>
                                                                    <label>URL type</label>
                                                                    <Select classNamePrefix="custom_select"  className="form_select" isSearchable={false} onChange={(e) => { this.handleCallToActionButtonUpdate(index, 'urlType', e.value) }} value={{ value: button.urlType, label: button.urlType }} options={this.buttonUrlTypes.map(bOption => ({ value: bOption, label: bOption }))}/>
                                                                </div>
                                                            )}
                                                            {button.actionType === 'Visit Website' && (
                                                                <div className='button_editor_item_field'>
                                                                    <label>Website URL</label>
                                                                    <div className='contained_input'><input type="text" onChange={(e) => { this.handleCallToActionButtonUpdate(index, 'websiteUrl', e.target.value.substring(0, this.maxLengths.buttonUrl)) }} value={button.websiteUrl} required/><div className='max-length'>{button.websiteUrl ? button.websiteUrl.length : 0}/{this.maxLengths.buttonUrl}</div></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                    <button className='btn' onClick={() => { this.handleAddNewButton('call_to_action') }} disabled={this.state.callToActionsButtons.length >= 2}><HiOutlinePlus/>Add Button</button>
                                                </div>
                                            )}
                                            {this.state.buttonType.toLowerCase() === 'quick reply' && (
                                                <div className='button_editor'>
                                                    {this.state.quickReplyButtons && this.state.quickReplyButtons.map((button, index) => (
                                                        <div className='button_editor_item'>
                                                            <div className='form-group'>
                                                                <label>Button text</label>
                                                                <div className='contained_input'><input type="text" placeholder='Enter button text' onChange={(e) => { this.handleQuickReplyUpdate(index, 'text', e.target.value) }} value={button.text} required/><div className='max-length'>{button.text ? button.text.length : 0}/{this.maxLengths.buttonText}</div></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button className='btn' onClick={() => { this.handleAddNewButton('quick_reply') }} disabled={this.state.quickReplyButtons.length >= 3}><HiOutlinePlus/>Add Button</button>
                                                </div>
                                            )}
                                            {this.state.error && (
                                                <AlertBox type='alert alert-danger'>{this.state.error}</AlertBox>
                                            )}
                                        </div>
                                        <WhastAppTemplatePreview updateObject={this.state}/>
                                    </div>
                                    <div className='whatsapp_template_step__actions'>                                    
                                        <button className='btn' onClick={() => { this.handleStepChange(1) }}>Previous step</button>
                                        <button className='btn primary' onClick={this.handleSubmitConfrimTrigger} disabled={!this.isValid()}><HiOutlinePlus/>Create Template</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.confirmCreate && (
                        <ConfirmModal isOpen={this.state.confirmCreate} onRequestClose={this.handleSubmitConfrimTrigger} onConfirm={this.handleTemplateSubmit} title="Confirm Template Submission" description="You are about to submit your template for approval. This message will be reviewed by Meta. Please ensure your template details are correct." confirmText="Submit"/>
                    )}
                    {this.state.creatingTemplate && (
                        <LoadingModal isOpen={this.state.creatingTemplate} text="Creating template..."/>
                    )}
                </div>
            </PageContainer>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppTemplateCreatePage);