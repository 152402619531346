import React from 'react';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import { toTimestamp } from '../../services/dates';
import { HiOutlineXMark } from 'react-icons/hi2';
import InternalChatLastActivity from './InternalChatLastActivity';
import newNotificationSound from '../../sounds/internal_message.mp3';

export default class InternalNotification extends React.Component{
    constructor(props){
        super(props);
        this.audio = new Audio(newNotificationSound)
        this.state = {
            deleted: false
        }

        this.handleDismiss = this.handleDismiss.bind(this);
    }
    componentDidMount(){
        if(!this.props.currentAgent.agent_settings.notifications_muted){
            this.audio.play();
            setTimeout(() => {
                this.audio.pause()
                this.audio.currentTime = 0;
            }, 2000);
        }

        setTimeout(() => {
            this.props.handleMarkAsShown();
        }, 5000);
    }

    componentDidUpdate(prevProps){
        if(prevProps.notification.messages.length <  this.props.notification.messages.length){
            if(!this.props.currentAgent.agent_settings.notifications_muted){
                this.audio.play();
                setTimeout(() => {
                    this.audio.pause()
                    this.audio.currentTime = 0;
                }, 2000);
            }
        }
    }

    handleDismiss(e){
        e.stopPropagation();
        this.props.handleMarkAsShown();
        this.setState({ deleted: true })
    }

    render(){
        const notification = this.props.notification;
        return !this.state.deleted && (
            <div className="internal_notifications__item" onClick={() => { this.props.handleNotificationClick(notification.chat) }}>
                <AgentProfileAvatar agent={notification.agent}/>
                <div className="internal_notifications__item__details text-truncate">
                    <div className="internal_notifications__item__name">{notification.agent.first_name} {notification.agent.last_name}</div>
                    <div className="internal_notifications__item__message_preview">{notification.messages.length === 1 ? <InternalChatLastActivity hideSender={true} lastActivity={notification.messages[0]}/> : notification.messages.length + ' new messages'}</div>
                    <div className="timestamp">{toTimestamp(notification.messages[notification.messages.length - 1].createdAt)}</div>
                    <div className="internal_notifications__item__dismiss" title="Dismiss Notification" onClick={this.handleDismiss}><HiOutlineXMark/></div>
                </div>
            </div>
        );
    }
}