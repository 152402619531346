import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PageContainer from '../Base/PageContainer';
import { DateRangePicker } from 'react-dates';
import Preloader from '../Preloader';
import { getLeads, searchLeads, exportLeads } from '../../services/user';
import LeadInfoModal from './LeadInfoModal';
import moment from 'moment';
import { HiEyeSlash, HiOutlineFunnel } from 'react-icons/hi2';
import { toTimestamp } from '../../services/dates';
import LoadMore from '../Base/LoadMore';
import validator from 'validator';
import { getQueues } from '../../services/queues';

import socket from '../../services/socket';
import { isMobile } from '../../services/responsive'

class LeadsPage extends React.Component{
    constructor(props){
        super(props);

        this.orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];
        this.state = {
            users: [],
            queues: undefined,
            loadingQueues: true,
            loadingUsers: true,
            loadingObjectFilters: true,
            usersLimit: 25,
            usersOffset: 25,
            hasMore: false,
            focusedInput: null,
            selectedUser: undefined,
            loadingMore: false,
            searchTerm: '',
            isSearched: false,
            filters: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment(),
                orderBy: 'desc'
            },
            objectFilterOptions: [],
            exporting: false,
            mobileFiltersShown: false
        }

        this.setUsers = this.setUsers.bind(this);
        this.handleAllInfoTrigger = this.handleAllInfoTrigger.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleSearchTerm = this.handleSearchTerm.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.handleFiltersTrigger = this.handleFiltersTrigger.bind(this);
    }

    async componentDidMount(){
        this.setUsers();

        //const objectFilterTitles = await getObjectTitles(this.props.auth.selectedChatbot.token);
        //const objectFilterOptions = objectFilterTitles.map(objectTitle => ({ value: objectTitle, label: titleCase(objectTitle) }))
        //this.setState({ objectFilterOptions, loadingObjectFilters: false })

        const queues = await getQueues(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        this.setState({ queues, loadingQueues: false });

        socket.on('usersUpdate', ({ id, conversationId, userId }) => {
            if(this.props.auth.selectedChatbot._id === id){
                this.setUsers();
            }
        });
    }

    componentWillUnmount(){
        socket.off('usersUpdate');
    }

    handleAllInfoTrigger(user){
        this.setState({ selectedUser: user })
    }

    handleLoadMore(){
        this.setState(prevState => ({
            usersLimit: prevState.usersLimit + prevState.usersOffset,
            loadingMore: true
        }), () => {
            this.setUsers().then(() => {
                this.setState({ loadingMore: false });
            })
        });
    }

    handleSearchTerm(e){
        this.setState({ searchTerm: e.target.value });
    }

    handleClearSearch(){
        this.setState({ loadingUsers: true, searchTerm: '', isSearched: false }, () => { this.setUsers() });
    }

    handleSearchSubmit(e){
        e.preventDefault();
        this.setState({ loadingUsers: true })
        if(this.state.searchTerm.trim().length > 0){
            this.setState({ isSearched: true }, () => { this.setUsers() })
        }else{
            this.setState({ isSearched: false }, () => { this.setUsers() })
        }
    }

    async setUsers(){
        let users = [];
        if(this.state.searchTerm.trim().length > 0){
            users = await searchLeads(this.props.auth.selectedChatbot.token, this.state.usersLimit + 1, this.state.searchTerm, this.state.filters)
        }else{
            users = await getLeads(this.props.auth.selectedChatbot.token, this.state.usersLimit + 1, this.state.filters)
        }
        this.setState({ loadingUsers: false, hasMore: users.length > this.state.usersLimit ? true : false });

        if(users.length > this.state.usersLimit){
            users.pop()
        }

        this.setState({ users })
    }

    handleFilterChange(fieldName, value){
        this.setState(prevState => ({ loadingUsers: true, filters: { ...prevState.filters, [fieldName]: value } }), () => {
            this.setUsers();
        })
    }

    async handleExport(){
        this.setState({ exporting: true });
        const response = await exportLeads(this.props.auth.selectedChatbot.token, this.state.filters);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Leads.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        this.setState({ exporting: false });
    }

    handleFiltersTrigger(){
        this.setState(prevState => ({ mobileFiltersShown: !prevState.mobileFiltersShown }))
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                        <div className="page_title">Leads</div>
                            {this.state.mobileFiltersShown ? <button className="btn mobile_filter_trigger" onClick={this.handleFiltersTrigger}><HiEyeSlash/>Hide Filters</button> : <button className="btn mobile_filter_trigger" onClick={this.handleFiltersTrigger}><HiOutlineFunnel/>Filters</button> }
                            <div className={`leads_top_bar ${this.state.mobileFiltersShown ? 'mobile_filters_shown' : ''}`}>
                                <div className="filters_column">
                                    <div className="filters">
                                        <div className="form-group">
                                            <label>Date Range</label>
                                            <DateRangePicker
                                                isOutsideRange={(day) => day.isAfter(moment())}
                                                noBorder={true}
                                                startDate={this.state.filters.startDate ? moment(this.state.filters.startDate) : undefined} // momentPropTypes.momentObj or null,
                                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                                endDate={this.state.filters.endDate ? moment(this.state.filters.endDate) : undefined} // momentPropTypes.momentObj or null,
                                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                                onDatesChange={({ startDate, endDate }) => { this.handleFilterChange('startDate', startDate ? startDate.format('YYYY-MM-DD') : undefined); this.handleFilterChange('endDate', endDate ? endDate.format('YYYY-MM-DD') + 'T23:59' : undefined); }} // PropTypes.func.isRequired,
                                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
                                                orientation={isMobile() ? 'vertical' : 'horizontal'}
                                                withFullScreenPortal={isMobile() ? true : false}
                                                initialVisibleMonth={() => moment().subtract(1, 'months')}
                                            />
                                        </div>
                                        <div className="top_bar_filter">
                                            <label>Sort by</label>
                                            <Select classNamePrefix="custom_select" onChange={(e) => { this.handleFilterChange('orderBy', e.value) }} className="filter form_select" value={this.orderByOptions.find(orderOption => orderOption.value === this.state.filters.orderBy)} options={this.orderByOptions}/>
                                        </div>
                                        {this.props.auth.agent.agent.role === 'admin' && <div className="top_bar_filter">
                                            <label>Queue</label>
                                            <Select classNamePrefix="custom_select"  className="filter form_select"  isClearable={true} isLoading={this.state.loadingQueues} onChange={(e) => { this.handleFilterChange("queue", e ? e.value : undefined) }} options={this.state.queues && this.state.queues.map(queueItem => ({ value: queueItem._id, label: queueItem.name }))}/>
                                        </div>}
                                    </div>
                                    {1 == 2 && (
                                        <div className="filters">
                                            <div className="top_bar_filter" style={{ width: "100%"}}>
                                                <label>Filter</label>
                                                <Select classNamePrefix="custom_select"  isLoading={this.state.loadingObjectFilters} onChange={(e) => { this.handleFilterChange('objectFilters', e.map(item => item.value)) }} className="flex-growform_select" isMulti options={this.state.objectFilterOptions}/>                                
                                                <button className="btn mb-medium mt-medium" disabled={this.state.exporting} onClick={this.handleExport}>{!this.state.exporting ? 'Export' : <Preloader padding="0" size="15px" color="white"/>}</button>
                                            </div> 
                                        </div>
                                    )}
                                    <div className="filters">
                                        <div className="top_bar_filter" style={{ width: "100%"}}>
                                            <button className="btn mb-medium mt-medium" disabled={this.state.exporting} onClick={this.handleExport}>{!this.state.exporting ? 'Export' : <Preloader padding="0" size="15px" color="white"/>}</button>
                                        </div> 
                                    </div>
                                </div> 
                                {/*<div className="search-container">
                                    <label>Search leads</label>
                                    <form className="help_request_search" onSubmit={this.handleSearchSubmit}>
                                        <input type="text" placeholder="Enter your search term" value={this.state.searchTerm} onChange={this.handleSearchTerm}/>
                                        {this.state.isSearched && <div className="btn" onClick={this.handleClearSearch}><HiOutlineXMark/></div>}
                                        <button className="btn"><HiMagnifyingGlass/></button>
                                    </form>
                                </div>*/}
                            </div> 
                            <div className="leads_list">
                                {!this.state.loadingUsers ? this.state.users.length > 0 ? (
                                    <div>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Created At</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.users.map((user, index) => {
                                                    return user.user_info && (user.user_info.email || user.user_info.phone_number) && (
                                                        <tr key={index}>
                                                            <td>{user.user_info.name ? user.user_info.name : <span className="leads_info_none">Not found</span>}</td>
                                                            <td>{user.user_info.email ? validator.isEmail(user.user_info.email) ? <a href={`mailto:${user.user_info.email}`} rel="noreferrer" target="_blank">{user.user_info.email}</a> : user.user_info.email : <span className="leads_info_none">Not found</span>}</td>
                                                            <td>{user.user_info.phone_number ? user.user_info.phone_number : <span className="leads_info_none">Not found</span>}</td>
                                                            <td>{toTimestamp(user.createdAt)}</td>
                                                            <td><button className="btn" onClick={() => { this.handleAllInfoTrigger(user) }}>View all info</button></td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        {this.state.hasMore && <div><LoadMore onClick={this.handleLoadMore} loadingMore={this.state.loadingMore}/></div>}
                                    </div>
                                ) : <span>No leads found</span> : <Preloader padding="30px 0"/> }
                            </div>
                        </div>
                    </div>
                </PageContainer>
                {this.state.selectedUser && <LeadInfoModal isOpen={this.state.selectedUser ? true : false} user={this.state.selectedUser} onRequestClose={() => { this.handleAllInfoTrigger(undefined) }}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(LeadsPage);