import React from "react";
import { connect } from 'react-redux';
import { createContactList } from "../../services/contacts";
import Preloader from "../Preloader";

class WhatsAppContactListCreateForm extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            contactListName: '',
            creatingContactList: false
        }

        this.handleContactListNameChange = this.handleContactListNameChange.bind(this);
        this.handleContactListCreate = this.handleContactListCreate.bind(this);
    }

    handleContactListNameChange(e){
        this.setState({ contactListName: e.target.value })
    }

    async handleContactListCreate(){
        this.setState({ creatingContactList: true })
        const contactList = await createContactList(this.props.auth.selectedChatbot.token, this.state.contactListName, this.props.auth.agent.agent._id, this.props.numbers);
        this.setState({ creatingContactList: false })
        this.props.onComplete(contactList)
    }
    
    render(){
        return (
            <div className="whatsapp_contact_list_create_form mt-medium">
                <div className="form-group">
                    <div className='whatsapp_template_composer__label' htmlFor="body">Contact List Name</div>
                    <div className='whatsapp_template_composer__label_description'>Imported contacts will be saved for future use.</div>
                    <input type="text" placeholder="Enter contact list name" onChange={this.handleContactListNameChange} value={this.state.contactListName} required/>
                </div>
                {!this.props.loading ? (
                    <table className="table contacts_preview">
                        <thead><th>Number</th></thead>
                        <tbody>
                            {(this.props.numbers && this.props.numbers.length > 0) ?
                                this.props.numbers.map(number => (
                                    <tr><td>{number}</td></tr>
                                ))
                            : <tr><td className='none_found_message'>Numbers imported will appear here</td></tr>}
                        </tbody>
                    </table>
                ) : <Preloader/>}
                <div className='whatsapp_template_step__actions'>
                    <button className='btn' onClick={this.props.onCancel}>Previous Step</button>
                    <button className='btn' disabled={!this.props.numbers || this.state.contactListName.length === 0} onClick={this.handleContactListCreate}>Next step</button>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppContactListCreateForm);