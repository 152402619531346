import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import { deleteUnansweredQuestions, getUnansweredQuestions, updateQuestion } from '../../services/unansweredQuestions';
import RejectedQuestionItem from './RejectedQuestionItem';
import LoadMore from '../Base/LoadMore';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import { getQueues } from '../../services/queues';
import { isMobile } from '../../services/responsive';
import { HiOutlineTrash } from 'react-icons/hi2';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';

class RejectedQuestionsPage extends React.Component {
    constructor(props){
        super(props);

        this.orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];
        this.state = {
            loadingUnansweredQuestions: true,
            queues: undefined,
            loadingQueues: true,
            unanswerdQuestions: [],
            unansweredQuestionsLimit: 50,
            unansweredQuestionsOffset: 50,
            hasMore: false,
            loadingMore: false,
            confirmDeleteAll: false,
            deletingAll: false,
            filters: {
                startDate: undefined,
                endDate: undefined,
                orderBy: 'desc',
                validQuestion: false
            },
        };

        this.setUnasweredQuestions = this.setUnasweredQuestions.bind(this);
        this.handleLoadMoreUnasweredQuestions = this.handleLoadMoreUnasweredQuestions.bind(this);
        this.handleApproveQuestion = this.handleApproveQuestion.bind(this);
        this.handleEditQuestion = this.handleEditQuestion.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleDeleteAllConfirm = this.handleDeleteAllConfirm.bind(this);
        this.handleDeleteAll = this.handleDeleteAll.bind(this);
    }

    async componentDidMount(){
        await this.setUnasweredQuestions();
        const queues = await getQueues(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        this.setState({ queues, loadingQueues: false });
    }

    async setUnasweredQuestions(){
        const unanswerdQuestions = await getUnansweredQuestions(this.props.auth.selectedChatbot.token, this.state.unansweredQuestionsLimit + 1, this.state.filters);

        this.setState({ hasMore: unanswerdQuestions.length > this.state.unansweredQuestionsLimit ? true : false });
        if(unanswerdQuestions.length > this.state.unansweredQuestionsLimit){
            unanswerdQuestions.pop()
        }

        this.setState({
            unanswerdQuestions,
            loadingUnansweredQuestions: false
        });
        
        // const count = await getUnansweredQuestionCount(this.props.auth.selectedChatbot.token);
        // this.setState({ count })
    }

    handleLoadMoreUnasweredQuestions(){
        this.setState(prevState => ({
            unansweredQuestionsLimit: prevState.unansweredQuestionsLimit + prevState.unansweredQuestionsOffset,
            loadingMore: true
        }), () => {
            this.setUnasweredQuestions().then(() => {
                this.setState({ loadingMore: false });
            })
        });
    }

    async handleApproveQuestion(questionId){
        await updateQuestion(this.props.auth.selectedChatbot.token, questionId, { valid_unanswered_question: true })
        await this.setUnasweredQuestions();
    }

    async handleEditQuestion(question, newText){
        await updateQuestion(this.props.auth.selectedChatbot.token, question._id, { message_data: { ...question.message_data, text: newText } })
        await this.setUnasweredQuestions();
    }
    
    handleFilterChange(fieldName, value){
        this.setState(prevState => ({ loadingUnansweredQuestions: true, filters: { ...prevState.filters, [fieldName]: value } }), () => {
            this.setUnasweredQuestions();
        })
    }

    handleDeleteAllConfirm(){
        this.setState(prevState => ({
            confirmDeleteAll: !prevState.confirmDeleteAll
        }));
    }

    async handleDeleteAll(){
        this.setState({ confirmDeleteAll: false, deletingAll: true });
        await deleteUnansweredQuestions(this.props.auth.selectedChatbot.token, this.state.filters);
        await this.setUnasweredQuestions();
        this.setState({ deletingAll: false });
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                            <div className="page_title">Unanswered Questions</div>
                            <div className="unanswered_questions_actions">
                                <NavLink to="/unanswered-questions" className="btn">Approved {this.state.count && <span>({this.state.count.approved_count})</span>}</NavLink>
                                <NavLink to="/unanswered-questions/pending" className="btn">Pending {this.state.count && <span>({this.state.count.pending_count})</span>}</NavLink>
                                <NavLink to="/unanswered-questions/rejected" className="btn selected">Rejected {this.state.count && <span>({this.state.count.rejected_count})</span>}</NavLink>
                            </div>
                            <div className="unanswered_questions_top_bar filters">
                                <div className="filters">
                                    <div className="form-group">
                                        <label>Date Range</label>
                                        <DateRangePicker
                                            isOutsideRange={(day) => day.isAfter(moment())}
                                            noBorder={true}
                                            startDate={this.state.filters.startDate ? moment(this.state.filters.startDate) : undefined} // momentPropTypes.momentObj or null,
                                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                            endDate={this.state.filters.endDate ? moment(this.state.filters.endDate) : undefined} // momentPropTypes.momentObj or null,
                                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                            onDatesChange={({ startDate, endDate }) => { this.handleFilterChange('startDate', startDate ? startDate.format('YYYY-MM-DD') : undefined); this.handleFilterChange('endDate', endDate ? endDate.format('YYYY-MM-DD') + 'T23:59' : undefined); }} // PropTypes.func.isRequired,
                                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
                                            orientation={isMobile() ? 'vertical' : 'horizontal'}
                                            withFullScreenPortal={isMobile() ? true : false}
                                            initialVisibleMonth={() => moment().subtract(1, 'months')}
                                        />
                                    </div>
                                </div>
                                <div className="filters">
                                    <div className="top_bar_filter">
                                        <label>Sort by</label>
                                        <Select classNamePrefix="custom_select"  onChange={(e) => { this.handleFilterChange('orderBy', e.value) }} className="filter form_select" value={this.orderByOptions.find(orderOption => orderOption.value === this.state.filters.orderBy)} options={this.orderByOptions}/>
                                    </div>
                                    <div className="top_bar_filter">
                                        <label>Queue</label>
                                        <Select classNamePrefix="custom_select"  className="filter form_select" isClearable={true} isLoading={this.state.loadingQueues} onChange={(e) => { this.handleFilterChange("queue", e ? e.value : undefined) }} options={this.state.queues && this.state.queues.map(queueItem => ({ value: queueItem._id, label: queueItem.name }))}/>
                                    </div>
                                </div>
                            </div>
                            <button className="btn mb-medium" onClick={this.handleDeleteAllConfirm}><HiOutlineTrash/>Delete All</button>
                            {!this.state.loadingUnansweredQuestions ? this.state.unanswerdQuestions.length > 0 ? (
                                <div>
                                    {this.state.unanswerdQuestions.map(unanswerdQuestion => <RejectedQuestionItem key={unanswerdQuestion._id} handleApproveQuestion={this.handleApproveQuestion} question={unanswerdQuestion} handleEditQuestion={this.handleEditQuestion}/>)}
                                    {this.state.hasMore && <LoadMore onClick={this.handleLoadMoreUnasweredQuestions} loadingMore={this.state.loadingMore}/>}
                                </div>
                            ) : <div>No unanswered questions</div> : <Preloader/>}
                        </div>
                    </div>
                </PageContainer>
                <ConfirmModal isOpen={this.state.confirmDeleteAll} title="Delete All" description="You are about to delete all rejected questions. This action cannot be undone. Are you sure you want to do this?" confirmText="Delete All" onRequestClose={this.handleDeleteAllConfirm} onConfirm={this.handleDeleteAll}/>
                <LoadingModal text="Deleting all" isOpen={this.state.deletingAll}/>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(RejectedQuestionsPage);