import React from 'react';
import Preloader from '../Preloader';
import { agentActions } from '../../actions/agents';
import { connect } from 'react-redux';

class ResetPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            validating: true,
            isValid: true
        };
    }
    componentDidMount(){
        const token = this.props.match.params.id;
        const { dispatch } = this.props;
        dispatch(agentActions.validatePasswordReset(token));
    }
    render(){
        return (
            <Preloader fitToContent={true}/>
        );
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(ResetPassword);