import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { companyService } from '../../services/company';
import Select from 'react-select';

class EditChatbotModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            chatbot: this.props.chatbot,
            companies: [],
            loadingCompanies: true
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    async componentDidMount(){
        const companies = await companyService.getCompanies(this.props.auth.agent.token);
        this.setState({ companies, loadingCompanies: false })
    }

    componentDidUpdate(prevProps){
        if(prevProps.chatbot !== this.props.chatbot){
            this.setState({ chatbot: this.props.chatbot })
        }
    }

    handleInputChange(e){
        this.setState(prevState => ({ chatbot: { ...prevState.chatbot, [e.target.name]: e.target.value } }))
    }

    handleSelectChange(e){
        this.setState(prevState => ({ chatbot: { ...prevState.chatbot, owner: e.value } }))
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="action_modal modal__preloader">
                <div className="modal__title">{!this.state.chatbot._id ? 'New' : 'Edit'} Chatbot</div>
                <div className="form-group">
                    <label>Name</label>
                    <input value={this.state.chatbot.name} onChange={this.handleInputChange} type="text" name="name" placeholder="Enter chatbot name"/>
                </div>
                <div className="form-group">
                    <label>Company</label>
                    <Select classNamePrefix="custom_select"  className="form_select" onChange={this.handleSelectChange}  isLoading={this.state.loadingCompanies} isDisabled={this.state.loadingCompanies} value={this.state.companies && this.state.chatbot.owner ? this.state.companies.map(company => ({ value: company, label: company.name })).find(optionItem => optionItem.value._id === this.state.chatbot.owner._id) : undefined} placeholder={this.state.loadingCompanies ? 'Loading...' : 'Select'} options={this.state.companies ? this.state.companies.map(company => ({ value: company, label: company.name })) : undefined}/>
                </div>
                {this.state.chatbot._id && <div className="form-group">
                    <label>Token</label>
                    <input value={this.state.chatbot.token} onChange={this.handleInputChange} type="text" name="name" disabled/>
                </div>}
                <div className="modal__actions mt-medium">
                    <div className="btn" onClick={(e) => { this.props.onRequestClose() }}>Cancel</div>
                    <button className="btn" disabled={this.state.chatbot.name.trim().length === 0 || !this.state.chatbot.owner} onClick={() => { this.props.onSave(this.state.chatbot) }}>Save</button>
                </div>                
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditChatbotModal);