import { Tooltip } from '@material-ui/core';
import Picker from 'emoji-picker-react';
import React from 'react';
import { MdOutlineEmojiEmotions } from 'react-icons/md';
import onClickOutside from "react-onclickoutside";

class EmojiPicker extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            emojiPickerShown: false
        };

        this.handleEmojiPickerTrigger = this.handleEmojiPickerTrigger.bind(this);
    }

    handleEmojiPickerTrigger(){
        this.setState(prevState => ({ emojiPickerShown: !prevState.emojiPickerShown }))
    }

    handleClickOutside = evt => {
        this.setState(prevState => ({ emojiPickerShown: false }))
    };

    render(){
        return (
            <div className="emoji_picker">
                <Tooltip title='Insert emoji' placement='top' arrow><div className="emoji_picker__triger" onClick={this.handleEmojiPickerTrigger}><MdOutlineEmojiEmotions/></div></Tooltip>
                {this.state.emojiPickerShown && <Picker previewConfig={{ showPreview: false }}  preload={true} className="emoji_picker__picker" onEmojiClick={this.props.handleEmojiPick}/>}
            </div>
        );
    }
};

export default onClickOutside(EmojiPicker)