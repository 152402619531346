import React from 'react';
import { HiOutlineArrowLeft, HiOutlinePlusCircle, HiOutlineTrash, HiOutlineXMark } from 'react-icons/hi2';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PageContainer from '../Base/PageContainer';
import WhatsAppTemplateList from './WhatsAppTemplateList';

class WhatsAppTemplatePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            editable: false
        }

        this.handleTriggerEditable = this.handleTriggerEditable.bind(this);
    }

    handleTriggerEditable(){
        this.setState(prevState => ({ editable: !prevState.editable }))
    }

    render(){
        return (
            <PageContainer>
                <div className="container">
                    <div className="col-12">
                        <div className="page_title">Template Messages</div>
                        <div className='page_description'>To send notifications to your customer via WhatsApp you need to make use of message templates. Those templates will be approved by the WhatsApp team.</div>
                        <div className='whatsapp_template_page_actions_holder'>
                            <div className='whatsapp_template_page_actions'>
                                <NavLink to="/whatsapp-push-notifications" className='btn'><HiOutlineArrowLeft/>Back</NavLink>
                            </div>
                            <div className='whatsapp_template_page_actions'>
                                <NavLink to="/whatsapp-push-notifications/templates/create" className='btn primary'><HiOutlinePlusCircle/>Create New Template</NavLink>
                                {!this.state.editable && <button className='btn' onClick={this.handleTriggerEditable}><HiOutlineTrash/>Delete Templates</button>}
                                {this.state.editable && <button className='btn' onClick={this.handleTriggerEditable}><HiOutlineXMark/>Cancel Delete</button>}
                            </div>
                        </div>
                        <WhatsAppTemplateList allowPending={true} editable={this.state.editable}/>
                    </div>
                </div>
            </PageContainer>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppTemplatePage);