import React from 'react';
import { connect } from 'react-redux';
import DashboardSelectedComponent from './DashboardSelectedComponent';
import Menu from './Menu/Menu';
import TopBar from './TopBar';
import { agentService } from '../services/agents';
import { agentActions } from '../actions/agents';
import { getPendingHumanHandoffs, getTransferedHumanHandoffs, getInvitesHumanHandoffs } from '../services/humanHandoff';
import { setInternalChats, getChat } from '../services/internalChats';
import { setHelpRequestCount } from '../services/helpRequest';
import { setMeetings } from '../services/meetings';
import HumanHandoffNotifcation from './Notifications/HumanHandoffNotification';
import InternalChat from './InternalChat/InternalChat';
import { setCurrentMessages } from '../services/internalChats';
import HumanHandoffTransfer from './Notifications/HumanHandoffTransfer';
import HumanHandoffInvite from './Notifications/HumanHandoffInvite';
import _ from 'lodash';

import socket from '../services/socket';

class Dashboard extends React.Component{
    constructor(props){
        super(props);

        this.statusTimer = undefined;
        this.state = {
            mobileMenuOpen: false
        };
        
        this.setNotifcations = this.setNotifcations.bind(this);
        this.mobileMenuTrigger = this.mobileMenuTrigger.bind(this);
    }
    componentDidMount(){
        socket.on('agentsUpdate', ({ id }) => {
            if(this.props.auth && this.props.auth.agent && this.props.auth.agent.agent && this.props.auth.agent.agent._id === id){
                agentService.setAgent(this.props.auth.agent.token, this.props.auth.agent.agent._id).then((agent) => {
                    this.props.dispatch(agentActions.setCurrentAgent(agent));
                });
            }
            setInternalChats(this.props);
            if((this.props.internalChats.selectedChat && this.props.internalChats.selectedChat.chat && this.props.internalChats.selectedChat.participants && this.props.internalChats.selectedChat.chat.participants.find(participant => participant._id === id))){
                getChat(this.props.internalChats.selectedChat.chat._id, this.props.auth.selectedChatbot.token).then(chat => {
                    this.props.dispatch({
                        type: 'UPDATE_SELECTED_CHAT',
                        selectedChat: chat
                    });
                });
            }
        });

        socket.on('heartbeat', () => {  
            // Respond to server heartbeat to keep the connection alive  
            socket.emit('heartbeat');  
        });  

        socket.on('chatbotUpdate', ({ chatbot }) => {
            delete chatbot._v;
            delete chatbot.updatedAt;
            delete chatbot.owner;
            delete this.props.auth.selectedChatbot._v;
            delete this.props.auth.selectedChatbot.updatedAt;
            delete this.props.auth.selectedChatbot.owner;
            
            if(this.props.auth.selectedChatbot._id === chatbot._id && !_.isEqual(this.props.auth.selectedChatbot, chatbot)){
                this.props.dispatch(agentActions.setCurrentChatbot(chatbot));
            }
        });

        socket.on('notifcationsUpdate', ({ id }) => {
            if(this.props.auth.selectedChatbot._id === id){
                this.setNotifcations();
            }
        });

        socket.on('internalMessageUpdate', ({ id, conversation }) => {
            if(this.props.auth.selectedChatbot._id === id && (this.props.internalChats.selectedChat && this.props.internalChats.selectedChat.chat && this.props.internalChats.selectedChat.chat._id === conversation)){
                setCurrentMessages(this.props);
            }
        });

        socket.on('internalChatUpdate', ({ id }) => {
            if(this.props.auth.selectedChatbot._id === id){
                setInternalChats(this.props);
            }
        });

        socket.on('helpRequestUpdate', ({ id }) => {
            if(this.props.auth.selectedChatbot._id === id){
                setHelpRequestCount(this.props);
            }
        });

        socket.on('meetingUpdate', ({ id }) => {
            if(this.props.auth.selectedChatbot._id === id){
                setMeetings(this.props);
            }
        });

        socket.emit('join', this.props.auth.agent.agent._id);

        this.statusTimer = setInterval(() => {
            socket.emit('join', this.props.auth.agent.agent._id);
        }, 2000);

        this.setNotifcations();
        setInternalChats(this.props);
        setMeetings(this.props);
        setHelpRequestCount(this.props);
    }

    componentWillUnmount(){
        socket.off('chatbotUpdate');
        socket.off('agentsUpdate');
        socket.off('notifcationsUpdate');
        socket.off('internalMessageUpdate');
        socket.off('internalChatUpdate');
        socket.off('helpRequestUpdate');
        socket.off('meetingUpdate');
        socket.off('heartbeat');
        if(this.statusTimer){
            clearInterval(this.statusTimer);
        }
    }

    async setNotifcations(){
        const humanHandoffs = await getPendingHumanHandoffs(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        const humanHandoffTransfers = await getTransferedHumanHandoffs(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        const humanHandoffInvites = await getInvitesHumanHandoffs(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        const humanHandoffNotifcations = humanHandoffTransfers.map((humanHandoff) => { return <HumanHandoffTransfer key={humanHandoff._id} humanHandoff={humanHandoff}/>; })
        const notifications = humanHandoffNotifcations.concat(humanHandoffs.map((humanHandoff) => { return <HumanHandoffNotifcation key={humanHandoff._id} humanHandoff={humanHandoff}/>; })).concat(humanHandoffInvites.map((humanHandoff) => { return <HumanHandoffInvite humanHandoff={humanHandoff}/>; }));
        this.props.dispatch({
            type: 'NOTIFICATIONS_SET',
            notifications: notifications
        });
    }

    mobileMenuTrigger(){
        this.setState(prevState => ({ mobileMenuOpen: !prevState.mobileMenuOpen }));
    }

    render(){
        return (
            <div className="dashboard">
                <Menu mobileMenuOpen={this.state.mobileMenuOpen} mobileMenuTrigger={this.mobileMenuTrigger}/>
                <div className="dashboard_content">
                    <TopBar mobileMenuTrigger={this.mobileMenuTrigger}/>
                    <DashboardSelectedComponent>{this.props.component}</DashboardSelectedComponent>
                    <InternalChat/>
                </div>
            </div>
        ); 
    };
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Dashboard);