import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { companyService } from '../../services/company';
import { HiOutlinePencil, HiTrash, HiPlus } from 'react-icons/hi2';
import Tooltip from '@material-ui/core/Tooltip';
import EditCompanyModal from './EditCompanyModal';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';

class AdminCompanies extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            companies: [],
            loadingCompanies: true,
            selectedCompany: undefined,
            companyToDelete: undefined,
            savingCompany: false,
            deletingCompany: false
        };

        this.handleEditCompany = this.handleEditCompany.bind(this);
        this.handleCompanySave = this.handleCompanySave.bind(this);
        this.handleDeleteCompany = this.handleDeleteCompany.bind(this);
    }

    async componentDidMount(){
        const companies = await companyService.getCompanies(this.props.auth.agent.token);
        this.setState({
            companies,
            loadingCompanies: false
        });
    }

    handleEditCompany(company){
        this.setState({ selectedCompany: company });
    }

    async handleCompanySave(companyItem, companyAgents){
        this.setState({ savingCompany: true, selectedCompany: undefined });
        if(companyItem._id){
            await companyService.updateCompany(companyItem._id, this.props.auth.agent.token, companyItem);
            await companyService.updateCompanyAgents(companyItem._id, this.props.auth.agent.token, companyAgents.map(agent => agent._id));
            const companies = await companyService.getCompanies(this.props.auth.agent.token);
            return this.setState({
                companies,
                loadingCompanies: false,
                savingCompany: false
            });
        }else{
            const createdComapny = await companyService.createCompany(this.props.auth.agent.token, companyItem);
            await companyService.updateCompanyAgents(createdComapny._id, this.props.auth.agent.token, companyAgents.map(agent => agent._id));
            const companies = await companyService.getCompanies(this.props.auth.agent.token);
            return this.setState({
                companies,
                loadingCompanies: false,
                savingCompany: false
            });
        }
    }

    handleDeleteCompanyConfirm(company){
        this.setState({ companyToDelete: company });
    }

    async handleDeleteCompany(){
        await companyService.deleteCompany(this.state.companyToDelete._id, this.props.auth.agent.token);
        this.setState({ deletingCompany: true, companyToDelete: undefined });
        const companies = await companyService.getCompanies(this.props.auth.agent.token);
        this.setState({
            companies,
            deletingCompany: false
        });
    }

    render(){
        return (
            <div className="admin_settings_widget">
                <h3>Companies</h3>
                <p>Edit company logos and details.</p>
                <button className="btn mb-medium" onClick={() => { this.handleEditCompany({ name: '' }) }}><HiPlus/>Add Company</button>
                {!this.state.loadingCompanies ? (this.state.companies.length > 0 ? (
                    this.state.companies.map(company => (
                        <div className="company_list_item">
                            {company.logo ? <img src={company.logo_icon} alt="Admin Company Logo"/> : <img src="https://i.stack.imgur.com/y9DpT.jpg" alt="Admin Company Logo"/>}
                            <div className="company_list_item__name">{company.name}</div>
                            <div className="company_list_item__actions">
                                <Tooltip title="Edit company" arrow><div className="company_list_item__action" onClick={() => { this.handleEditCompany(company) }}><HiOutlinePencil/></div></Tooltip>
                                <Tooltip title="Delete company" arrow><div className="company_list_item__action" onClick={() => { this.handleDeleteCompanyConfirm(company) }}><HiTrash/></div></Tooltip>
                            </div>
                        </div>
                    ))
                    ) : 'No Companies') : <Preloader/>}
                {this.state.selectedCompany && <EditCompanyModal onSave={this.handleCompanySave} isOpen={this.state.selectedCompany ? true : false} onRequestClose={() => { this.handleEditCompany(undefined) }} company={this.state.selectedCompany}/>}
                {this.state.companyToDelete && <ConfirmModal isOpen={this.state.companyToDelete ? true : false} title="Delete Company" onConfirm={this.handleDeleteCompany} description={<span>You are about to delete <b>{this.state.companyToDelete.name}</b><br/><br/>Please note that deleting this company will be a permanent action. Are you sure you want to do this?</span>} confirmText="Delete" onRequestClose={() => { this.handleDeleteCompanyConfirm(undefined) }}/>}
                {this.state.deletingCompany && <LoadingModal isOpen={this.state.deletingCompany} text="Deleting company"/>}              
                {this.state.savingCompany && <LoadingModal isOpen={this.state.savingCompany} text="Saving company"/>}               
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AdminCompanies);