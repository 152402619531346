import React from 'react';
import { connect } from 'react-redux';
import AgentManager from '../Agents/AgentManager';
import PageContainer from '../Base/PageContainer';
import QueueManager from '../QueueManager/QueueManager';
import BotSettings from './BotSettings';
import PortalSettings from './PortalSettings';

class SettingsPage extends React.Component{
    render(){
        return (
            <div className="container column mb-medium">
                <PageContainer>
                    <h2>Agents</h2>
                    <AgentManager/>
                </PageContainer>
                <PageContainer>
                    <h2>Queues</h2>
                    <QueueManager/>
                </PageContainer>
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <PageContainer>
                        <h2>Portal Settings</h2>
                        <PortalSettings/>
                    </PageContainer>
                )}
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <PageContainer>
                    <h2>Bot Settings</h2>
                    <BotSettings/>
                </PageContainer>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(SettingsPage);