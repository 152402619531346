import React from 'react';
import { connect } from 'react-redux';

class AnalyticsPage extends React.Component{
    render(){
        return this.props.auth.selectedChatbot.settings.analytics_embed ? <div className="analytics_page" dangerouslySetInnerHTML={{ __html: this.props.auth.selectedChatbot.settings.analytics_embed }}/> : <div className="container"><div className="col-6"><div className="alert_box alert-danger m-medium">Analytics have not been configured</div></div></div>;
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AnalyticsPage);