import React from 'react';
import { connect } from 'react-redux';
import NotificationSoundToggle from './NotificationSoundToggle';

class NotificationsActions extends React.Component{
    render(){
        return (
            <div className="notifications_actions">
                <div className="notifications_title">Notifications</div>
                <div className="notifications_actions__container">
                    <NotificationSoundToggle/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(NotificationsActions);