import React from 'react';
import { connect } from 'react-redux';
import StatusSelector from './StatusSelector';
import StatusText from './StatusText';
import { agentService } from '../../services/agents';
import { agentActions } from '../../actions/agents';

class Status extends React.Component{
    constructor(props){
        super(props);
        this.currentAgent = this.props.auth.agent.agent;
        this.agent = this.props.agent
        this.handleStatusSelectChange = this.handleStatusSelectChange.bind(this);
    }

    handleStatusSelectChange(e, status){
        agentService.setStatus(this.props.auth.agent.token, this.currentAgent._id, status).then((agent) => {
            this.props.dispatch(agentActions.setCurrentAgent(agent));
        });
    }
    
    render(){
        
        return (
            <div className="status">
                {this.agent._id === this.currentAgent._id ? ( <StatusSelector onChange={this.handleStatusSelectChange} agent={this.currentAgent}/> ) : (
                    <StatusText status={this.currentAgent.agent_settings.status}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Status);
