import React from 'react';
import { connect } from 'react-redux';
import MiniAgentProfile from './MiniAgentProfile';
import Notifications from './Notifications/Notifcations';
import { HiBars3BottomLeft } from 'react-icons/hi2';

class TopBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            chatbotSelectorOpen: false
        }

        this.handleToggleChatbotSelector = this.handleToggleChatbotSelector.bind(this);
    }

    handleToggleChatbotSelector(open){
        this.setState({ chatbotSelectorOpen: open })
    }

    render(){
        return (
            <div className="dashboard_topbar">
                <div className="dashboard_topbar__menu_trigger" onClick={this.props.mobileMenuTrigger}><HiBars3BottomLeft/></div>
                <img src="/images/logo_full.webp" className="top_bar_logo" alt="Verge AI Logo"/>
                {/*this.props.auth.selectedChatbot && <div className="chatbot_selector_toggle" onClick={(e) => { e.preventDefault(); this.handleToggleChatbotSelector(!this.state.chatbotSelectorOpen) }}>{this.props.auth.selectedChatbot.name}{this.state.chatbotSelectorOpen ? <HiOutlineChevronUp/> : <HiOutlineChevronDown/>}                {this.state.chatbotSelectorOpen && <ChatbotSelector selectedChatbot={this.props.auth.selectedChatbot._id} handleToggleChatbotSelector={this.handleToggleChatbotSelector}/>}</div> */}
                <div className="dashboard_topbar__actions">
                    <Notifications/>
                    <MiniAgentProfile agent={this.props.auth.agent.agent}/>
                </div>
            </div>
        );
    };
}


const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(TopBar);