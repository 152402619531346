import Tooltip from '@material-ui/core/Tooltip';
import { HiOutlinePencil, HiTrash } from 'react-icons/hi2';

const QueueListItem = (props) => {
    return (
        <div className="queue_list_item">
            <div className=""><div className="queue_list_item__name">{props.queue.name}</div>
                <div className="queue_list_item__subtitle">{props.queue.agents.filter(agent => agent.agent_settings.status !== 'offline').length}/{props.queue.agents.length} agents online</div>
            
            </div>    
            <div className="queue_list__actions">
                <Tooltip title="Edit queue"><div className="queue_list__action" onClick={() => { props.handleQueueClick(props.queue) }}><HiOutlinePencil/></div></Tooltip> 
                <Tooltip title="Delete queue"><div className="queue_list__action" onClick={() => { props.handleDeleteConfirm(props.queue) }}><HiTrash/></div></Tooltip>
            </div>
        </div>
    );
};

export default QueueListItem;