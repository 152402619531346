import React from 'react';
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi2';
import StatusText from './StatusText';
import onClickOutside from "react-onclickoutside";

class StatusSelector extends React.Component{
    constructor(props){
        super(props);
        this.agent = this.props.agent;
        this.state = {
            menuShown: false
        }

        this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }

    handleMenuToggle(){
        this.setState((prevState) => {
            return {
                menuShown: !prevState.menuShown
            }
        });
    }
    
    handleClickOutside = evt => {
        if(this.state.menuShown){ 
            this.handleMenuToggle() }
    };


    render(){
        return (
            <div className="status_selector" onClick={this.handleMenuToggle}>
                <StatusText status={this.agent.agent_settings.status}/>{this.state.menuShown ? <HiOutlineChevronUp className="chevron"/> : <HiOutlineChevronDown className="chevron"/>}
                {this.state.menuShown && (
                    <div className="popup_box">               
                        <div className="status_selector__item" onClick={(e) => { this.props.onChange(e, 'available') }}><StatusText status="available"/></div> 
                        <div className="status_selector__item" onClick={(e) => { this.props.onChange(e, 'busy') }}><StatusText status="busy"/></div> 
                    </div>
                )}
            </div>
        );
    };
}

export default onClickOutside(StatusSelector);