import socket from '../services/socket';
import { handleResponse, expiryTime } from './misc';

function login(email, password){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch('/api/agents/login', requestOptions).then(handleLoginResponse).then((agent) => {
        localStorage.setItem('user', JSON.stringify({ ...agent, expiryTime: expiryTime() }));
        return agent;
    });
}

function validatePassword(email, password){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch('/api/agents/login', requestOptions).then(handleResponse).then(({ agent }) => {
        return agent;
    }).catch(e => {
        throw e;
    });
}

function setPassword(authToken, password){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ password, password_set: true })
    };

    return fetch('/api/agents/password', requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    }).catch(e => { throw e });
}

function resetPassword(email){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch('/api/agents/password-reset', requestOptions).then(handleLoginResponse).then((success) => {
        return success;
    }).catch(e => { throw e });
}

function validatePasswordReset(token){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
    };

    return fetch('/api/agents/password-reset/validate', requestOptions).then(handleLoginResponse).then((agent) => {
        localStorage.setItem('user', JSON.stringify({ ...agent, expiryTime: expiryTime() }));
        return agent;
    });
}

function setAgent(authToken, agentId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/agents/' + agentId, requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    })
}

function createAgent(authToken, { first_name, last_name, email, companies, role}){
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ first_name, last_name, email, companies, role })
    };
    return fetch('/api/agents', requestOptions).then(handleLoginResponse).then((agent) => {
        return agent;
    }).catch((e) => {
        throw new Error(e);
    });
}

function updateAgent(authToken, { _id, first_name, last_name, email, companies, role, disabled, deleted, expiry_date }){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ first_name, last_name, email, companies, role, disabled, deleted, expiry_date })
    };
    return fetch('/api/agents/' + _id, requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        if(currentAgent.agent._id === agent._id){
            currentAgent.agent = agent;
            localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        }
        return agent;
    }).catch((e) => {
        throw new Error(e);
    });
}

function deleteAgent(authToken, agentId){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ deleted: true })
    };
    return fetch('/api/agents/' + agentId, requestOptions).then(handleResponse).then((agent) => {
        return agent;
    }).catch((e) => {
        throw new Error(e);
    });
}

function setAvatar(authToken, agentId, url){
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authToken, 'Content-Type': 'application/json' },
        body: JSON.stringify({ url })
    };

    return fetch('/api/agents/' + agentId + '/avatar', requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    });
}

function removeAvatar(authToken, agentId){
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Authorization': 'Bearer ' + authToken, 'Content-Type': 'application/json' }
    };

    return fetch('/api/agents/' + agentId + '/avatar', requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    });
}

function setStatus(authToken, agentId, status){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ status })
    };

    return fetch('/api/agents/' + agentId + '/status', requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    });
}

function setTimedAgent(authToken, agentId, body){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify(body)
    };

    return fetch('/api/agents/' + agentId + '/timed_agent', requestOptions).then(handleLoginResponse).then((agent) => {
        return agent;
    });
}

function setMuted(authToken, agentId, muted){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ muted })
    };

    return fetch('/api/agents/' + agentId + '/mute', requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    });
}

function setCallMuted(authToken, agentId, muted){
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken },
        body: JSON.stringify({ muted })
    };

    return fetch('/api/agents/' + agentId + '/callmute', requestOptions).then(handleLoginResponse).then((agent) => {
        const currentAgent = JSON.parse(localStorage.getItem('user'));
        currentAgent.agent = agent;
        localStorage.setItem('user', JSON.stringify({ ...currentAgent, expiryTime: expiryTime() }));
        return agent;
    });
}

function logout(){
    socket.emit('logout', {});
    localStorage.removeItem('user');
    localStorage.removeItem('selectedChatbot');
    localStorage.removeItem('selectedCompany');
}

function getAgentCompanies(agentId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/agents/' + agentId + '/companies', requestOptions).then(handleLoginResponse).then((companies) => {
        return companies;
    });
}

function getAgent(agentId, authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/agents/' + agentId, requestOptions).then(handleLoginResponse).then((agent) => {
        return agent;
    });
}

function getAgents(authToken){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/agents', requestOptions).then(handleLoginResponse).then((agents) => {
        return agents;
    });
}

function handleLoginResponse(response){
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if(!response.ok){
            if(response.status === 400){
                logout();
            }
            
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export const agentService = {
    login,
    logout,
    setPassword,
    getAgentCompanies,
    setAvatar,
    setAgent,
    setStatus,
    createAgent,
    updateAgent,
    deleteAgent,
    getAgent,
    setMuted,
    removeAvatar,
    setCallMuted,
    getAgents,
    setTimedAgent,
    resetPassword,
    validatePassword,
    validatePasswordReset
};