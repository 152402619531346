import React from 'react';
import { connect } from 'react-redux';
import Chat from './Chat';
import ChatsList from './ChatsList';
import { getConversations, searchConversations, deleteConversaton, getConversationMessages, getConversation } from '../../services/conversation';
import { sendMessage } from '../../services/humanHandoff';
import { uploadMedia } from '../../services/upload';
import Preloader from '../Preloader';
import ChatSidebar from './ChatSidebar';
import MeetingRoom from '../Meetings/MeetingRoom';
import { Prompt } from 'react-router-dom';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';
import { disconnectAgent, getLatestConversationMeeting, setUsersWaiting } from '../../services/meetings';
import { isMobile, isTablet } from '../../services/responsive';
import { newQuickReplies } from '../../services/quickReplies';

import socket from '../../services/socket';
import moment from 'moment';
import { getHelpRequests } from '../../services/helpRequest';
import { setGoToConversation } from '../../actions/chats';

class ChatsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loadingConversations: true,
            chatsLimit: 10,
            chatsOffset: 10,
            messageLimit: 10,
            messageOffset: 10,
            hasMoreConversations: false,
            loadingMoreConversations: false,
            isSearched: false,
            searching: false,
            searchTerm: '',
            termSearched: '',
            conversationToBeDeleted: undefined,
            deletingConversation: false,
            editToggled: false,
            sidebarExpanded: true,
            currentMessages: [],
            filters: {
                agentChats: undefined,
                previousChats: undefined,
                agentView: this.props.auth.agent.agent._id,
                startDate: undefined,
                endDate: undefined,
                queue: undefined
            },
            sortBy: 'desc'
        };

        this.setConversations = this.setConversations.bind(this);
        this.setCurrentMessages = this.setCurrentMessages.bind(this);
        this.setCurrentConversation = this.setCurrentConversation.bind(this);

        this.resetMesageLimit = this.resetMesageLimit.bind(this);
        this.handleConversationClick = this.handleConversationClick.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);
        this.handleLoadMoreConversations = this.handleLoadMoreConversations.bind(this);
        this.handleLoadMoreMessages = this.handleLoadMoreMessages.bind(this);
        this.handleSetFilter = this.handleSetFilter.bind(this);
        this.handleSetSort = this.handleSetSort.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleDeleteTrigger = this.handleDeleteTrigger.bind(this);
        this.handleDeleteConversation = this.handleDeleteConversation.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleMeetingDisconnect = this.handleMeetingDisconnect.bind(this);
        this.handleChatBackClick = this.handleChatBackClick.bind(this);
        this.handleSidebarTrigger = this.handleSidebarTrigger.bind(this);
        this.handleMobileSidebarTrigger = this.handleMobileSidebarTrigger.bind(this);
        this.handleAddToQuickReplies = this.handleAddToQuickReplies.bind(this);
    }

    async handleConversationClick(conversation_id){
        if(this.state.currentConversationId !== conversation_id){
            this.resetMesageLimit();
            this.setState({ loadingConversation: true });

            await this.setCurrentConversation(this.props.auth.selectedChatbot.token, conversation_id);
            await this.setCurrentMessages(this.props.auth.selectedChatbot.token, conversation_id);
        }

        if(this.state.currentConversationId === conversation_id){
            this.setState({ loadingConversation: false });
        }
    }

    async handleSendMessage(messageText, publicMessage, mediaFile){
        let media = null;
        if(mediaFile && !mediaFile.url){
            const uploadResponse = await uploadMedia(this.props.auth.selectedChatbot.token, mediaFile);
            if(uploadResponse.url){
                media = {
                    url: uploadResponse.url,
                    name: mediaFile.name,
                    type: mediaFile.type
                };
            }
        }

        if(mediaFile && mediaFile.url){
            media = mediaFile;
        }

        const message = {
            conversation_id: this.state.currentConversation.conversation_id,
            sender: 'agent',
            agent_sender: this.props.auth.agent.agent._id,
            agent_sender_object: this.props.auth.agent.agent,
            internal: !publicMessage,
            message_data: {
                text: messageText,
                media: media ? [media] : null
            }
        }

        this.setState(prevState => ({ currentMessages: [...prevState.currentMessages, {...message, sending: true}] }))

        sendMessage(this.props.auth.selectedChatbot.token, message, this.state.currentConversation.channel);
    }

    async handleAddToQuickReplies(message, mediaFile){
        let media = null;
        if(mediaFile && !mediaFile.url){
            const uploadResponse = await uploadMedia(this.props.auth.selectedChatbot.token, mediaFile);
            if(uploadResponse.url){
                media = {
                    url: uploadResponse.url,
                    name: mediaFile.name,
                    type: mediaFile.type
                };
            }
        }

        const messageData = {
            media: media ? [media] : [],
            text: message
        }

        await newQuickReplies(this.props.auth.selectedChatbot.token, {message_data: messageData, private: true, owner: this.props.auth.agent.agent._id });
    }

    async componentDidMount(){
        if(isTablet()){
            this.setState({ sidebarExpanded: false })
        }

        let goToConversation = null;
        if(this.props.chats.goToConversation){
            goToConversation = this.props.chats.goToConversation;
            this.setState({ loadingConversation: true })
            try{
                await this.setCurrentConversation(this.props.auth.selectedChatbot.token, goToConversation);
            }catch(e){
            }
        }

        await this.setConversations(this.props.auth.selectedChatbot.token);
        if(!this.state.currentConversation && this.state.conversations){
            if(this.state.conversations.length > 0 && !isMobile()){
                this.setState({ loadingConversation: true })
                await this.setCurrentConversation(this.props.auth.selectedChatbot.token, this.state.conversations[0].conversation_id);
            }
        } 

        if(this.state.currentConversation){
            await this.setCurrentMessages(this.props.auth.selectedChatbot.token, this.state.currentConversation.conversation_id); 
            this.setState({ loadingConversation: false })
        }
        
        socket.on('conversationsUpdated', ({ id, conversationId, userId }) => {
            if(this.props.auth.selectedChatbot._id === id){
                this.setConversations(this.props.auth.selectedChatbot.token);
                if((this.state.currentConversation && this.state.currentConversation._id === conversationId ) || (this.state.currentConversation && this.state.currentConversation.user_id === userId)){
                    this.setCurrentConversation(this.props.auth.selectedChatbot.token, this.state.currentConversation.conversation_id);
                }
            }
        });

        socket.on('messagesUpdated', ({ id, conversationId }) => {
            if(this.props.auth.selectedChatbot._id === id && this.state.currentConversationId && this.state.currentConversationId === conversationId){
                this.setCurrentMessages(this.props.auth.selectedChatbot.token, this.state.currentConversationId);
            }
        });
    }

    componentWillUnmount(){
        socket.off('conversationsUpdated');
        socket.off('messagesUpdated');
    }

    async componentDidUpdate(prevProps){
        if(prevProps.chats.goToConversation !== this.props.chats.goToConversation && this.props.chats.goToConversation){
            let goToConversation = null;
            if(this.props.chats.goToConversation){
                goToConversation = this.props.chats.goToConversation;
                this.setState({ loadingConversation: true })
                try{
                    await this.setCurrentConversation(this.props.auth.selectedChatbot.token, goToConversation);
                }catch(e){
                    this.setState({
                        currentConversation: null, 
                        currentConversationId: null,
                        loadingConversation: false
                    });
                }
            }

            if(this.state.currentConversation){
                await this.setCurrentMessages(this.props.auth.selectedChatbot.token, goToConversation); 
                this.setState({ loadingConversation: false })
            }
        }
    }

    async setConversations(authToken){
        let conversations = [];
        if(!this.state.isSearched){
            let filters = this.state.filters;

            if(!this.props.auth.selectedChatbot.settings.show_all_history){ filters = { ...filters, agentChats: this.props.auth.agent.agent._id } }

            conversations = await getConversations(authToken, this.state.chatsLimit + 1, filters, this.state.sortBy);
        }else{
            conversations = await searchConversations(authToken, this.state.searchTerm, this.state.chatsLimit + 1, this.state.sortBy);
        }

        const hasMoreConversations = conversations.length > this.state.chatsLimit ? true : false ;

        if(hasMoreConversations){
            conversations.pop()
        }
        
        this.setState({ 
            conversations, 
            loadingConversations: false, 
            hasMoreConversations
        });
    }

    async setCurrentMessages(authToken, conversationId){
        const currentMessages = await getConversationMessages(authToken, conversationId, this.state.messageLimit + 1);
        
        if(this.state.currentConversationId === conversationId){
            const hasMoreMessages = currentMessages.length > this.state.messageLimit ? true : false;

            if(hasMoreMessages){
                currentMessages.pop()
            }

            this.setState({
                currentMessages: currentMessages.sort((a, b) => moment(a.createdAt) - moment(b.createdAt)),
                hasMoreMessages
            });
        }
    }

    async setCurrentConversation(authToken, conversationId){
        if(conversationId){
            this.setState({ currentConversationId: conversationId });
            
            let conversation = await getConversation(authToken, conversationId);
            const helpRequests = await getHelpRequests(authToken, 1, { conversation: conversationId })
            const latestMeeting = await getLatestConversationMeeting(authToken, conversation._id);
            if(this.state.currentConversationId === conversationId){
                conversation = { ...conversation, helpRequests, latestMeeting };

                this.setState({
                    currentConversation: conversation
                });
            }
        }else{
            this.setState({
                currentConversation: null
            })
        }

        setGoToConversation(this.props, null);
    }

    handleLoadMoreConversations(){
        this.setState(prevState => ({
            chatsLimit: prevState.chatsLimit + prevState.chatsOffset,
            loadingMoreConversations: true
        }), async () => {
            await this.setConversations(this.props.auth.selectedChatbot.token);
            this.setState({ loadingMoreConversations: false });
        });
    }

    async handleLoadMoreMessages(element){
        if(element.current){
            const chatsElement = element.current.wrapperRef.current;
            const scrollHeight = chatsElement.scrollHeight;
            
            this.setState({ 
                messageLimit: this.state.messageLimit + this.state.messageOffset,
                loadingMoreMessages: true
            }, async () => {        
                await this.setCurrentMessages(this.props.auth.selectedChatbot.token, this.state.currentConversation.conversation_id);
                this.setState({ loadingMoreMessages: false }, () => {
                    element.current.props.animateScroll(chatsElement, (chatsElement.scrollHeight - scrollHeight))
                });
            });
        }
    }

    handleSetFilter(key, value){
        this.setState(prevState => ({loadingConversations: true, filters: {...prevState.filters, [key]: value}}), () => {
            this.setConversations(this.props.auth.selectedChatbot.token);
        });
    }

    handleSetSort(value){
        this.setState(prevState => ({loadingConversations: true, sortBy: value }), () => {
            this.setConversations(this.props.auth.selectedChatbot.token);
        });
    }

    handleSearchChange(e){
        this.setState({ searchTerm: e.target.value })
    }

    handleSearchSubmit(e){
        e.preventDefault();
        if(this.state.searchTerm.trim().length > 0){
            this.setState({ searching: true, isSearched: true, loadingConversations: true }, async () => {
                await this.setConversations(this.props.auth.selectedChatbot.token);
                this.setState({ searching: false, loadingConversations: false, termSearched: this.state.searchTerm });
            });
        }else{
            this.clearSearch();
        }
    }

    handleDeleteTrigger(conversation){
        this.setState({ conversationToBeDeleted: conversation })
    }

    async handleDeleteConversation(){
        const conversationToBeDeleted = this.state.conversationToBeDeleted;
        this.setState({ deletingConversation: true, conversationToBeDeleted: undefined })
        await deleteConversaton(this.props.auth.selectedChatbot.token, conversationToBeDeleted.conversation_id);
        await this.setConversations(this.props.auth.selectedChatbot.token);
        this.setCurrentConversation(this.props, undefined);
        this.setState({ deletingConversation: false })
    }

    async handleMeetingDisconnect(){
        const meeting = this.props.meetings.currentMeeting;
        await this.props.dispatch({
            type: 'SET_MEETINGS',
            meetings: {
                currentMeeting: undefined
            }
        });
        await disconnectAgent(meeting._id, this.props.auth.agent.agent._id)
        await setUsersWaiting(meeting._id, false);
        
    }

    clearSearch(){
        this.setState({ searching: false, isSearched: false, searchTerm: '', termSearched: false, loadingConversations: true }, async () => {
            await this.setConversations(this.props.auth.selectedChatbot.token);
            this.setState({ loadingConversations: false });
        });
    }  
    
    handleChatBackClick(){
        this.setState({
            currentConversationId: undefined,
            currentConversation: undefined
        })
    }

    handleSidebarTrigger(){
        this.setState(prevState => ({ sidebarExpanded: !prevState.sidebarExpanded }));
    }

    handleMobileSidebarTrigger(){
        this.setState(prevState => ({ mobileSidebarExpanded: !prevState.mobileSidebarExpanded }));
    }

    resetMesageLimit(){
        this.setState(prevState => ({
            messageLimit: prevState.messageOffset
        }));
    }

    render(){
        return (
            <div className="chats_app">
                {this.props.meetings.currentMeeting && <Prompt
                    when={this.props.meetings.currentMeeting}
                    message={location => `Please note leaving this page will end your current call.\n\nDo you want to continue?`}
                />}
                <ChatsList 
                    currentConversationId={this.state.currentConversationId}
                    handleSearchSubmit={this.handleSearchSubmit} 
                    searching={this.state.searching} 
                    termSearched={this.state.termSearched} 
                    searchTerm={this.state.searchTerm} 
                    isSearched={this.state.isSearched} 
                    handleSearchChange={this.handleSearchChange} 
                    clearSearch={this.clearSearch}
                    filters={this.state.filters}
                    sortBy={this.state.sortBy} 
                    loadingConversations={this.state.loadingConversations} 
                    currentAgent={this.props.auth.agent.agent} 
                    handleSetFilter={this.handleSetFilter} 
                    handleSetSort={this.handleSetSort}
                    handleLoadMoreConversations={this.handleLoadMoreConversations} 
                    loadingMoreConversations={this.state.loadingMoreConversations} 
                    hasMoreConversations={this.state.hasMoreConversations} 
                    handleConversationClick={this.handleConversationClick} 
                    currentConversation={this.state.currentConversation} 
                    conversations={this.state.conversations}
                    chatsLimit={this.state.chatsLimit}
                    showAllHistory={this.props.auth.selectedChatbot.settings.show_all_history}
                />
                {!this.state.loadingConversation ? 
                    (this.state.currentConversation ? 
                        <Chat 
                            loadingConversation={this.state.loadingConversation}
                            currentConversation={this.state.currentConversation} 
                            setCurrentConversation={this.setCurrentConversation}
                            currentMessages={this.state.currentMessages} 
                            setCurrentMessages={this.setCurrentMessages}
                            handleSendMessage={this.handleSendMessage}
                            loadingMoreMessages={this.state.loadingMoreMessages}
                            handleLoadMoreMessages={this.handleLoadMoreMessages}
                            handleChatBackClick={this.handleChatBackClick}
                            handleMobileSidebarTrigger={this.handleMobileSidebarTrigger}
                            handleAddToQuickReplies={this.handleAddToQuickReplies}
                            hasMoreMessages={this.state.hasMoreMessages}
                        /> : ''
                    ) : <div className="chats_preloader"><Preloader size="25"/></div>}
                <ChatSidebar  
                    loadingConversation={this.state.loadingConversation}
                    currentConversation={this.state.currentConversation} 
                    setCurrentConversation={this.setCurrentConversation}
                    handleDeleteTrigger={this.handleDeleteTrigger}
                    editToggled={this.state.editToggled}
                    sidebarExpanded={this.state.sidebarExpanded}
                    mobileSidebarExpanded={this.state.mobileSidebarExpanded}
                    handleMobileSidebarTrigger={this.handleMobileSidebarTrigger}
                    handleSidebarTrigger={this.handleSidebarTrigger}
                    currentAgent={this.props.auth.agent.agent}
                />
                {this.state.conversationToBeDeleted && <ConfirmModal isOpen={this.state.conversationToBeDeleted ? true : false} onConfirm={this.handleDeleteConversation} onRequestClose={() => { this.handleDeleteTrigger(undefined) }} confirmText="Delete" title="Delete Chat" description={<span>You are about to delete this chat permanently for all agents on the portal. All notes and history for this chat will be lost.<br/><br/>Are you sure you want to do this?</span>}/>}
                {this.props.meetings.currentMeeting && <MeetingRoom minimizable={true} displayName={this.props.auth.agent.agent.first_name + ' ' + this.props.auth.agent.agent.last_name} meeting={this.props.meetings.currentMeeting} agent={this.props.auth.agent.agent} onDisconnect={this.handleMeetingDisconnect}/>}
                {this.state.deletingConversation && <LoadingModal isOpen={this.state.deletingConversation} text="Deleting conversation"/>}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ChatsPage);