import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import alertReducer from '../reducers/alerts';
import authReducer from '../reducers/auth';
import chatsReducer from '../reducers/chats';
import notificationsReducer from '../reducers/notfications';
import internalChatsReducer from '../reducers/internalChatsReducer';
import helpRequestsReducer from '../reducers/helpRequestReducer';
import meetingsReducer from '../reducers/meetingReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    combineReducers({
        alerts: alertReducer,
        auth: authReducer,
        notifications: notificationsReducer,
        chats: chatsReducer,
        internalChats: internalChatsReducer,
        helpRequests: helpRequestsReducer,
        meetings: meetingsReducer
    }),
    composeEnhancers(
        applyMiddleware(thunk)
    )
);

