import Select from 'react-select';

const AgentListFilters = (props) => {
    const filters = [{
        name: 'queue',
        options: { value: 'all', label: 'All Agents' }
    }];
    return (
        <div className="agent_filters">
            {filters.map(filter => {
                return <Select classNamePrefix="custom_select"  name={filter.name} options={filter.options} value={filter.options[0]}/>
            })}
        </div>
    )
}

export default AgentListFilters;