import React from 'react';
import { connect } from 'react-redux';
import ChatTransfer from './ChatTransfer';
import { HiOutlineCheck } from 'react-icons/hi2';
import { invite } from '../../services/humanHandoff';
import { Tooltip } from '@material-ui/core';
import { HiArrowsRightLeft, HiOutlineUserPlus } from 'react-icons/hi2';

class ChatMessageBarActions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            transferShown: false,
            inviteListShown: false,
            invited: false
        };

        this.handleTransferToggle = this.handleTransferToggle.bind(this);
        this.handleInviteToggle = this.handleInviteToggle.bind(this);
        this.handleInvite = this.handleInvite.bind(this);
    }

    handleTransferToggle(){
        this.setState(prevState => ({
            transferShown: !prevState.transferShown
        }));
    }

    handleInviteToggle(){
        this.setState(prevState => ({
            inviteListShown: !prevState.inviteListShown
        }));
    }

    async handleInvite(id){
        await invite(this.props.auth.selectedChatbot.token, this.props.currentConversation.conversation_id, id, this.props.auth.agent.agent._id);   
        this.setState({ invited: true })
        setTimeout(() => {
            this.setState({ invited: false })
        }, 3000)
    }

    render(){
        return (
            <div className="chat_message_bar_actions">
                <Tooltip title="Transfer chat" arrow placement='top'><button className='chat_message_bar__action' onClick={this.handleTransferToggle}><HiArrowsRightLeft/></button></Tooltip>
                <Tooltip title={this.state.invited ? 'Agent invited' : 'Invite Agent to Chat'} arrow placement='top'><button className='chat_message_bar__action' disabled={this.state.invited} onClick={this.handleInviteToggle}>{this.state.invited ? <HiOutlineCheck/> : <HiOutlineUserPlus/>}</button></Tooltip>
                {this.state.transferShown && <ChatTransfer handleTransferToggle={this.handleTransferToggle} conversation={this.props.currentConversation}/>}
                {this.state.inviteListShown && <ChatTransfer agentPicker={true} title="Invite agent" actionText="Inviting" onSelect={this.handleInvite} handleTransferToggle={this.handleInviteToggle} conversation={this.props.currentConversation}/>}
                <div className="chat_message_bar_actions__right">
                    <div className="internal_switch">
                        <div className={`internal_switch__button ${this.props.public ? 'active' : ''}`} onClick={() => { this.props.handleTogglePublic(true) }}>User</div>
                        <div className={`internal_switch__button ${!this.props.public ? 'active' : ''}`} onClick={() => { this.props.handleTogglePublic(false) }}>Internal</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ChatMessageBarActions);