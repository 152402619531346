import React from 'react';
import { connect } from 'react-redux';
import { deleteWhatsAppTemplate, getWhatsAppTemplates } from '../../services/whatsAppTemplateSender';
import AlertBox from '../AlertBox';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';
import Preloader from '../Preloader';
import WhatsAppTemplateListItem from './WhatsAppTemplateListItem';

class WhatsAppTemplateList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loadingTemplates: true
        }

        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.loadTemplates = this.loadTemplates.bind(this);
    }

    componentDidMount(){
        this.loadTemplates()
    }

    async loadTemplates(){
        const templates = await getWhatsAppTemplates(this.props.auth.selectedChatbot.token);
        this.setState({ templates: templates.templates, loadingTemplates: false });
    }

    handleDeleteClick(template){
        this.setState({ templateToDelete: template });
    }

    async handleDeleteConfirm(){
        const templateToDelete = this.state.templateToDelete;
        this.setState({ deletingTemplate: true, templateToDelete: null, deletingError: null })
        try{
            await deleteWhatsAppTemplate(this.props.auth.selectedChatbot.token, templateToDelete.name, templateToDelete.language);
            await this.loadTemplates();
            this.setState({ deletingTemplate: false });
        }catch(e){
            this.setState({ deletingError: true, deletingTemplate: false });
        }
    }

    render(){
        return !this.state.loadingTemplates ? (
            <div>
                {this.state.deletingError && <AlertBox type='alert alert-danger mt-medium'>Failed to delete template message. This could be a problem with WhatsApp itself. Please try again later.</AlertBox>}
                <table className='whatsapp_template_list table'>
                    <col className='whatsapp_template_list_name'/>
                    <col className='whatsapp_template_list_language'/>
                    <col className='whatsapp_template_list_status'/>
                    {this.props.editable && <col className='whatsapp_template_list_actions'/> }
                    <thead>
                        <tr>
                            <th>Template Name</th>
                            <th>Language</th>
                            <th>Status</th>
                            {this.props.editable && <th>Actions</th> }
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.templates.map(template => (
                            <WhatsAppTemplateListItem allowPending={this.props.allowPending} onDelete={this.handleDeleteClick} editable={this.props.editable} onClick={this.props.onClick} template={template}/>
                        ))}
                    </tbody>
                    {this.state.deletingTemplate && <LoadingModal isOpen={this.state.deletingTemplate} text="Deleting template"/>}
                    {this.state.templateToDelete && <ConfirmModal onConfirm={this.handleDeleteConfirm} onRequestClose={() => { this.handleDeleteClick(); }} isOpen={this.state.templateToDelete} title="Delete Template" description={<div>Your are about to delete <b>{this.state.templateToDelete.name}</b> permanently. This is will delete the message on Meta and is not reversable. Are you sure you wish to proceed?</div>} confirmText="Delete Template"/>}
                </table>
            </div>
        ) : <Preloader/>;
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppTemplateList);