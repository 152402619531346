import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import AgentProfileAvatar from './AgentProfileAvatar';
import ChangeAvatar from './ChangeAvatar';
import { agentService } from '../../services/agents';
import { uploadMedia } from '../../services/upload';
import { agentActions } from '../../actions/agents';
import Badge from '../Base/Badge';
import Status from '../Base/Status';
import EditAgentForm from './EditAgentForm';
import QuickReplyList from '../QuickReplies/QuickReplyList';
import AgentResetForm from '../PasswordReset/AgentResetForm';
import AlertBox from '../AlertBox';

class AgentPage extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agent: this.props.auth.agent.agent,
            changingAvatar: false,
            editSubmiting: false,
            quickReplySearchValue: ''
        };

        this.handleImageSelect = this.handleImageSelect.bind(this);
        this.handleImageChanged = this.handleImageChanged.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleAvatarRemove = this.handleAvatarRemove.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.auth.agent.agent !== this.props.auth.agent.agent){
            this.setState({ agent: this.props.auth.agent.agent })
        }
    }

    handleImageSelect(inputElement){
        inputElement.click();
    }

    handleEditSubmit(e, agent){
        e.preventDefault();
        this.setState({ editSubmiting: true });
        agentService.updateAgent(this.props.auth.agent.token, agent).then((agentRecieved) => {
            this.props.dispatch(agentActions.setCurrentAgent(agentRecieved));
            this.setState({ editSubmiting: false });
        });

    }

    async handleAvatarRemove(){
        this.setState({changingAvatar: true})
        const agent = await agentService.removeAvatar(this.props.auth.agent.token, this.state.agent._id);
        await this.props.dispatch({
            type: 'AVATAR_SET',
            agent
        } );
        this.setState({changingAvatar: false})
    }

    async handleImageChanged(e){
        this.setState({changingAvatar: true, error: '' })
        try{ 
            const uploadResponse = await uploadMedia(this.props.auth.selectedChatbot.token, e.target.files[0], true);
            agentActions.setAvatar(this.props.auth.agent.token, this.state.agent._id, uploadResponse.url).then((agent) => {
                this.props.dispatch({
                    type: 'AVATAR_SET',
                    agent
                } );
                
                this.setState({ changingAvatar: false });
            });
        }
        catch(e){
            this.setState({ error: 'Failed to upload file', changingAvatar: false })
        }
    }

    handleSearchChange(e){
        this.setState({ quickReplySearchValue: e.target.value })
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-6 agent_profile_card">
                            <AgentProfileAvatar size={150} changingAvatar={this.state.changingAvatar} agent={this.state.agent}/>
                            <ChangeAvatar handleImageSelect={this.handleImageSelect} handleImageChanged={this.handleImageChanged} handleAvatarRemove={this.handleAvatarRemove}/>
                            {this.state.error && <AlertBox type="alert-danger mt-medium">{this.state.error}</AlertBox>}
                        </div>
                        <div className="col-6">
                            <div className="agent_info">
                                <div className="agent_name">{this.state.agent.first_name} {this.state.agent.last_name}</div>
                                <Status agent={this.state.agent}/>
                                <Badge text={this.state.agent.role}/>
                            </div>
                        </div>
                    </div>
                </PageContainer>
                <PageContainer>
                    <h2>Edit Information</h2>
                    <EditAgentForm submiting={this.state.editSubmiting} onSubmit={this.handleEditSubmit} agent={this.state.agent}/>
                </PageContainer>
                <PageContainer>
                    <h2>Quick Replies</h2>
                    <p>Edit your quick replies for in-chat template relpying.</p>
                    <QuickReplyList handleSearchChange={this.handleSearchChange} messageValue={this.state.quickReplySearchValue} showSearch={true} editable={true}/>
                </PageContainer>
                <PageContainer>
                    <h2>Reset Password</h2>
                    <AgentResetForm/>
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AgentPage);