import React from 'react';
import { connect } from 'react-redux';
import { agentService } from '../../services/agents';
import { updateQueue } from '../../services/queues';
import Preloader from '../Preloader';
import AgentListItem from './AgentListItem';
import { HiUserPlus } from 'react-icons/hi2';
import AgentListFilters from './AgentListFilters';
import EditAgentModal from './EditAgentModal';
import LoadingModal from '../Base/LoadingModal';
import ConfirmModal from '../Base/ConfirmModal';

import socket from '../../services/socket';
import moment from 'moment';

class AgentList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            filtersShown: false,
            editAgentFormOpen: false,
            selectedAgent: undefined,
            savingAgent: false,
            confirmDeleteAgent: undefined,
            deletingAgent: false,
            searchTerm: '',
            errorMessage: ''
        }

        this.handleToggleFilters = this.handleToggleFilters.bind(this);
        this.handleToggleEditAgent = this.handleToggleEditAgent.bind(this);
        this.getAgentQueues = this.getAgentQueues.bind(this);
        this.handleAgentSave = this.handleAgentSave.bind(this);
        this.handleDeleteAgentConfirm = this.handleDeleteAgentConfirm.bind(this);
        this.handleDeleteAgent = this.handleDeleteAgent.bind(this);
        this.updateQueueAgents = this.updateQueueAgents.bind(this);
        this.handleSearchAgent = this.handleSearchAgent.bind(this);
    }

    componentDidMount(){
        socket.on('agentsUpdate', async ({ id }) => {
            if(this.props.loadAgents){
                await this.props.loadAgents()
            }
        });
    }

    componentWillUnmount(){
        socket.off('agentsUpdate');
    }

    handleToggleFilters(){
        this.setState(prevState => ({ filtersShown: !prevState.filtersShown }))
    }

    handleToggleEditAgent(agent){
        this.setState({ selectedAgent: agent, editAgentFormOpen: agent ? true : false, errorMessage: '' })
    }

    async handleAgentSave(agent, agentQueues){
        this.setState({ savingAgent: true, editAgentFormOpen: false, selectedAgent: undefined, errorMessage: '' });
        try{
            if(agent._id){
                // Edit Agent
                await agentService.updateAgent(this.props.auth.agent.token, agent);
                await agentService.setTimedAgent(this.props.auth.agent.token, agent._id, agent.agent_settings.timed_agent);
                if(!this.props.noCompany){
                    await this.updateQueueAgents(agent, agentQueues);
                } 
                await this.props.loadAgents()
    
                return this.setState({ savingAgent: false });            
            }
            
            // New Agent
            const createdAgent = await agentService.createAgent(this.props.auth.agent.token, {...agent, companies: [(this.props.auth && this.props.auth.selectedCompany) ? this.props.auth.selectedCompany : null]});
            if(!this.props.noCompany){
                await this.updateQueueAgents(createdAgent.agent, agentQueues);
            } 
            await this.props.loadAgents()
            this.setState({ savingAgent: false });  
        } catch(e){
            this.setState({ savingAgent: false, editAgentFormOpen: true, selectedAgent: agent, errorMessage: e.message });
        }
        
    }

    async updateQueueAgents(agent, agentQueues){
        this.props.queues.forEach(async (queue) => {
            let queueAgents = queue.agents.filter(queueAgent => queueAgent._id !== agent._id);
            if(agentQueues.find(agentQueue => agentQueue._id === queue._id)){
                queueAgents.push(agent)
            }

            const queueAgentIds = queueAgents.map(queueAgent => queueAgent._id);

            await updateQueue(this.props.auth.selectedChatbot.token, queue._id, { agents: queueAgentIds });
        })
    }

    handleDeleteAgentConfirm(agent){
        this.setState({ confirmDeleteAgent: agent })
    }

    async handleDeleteAgent(){
        const agentToDelete = this.state.confirmDeleteAgent;
        this.setState({ deletingAgent: true, confirmDeleteAgent: undefined });
        await agentService.deleteAgent(this.props.auth.agent.token, agentToDelete._id);
        await this.props.loadAgents()
        this.setState({ deletingAgent: false });
    }

    getAgentQueues(agent){
        let agentQueues = [];
        if(this.props.queues && this.props.queues.length > 0){
            agentQueues = this.props.queues.filter(queue => queue.agents.find(queueAgent => queueAgent._id === agent._id))
        }   

        return agentQueues;
    }

    handleSearchAgent(e){
        this.setState({ searchTerm: e.target.value })
    }

    render(){
        let agents = this.props.agents;
        if(this.state.searchTerm.trim().length > 0){
            const searchTerm = this.state.searchTerm.trim().toLowerCase();
            agents = agents.filter(agent => {
                let isMatch = false;
                if((agent.first_name + " " + agent.last_name).trim().toLowerCase().includes(searchTerm)){ isMatch = true }
                if(agent.email.trim().toLowerCase().includes(searchTerm)){ isMatch = true }
                return isMatch;
            });
        }

        const onlineAgents = [];
        let offlineAgents = [];
        agents.forEach(agent => {
           if(agent.agent_settings.status !== 'offline'){
               onlineAgents.push(agent);
           } else{
               offlineAgents.push(agent);
           }
        })

        offlineAgents = offlineAgents.sort((a, b) => {
            return moment(a.last_seen).unix() - moment(b.last_seen).unix();
        });

        offlineAgents = offlineAgents.sort((a, b) => {
            return !a.last_seen && b.last_seen ? 1 : -1;
        });
        
        offlineAgents = offlineAgents.sort((a, b) => {
            return !a.disabled && b.disabled ? -1 : 1;
        });

        return (
            <div className="agent_list">
                {!this.props.isSelectList && <button className="btn mb-medium" onClick={() => { this.handleToggleEditAgent({ first_name: '', last_name: '', email: '', role: undefined, agent_settings: { timed_agent: false } }) }}><HiUserPlus/>Add Agent</button>}
                <div className="agent_list_topbar">
                    <input type="text" placeholder="Type to search agents" onChange={this.handleSearchAgent}/>
                    <div className="agent_list_topbar__actions">
                        {this.state.filtersShown && <AgentListFilters/>}
                    </div>
                </div>
                {!this.props.loadingAgents ? agents && agents.length > 0 ? (
                    <div>                        
                        {onlineAgents.length > 0 && <label>Online Agents ({onlineAgents.length})</label>}
                        <div className="agent_list__list mb-medium">{onlineAgents.map(agent => <AgentListItem isSelectList={this.props.isSelectList} onSelect={this.props.onSelect} isSelected={(this.props.isSelectList && this.props.selectedAgents && this.props.selectedAgents.find(selectedAgent => selectedAgent._id === agent._id)) ? true : false} key={agent._id} handleDeleteAgentConfirm={this.handleDeleteAgentConfirm} getAgentQueues={this.getAgentQueues} agent={agent} handleToggleEditAgent={this.handleToggleEditAgent} currentAgent={this.props.currentAgent}/>)}</div>
                        {offlineAgents.length > 0 && <label>Offline Agents ({offlineAgents.length})</label>}
                        <div className="agent_list__list">{offlineAgents.map(agent => <AgentListItem isSelectList={this.props.isSelectList} onSelect={this.props.onSelect} isSelected={(this.props.isSelectList && this.props.selectedAgents && this.props.selectedAgents.find(selectedAgent => selectedAgent._id === agent._id)) ? true : false} key={agent._id} handleDeleteAgentConfirm={this.handleDeleteAgentConfirm} getAgentQueues={this.getAgentQueues} agent={agent} handleToggleEditAgent={this.handleToggleEditAgent} currentAgent={this.props.currentAgent}/>)}</div>
                    </div>
                ) : <span>No agents found</span> : <Preloader/>}
                {this.state.selectedAgent && <EditAgentModal errorMessage={this.state.errorMessage} noCompany={this.props.noCompany} isOpen={this.state.editAgentFormOpen} handleAgentSave={this.handleAgentSave} getAgentQueues={this.getAgentQueues} queues={this.props.queues} onRequestClose={this.handleToggleEditAgent} agent={this.state.selectedAgent}/>}
                {this.state.savingAgent && <LoadingModal isOpen={this.state.savingAgent} text="Saving agent"/>}
                {this.state.confirmDeleteAgent && <ConfirmModal isOpen={this.state.confirmDeleteAgent ? true : false} title="Delete Agent" onConfirm={this.handleDeleteAgent} onRequestClose={() => { this.handleDeleteAgentConfirm() }} description="You are about to delete this users account. Are you sure you want to do this?" confirmText="Delete"/>}
                {this.state.deletingAgent && <LoadingModal isOpen={this.state.deletingAgent} text="Deleting agent"/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AgentList);