const initialState = {
    helpRequests: [],
    helpRequestsCount: 0,
    loadingHelpRequest: true,
    limit: 10,
    offset: 10,
    hasMore: false,
    isSearched: false,
    searchTerm: '',
    filters: {
        orderBy: 'desc'
    }
}

export default function helpRequests(state = initialState, action){
    switch(action.type){
        case 'HELP_REQUESTS_CLEAR':
            return initialState;
        case 'HELP_REQUESTS_SET':
            return {
                ...state,
                helpRequests: action.helpRequests,
                loadingHelpRequests: action.loadingHelpRequests,
                isSearched: action.isSearched,
                hasMore: action.hasMore || false,
                helpRequestsCount: action.count
            }
        case 'HELP_REQUESTS_SET_COUNT':
            return {
                ...state,
                helpRequestsCount: action.count
            }
        case 'HELP_REQUESTS_SEARCH_TERM_UPDATE':
            return {
                ...state,
                searchTerm: action.searchTerm
            }
        case 'LOADING_HELP_REQUESTS':
            return {
                ...state,
                loadingHelpRequests: action.loadingHelpRequests
            }
        case 'SET_HELP_REQUESTS_LIMIT':
            return {
                ...state,
                limit: action.limit
            }
        case 'SET_HELP_REQUEST_FILTERS':
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.filter
                }
            }
        default: 
            return state;
    }
}