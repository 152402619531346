import React from 'react';
import { connect } from 'react-redux';
import ChatNotesList from './ChatNotesList';
import ChatsAddNote from './ChatsAddNote';
import { addNote, removeNote } from '../../services/conversation'
import moment from 'moment';
import ConfirmModal from '../Base/ConfirmModal';
import { removeNull } from '../../services/misc';
import LoadingModal from '../Base/LoadingModal';

class ChatNotes extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            chat: this.props.chat,
            addingNote: false,
            savingNote: false,
            noteValue: '',
            noteMaxLength: 300,
            deletePromptShown: false,
            deletingNote: false,
            currentNote: undefined
        };

        this.handleAddNoteTrigger = this.handleAddNoteTrigger.bind(this);
        this.handleAddNoteChange = this.handleAddNoteChange.bind(this);
        this.handleAddNoteSubmit = this.handleAddNoteSubmit.bind(this);
        this.handleNoteDelete = this.handleNoteDelete.bind(this);
        this.handleNoteDeleteModalTrigger = this.handleNoteDeleteModalTrigger.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.chat && (prevProps.chat.notes !== this.props.chat.notes)){
            this.setState({ chat: this.props.chat })
        }
    }

    handleAddNoteTrigger(){
        this.setState(prevState => ({ addingNote: !prevState.addingNote, noteValue: '' }))
    }

    handleAddNoteChange(e){
        if(e.target.value.length <= this.state.noteMaxLength){
            this.setState({ noteValue: e.target.value });
        }
    }

    handleAddNoteSubmit(e){
        e.preventDefault();
        this.setState({ 
            savingNote: true
        });
        const note = { text: this.state.noteValue, owner: this.props.auth.agent.agent._id };
        addNote(this.props.auth.selectedChatbot.token, this.props.chat._id, note).then((chat) => {
            this.setState(prevState => ({ 
                noteValue: '',
                addingNote: false,
                savingNote: false,
                chat: {...prevState.chat, notes: [...prevState.chat.notes, {
                    ...note,
                    createdAt: moment(),
                    owner: this.props.auth.agent.agent
                }]}
            }));
        });
    }

    handleNoteDeleteModalTrigger(noteId){
        this.setState(prevState => ({ deletePromptShown: !prevState.deletePromptShown, currentNote: noteId || undefined }));
    }

    async handleNoteDelete(){
        this.setState({ deletePromptShown: false, deletingNote: true });
        await removeNote(this.props.auth.selectedChatbot.token, this.props.chat._id, this.state.currentNote);
        if(this.props.chat.currentConversation){
            await this.props.setCurrentConversation(this.props.auth.selectedChatbot.token, this.props.chat.conversation_id);
        }
        this.setState({ currentNote: undefined, deletingNote: false });
    }

    render(){
        return (
            <div className="notes">
                <ChatNotesList handleNoteDeleteModalTrigger={this.handleNoteDeleteModalTrigger} notes={removeNull(this.state.chat.notes)}/>
                <ChatsAddNote addingNote={this.state.addingNote} savingNote={this.state.savingNote} noteMaxLength={this.state.noteMaxLength} noteValue={this.state.noteValue} handleAddNoteSubmit={this.handleAddNoteSubmit} handleAddNoteTrigger={this.handleAddNoteTrigger} handleAddNoteChange={this.handleAddNoteChange}/>
                <ConfirmModal confirmText="Delete" title="Delete Note" description="Please note you are about to delete this note. This action cannot be undone." onRequestClose={this.handleNoteDeleteModalTrigger} isOpen={this.state.deletePromptShown} onConfirm={this.handleNoteDelete}/>
                <LoadingModal text="Deleting note" isOpen={this.state.deletingNote}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ChatNotes);