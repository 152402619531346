import React from 'react';
import { toTimestamp } from '../../services/dates';
import ChatChannelIcon from './ChatChannelIcon';
import LastActivity from './LastActivity';
import newNotificationSound from '../../sounds/message_sound.mp3';

export default class ChatListItem extends React.Component{
    constructor(props){
        super(props);

        this.audio = new Audio(newNotificationSound)
    }
    
    componentDidUpdate(prevProps){
        const currentAgent = this.props.currentAgent;
        const prevNotification = prevProps.conversation.notifications && prevProps.conversation.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
        const notification = this.props.conversation.notifications && this.props.conversation.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
        if(notification && prevNotification && prevNotification.messages.length< notification.messages.length){
            if(!currentAgent.agent_settings.notifications_muted){
                this.audio.play();
                setTimeout(() => {
                    this.audio.pause()
                    this.audio.currentTime = 0;
                }, 600);
            }
        }
    }
    render(){
        const conversation = this.props.conversation;
        const currentAgent = this.props.currentAgent;
        const userName = (this.props.conversation.user && this.props.conversation.user.user_info && this.props.conversation.user.user_info.name) ? this.props.conversation.user.user_info.name : conversation.conversation_id;
        const notification = this.props.conversation.notifications && this.props.conversation.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString())
        return (
            <div className={`chat_list__item ${this.props.currentConversationId && this.props.currentConversationId === conversation.conversation_id ? 'active' : ''}`} onClick={() => {this.props.handleConversationClick(conversation.conversation_id)}}>
                <ChatChannelIcon channel={conversation.channel} status={conversation.status}/>
                <div className="chat_list__item_details">
                    <div className="chat_list__item__title text-truncate">{userName}</div>
                    {conversation.last_activity && <LastActivity currentAgent={currentAgent} lastActivity={conversation.last_activity}/>}
                    <div className="chat_list__item__timestamp">{toTimestamp(conversation.updatedAt)}</div>
                </div>                    
                {(notification && notification.unread) && <div className="notification_badge">{notification.messages.length}</div>}
            </div>
        );
    }
}