import React from 'react';
import { connect } from 'react-redux';
import { HiVideoCamera, HiClipboardDocumentCheck, HiClipboardDocument } from 'react-icons/hi2';
import { createMeeting, validateMeeting } from '../../services/meetings';
import { sendMessage } from '../../services/humanHandoff';
import Preloader from '../Preloader';
import onClickOutside from "react-onclickoutside";
import { createEvent } from '../../services/conversation';

class MeetingSetup extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            meeting: this.props.meeting || undefined,
            currentChat: this.props.conversation,
            meetingTitle: this.props.auth.agent.agent ? 'Meeting with ' + this.props.auth.agent.agent.first_name : '',
            sharingLink: false,
            linkShared: false,
            generatingMeeting: false
        };

        this.handleMeetingTitleChange = this.handleMeetingTitleChange.bind(this);
        this.handleMeetingSetup = this.handleMeetingSetup.bind(this);
        this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
        this.acceptCall = this.acceptCall.bind(this);
        this.handleSendLink = this.handleSendLink.bind(this);
    }
    
    componentDidUpdate(prevProps){
        if(prevProps.currentChat !== this.props.currentChat){
            this.setState({ currentChat: this.props.currentChat });
        }

        if(prevProps.meeting !== this.props.meeting){
            this.setState({ meeting: this.props.meeting });
        }
    }

    handleMeetingTitleChange(e){
        this.setState({ meetingTitle: e.target.value })
    }
    
    handleClickOutside = evt => {
        this.props.toggleMeetingSetup()
    };

    async handleSendLink(){
        this.setState({ sharingLink: true })
        const messageObject = {
            conversation_id: this.state.currentChat.conversation_id,
            sender: 'agent',
            agent_sender: this.props.auth.agent.agent._id,
            agent_sender_object: this.props.auth.agent.agent,
            message_data: {
                text: this.props.auth.agent.agent.first_name + ' ' + this.props.auth.agent.agent.last_name + ' has setup a meeting. Please connect by clicking on the link below\n\n' + window.location.origin.toString() + '/meeting/' + this.state.meeting._id
            }
        }

        await sendMessage(this.props.auth.selectedChatbot.token, messageObject, this.state.currentChat.channel);
        this.setState({ sharingLink: false, linkShared: true })
    }

    async handleMeetingSetup(){
        this.setState({ generatingMeeting: true });
        const createdMeeting = await createMeeting(this.props.auth.selectedChatbot.token, this.state.meetingTitle, this.props.auth.agent.agent._id, this.state.currentChat._id);
        const meeting = await validateMeeting(createdMeeting._id);
        this.setState({ meeting, generatingMeeting: false });
        
        await createEvent(this.props.auth.selectedChatbot.token, this.state.currentChat.conversation_id, 'Meeting Created', `${this.props.auth.agent.agent.first_name} ${this.props.auth.agent.agent.last_name} setup a meeting.`)
        this.props.setCurrentConversation(this.props.auth.selectedChatbot.token, this.props.conversation.conversation_id);      
    }
    
    async acceptCall(withVideo){
        await this.props.dispatch({
            type: 'SET_MEETINGS',
            meetings: {
                currentMeeting: this.state.meeting
            }
        });
    }

    handleCopyToClipboard(){
        const link = window.location.origin.toString() + '/meeting/' + this.state.meeting._id;
        navigator.clipboard.writeText(link);
        this.setState({ copied: true }, () => { 
            setTimeout(() => {
                this.setState({ copied: false })
            }, 10000)
        })
    }

    render(){
        return (
            <div className="chat_action_widget">
                <div className="widget_title"><HiVideoCamera/> Setup Call</div>
                {!this.state.generatingMeeting ? !this.state.meeting ? (
                    <div>
                        <label>Meeting Name:</label>
                        <input type="text" onChange={this.handleMeetingTitleChange} value={this.state.meetingTitle} placeholder="Enter meeting name"/>
                        <div className="chat_action_widget__actions">     
                            <button className="btn" disabled={this.state.meetingTitle.length === 0} onClick={this.handleMeetingSetup}>Setup Call</button>
                        </div>
                    </div>    
                ) : (
                    <div>
                        <div className="widget_body">Your meeting has been setup. Please see meeting link below.<br/><br/>
                            <div onClick={!this.state.copied ? () => { this.handleCopyToClipboard() } : () => {}} className='widget_body__copy'>{this.state.copied ? <HiClipboardDocumentCheck/> : <HiClipboardDocument/>}{this.state.copied ? 'Copied to clipboard' : 'Copy meeting Link'}</div>
                        </div>
                        <div className="chat_action_widget__actions">                            
                            <button className="btn" disabled={this.state.sharingLink || this.state.linkShared} onClick={this.handleSendLink}>{this.state.sharingLink ? <span>Sharing...</span> : (this.state.linkShared ? <span>Link Shared</span> : <span>Send to User</span>)}</button>
                            <button className="btn" onClick={this.acceptCall}>Join Meeting</button>
                        </div>
                    </div> 
                ) : <div><Preloader/></div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(onClickOutside(MeetingSetup));