import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import LoadMore from '../Base/LoadMore';
import ChatFilters from './ChatFilters';
import ChatSort from './ChatSort';
import ChatListActions from './ChatListActions';
import ChatListItem from './ChatListItem';
import ChatSearch from './ChatSearch';
import ChatsPreloader from './ChatsPreloader';
import { HiOutlineChevronDown } from 'react-icons/hi2';

export default class ChatsList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            filtersShown: false,
            searchShown: false,
            sortShown: false,
            currentCollapsed: false,
            allCollapsed: false
        };

        this.handleFilterTrigger = this.handleFilterTrigger.bind(this);
        this.handleSearchTrigger = this.handleSearchTrigger.bind(this);
        this.handleSortTrigger = this.handleSortTrigger.bind(this);
        this.handleClearSearchClick = this.handleClearSearchClick.bind(this);
        this.handleCollapseToggle = this.handleCollapseToggle.bind(this);
    }

    handleFilterTrigger(){
        this.setState(prevState => ({ filtersShown: !prevState.filtersShown, searchShown: false, sortShown: false }))
    } 
    
    handleSearchTrigger(){
        this.setState(prevState => ({ searchShown: !prevState.searchShown, filtersShown: false, sortShown: false }))
    }

    handleSortTrigger(){
        this.setState(prevState => ({ sortShown: !prevState.sortShown, filtersShown: false, searchShown: false }))
    }

    handleClearSearchClick(){
        this.props.clearSearch();
        this.setState({ searchShown: false });
    }

    handleCollapseToggle(fieldName){
        this.setState(prevState => ({
            [fieldName]: !prevState[fieldName]
        }))
    }

    render(){
        const currentChats = this.props.conversations && this.props.conversations.length > 0 ? this.props.conversations.filter(conversation => conversation.current === true) : [];
        const allChats = this.props.conversations && this.props.conversations.length > 0 ? this.props.conversations.filter(conversation => !conversation.current) : [];
        return (
           <div className="chats_list">
                <ChatListActions 
                    isSearched={this.props.isSearched} 
                    termSearched={this.props.termSearched} 
                    searching={this.props.searching}
                    filters={this.props.filters} 
                    sortBy={this.props.sortBy} 
                    currentAgent={this.props.currentAgent} 
                    handleSetFilter={this.props.handleSetFilter} 
                    handleFilterTrigger={this.handleFilterTrigger} 
                    handleSearchTrigger={this.handleSearchTrigger}
                    handleSortTrigger={this.handleSortTrigger}
                    conversations={this.props.conversations}
                    filtersShown={this.state.filtersShown}
                    searchShown={this.state.searchShown}
                    sortShown={this.state.sortShown}
                />
                {this.state.sortShown && <ChatSort sortBy={this.props.sortBy} handleSetSort={this.props.handleSetSort}/>}
                {this.state.filtersShown && <ChatFilters filters={this.props.filters} handleFilterTrigger={this.handleFilterTrigger} handleSetFilter={this.props.handleSetFilter}/>}
                {this.state.searchShown && <ChatSearch handleClearSearchClick={this.handleClearSearchClick} handleSearchSubmit={this.props.handleSearchSubmit} handleSearchChange={this.props.handleSearchChange} searchTerm={this.props.searchTerm} isSearched={this.props.isSearched}/>}
                {!this.props.loadingConversations ? (
                    <div className="chat_list__inner_list">
                    {
                        currentChats && currentChats.length > 0 && (
                            <div>
                                <div className="chat_list__subtitle" onClick={() => { this.handleCollapseToggle('currentCollapsed') }}><HiOutlineChevronDown className={this.state.currentCollapsed ? 'rotated' : ''}></HiOutlineChevronDown><span>Connected Conversations</span></div>
                                <div className={`list ${this.state.currentCollapsed ? 'collapsed' : ''}`}>
                                    {currentChats.map((conversation) => (
                                        <ChatListItem key={conversation._id} currentConversationId={this.props.currentConversationId} handleConversationClick={this.props.handleConversationClick} currentConversation={this.props.currentConversation} conversation={conversation} currentAgent={this.props.currentAgent} />
                                    ))}
                                </div>
                            </div>
                        )
                    }
                    {    
                        (this.props.showAllHistory && allChats && allChats.length > 0) && (
                            <div>
                                <div className="chat_list__subtitle" onClick={() => { this.handleCollapseToggle('allCollapsed') }}><HiOutlineChevronDown className={this.state.allCollapsed ? 'rotated' : ''}></HiOutlineChevronDown><span>All Conversations</span></div>
                                <div className={`list ${this.state.allCollapsed ? 'collapsed' : ''}`}>
                                    {allChats.map((conversation) => (
                                        <ChatListItem key={conversation._id} currentConversationId={this.props.currentConversationId} handleConversationClick={this.props.handleConversationClick} currentConversation={this.props.currentConversation} conversation={conversation} currentAgent={this.props.currentAgent} />
                                    ))}
                                </div>
                            </div>
                        )
                    }
                    {(this.props.conversations && this.props.hasMoreConversations) &&  <VisibilitySensor onChange={(isVisible) => { if(isVisible){ this.props.handleLoadMoreConversations() } }}><LoadMore loadingMore={this.props.loadingMoreConversations} onClick={this.props.handleLoadMoreConversations}/></VisibilitySensor>}

                    </div>
                ) : <ChatsPreloader/>}
           </div>
       );
    }
}