import React from 'react';
import { connect } from 'react-redux';
import { HiOutlineXMark, HiOutlinePencilSquare } from 'react-icons/hi2';
import InternalChatSearch from './InternalChatSearch';
import Preloader from '../Preloader';
import InternalChatsList from './InternalChatsList';
import InternalChatRoom from './InternalChatRoom';
import { setInternalChats } from '../../services/internalChats';
import InternalChatNewChat from './InternalChatNewChat';
import InternalChatSearchResults from './InternalChatSearchResults';
import Tooltip from '@material-ui/core/Tooltip';

class InternalChatWidget extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            searchTerm: '',
            newChatOpen: false,
            isSearched: false,
            searchResults: []
        };

        this.handleSelectChat = this.handleSelectChat.bind(this);
        this.handleDeselectChat = this.handleDeselectChat.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleNewChatClick = this.handleNewChatClick.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    handleSelectChat(chat){
        this.props.dispatch({
            type: 'SET_SELECTED_CHAT',
            selectedChat: chat
        });

        this.setState({ newChatOpen: false })
    }

    handleDeselectChat(){
        this.props.dispatch({
            type: 'DESELECT_CHAT'
        });
    }

    handleSearchChange(e){
        this.setState({
            searchTerm: e.target.value
        });
    }

    handleNewChatClick(){
        this.setState(prevState => ({ newChatOpen: !prevState.newChatOpen }));
    }

    async handleSearchSubmit(e){
        if(e){
            e.preventDefault();
        }
        if(this.state.searchTerm.trim().length > 0){
            this.setState({ isSearched: this.state.searchTerm })

            
        }else{
            this.clearSearch();
        }
    }

    clearSearch(){
        this.setState({ searchTerm: '', isSearched: '' });
        this.props.dispatch({
            type: 'INTERNAL_CHATS_SET',
            loadingChats: true
        });
        setInternalChats(this.props);
    }

    async handleLoadMore(){
        await this.props.dispatch({
            type: 'INTERNAL_CHATS_UPDATE',
            updates: {
                loadingMore: true,
                internalChatsLimit: this.props.internalChats.internalChatsLimit + this.props.internalChats.internalChatsOffset
            }
        });
        if(this.state.searchTerm.trim().length > 0){
            this.handleSearchSubmit();
        }else{
            await setInternalChats(this.props);
        }
        await this.props.dispatch({
            type: 'INTERNAL_CHATS_UPDATE',
            updates: {
                loadingMore: false
            }
        });
    }

    render(){
        return (
            <div className="internal_chat_widget">
                {!this.props.internalChats.selectedChat || (this.props.internalChats.selectedChat && !(this.props.internalChats.selectedChat.chat && this.props.internalChats.selectedChat.chat.participants)) ? (
                    <div className="internal_chat_widget__menu">
                        {!this.state.newChatOpen ? (
                            <div className="flex_grow_container">
                                <div className="internal_chat_widget__title">Chats <div className="internal_chat_widget__close_chat" onClick={this.props.handleToggleChat}><HiOutlineXMark/></div></div>
                                <div className="internal_chat_top_bar">
                                    <InternalChatSearch searchTerm={this.state.searchTerm} clearSearch={this.clearSearch} handleSearchSubmit={this.handleSearchSubmit} handleSearchChange={this.handleSearchChange}/>
                                    <div className="internal_chat_top_bar__actions">
                                        <Tooltip title="New Chat" arrow><div className="internal_chat_top_bar__action" onClick={this.handleNewChatClick}><HiOutlinePencilSquare/></div></Tooltip>
                                    </div>
                                </div>
                                <div className="internal_chat_widget__content">
                                    {!this.state.isSearched ? (
                                        !this.props.internalChats.loadingChats ? <InternalChatsList handleNewChatClick={this.handleNewChatClick} handleSelectChat={this.handleSelectChat} handleLoadMore={this.handleLoadMore}/> : <Preloader/>
                                    ) : <InternalChatSearchResults searchTerm={this.state.isSearched}/>}
                                </div>
                            </div>
                        ) : (
                            <InternalChatNewChat handleSelectChat={this.handleSelectChat} handleNewChatClick={this.handleNewChatClick} handleToggleChat={this.props.handleToggleChat}/>
                        )}
                    </div>                        
                ) : (
                    <InternalChatRoom handleToggleChat={this.props.handleToggleChat} handleDeselectChat={this.handleDeselectChat}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalChatWidget);