import React from 'react';
import { connect } from 'react-redux';
import AgentList from './AgentList';
import { companyService } from '../../services/company';
import { agentService } from '../../services/agents';
import { getQueues } from '../../services/queues';
import { firstBy } from "thenby";

class AgentManager extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agents: [],
            queues: [],
            loadingAgents: true
        };

        this.loadAgents = this.loadAgents.bind(this);
    }

    async componentDidMount(){
        await this.loadAgents()
    }
    
    async loadAgents(){
        let agents = [];
        let queues = [];
        if(!this.props.noCompany){
            agents = await companyService.getCompanyAgents(this.props.auth.selectedCompany, this.props.auth.agent.token);
            queues = await getQueues(this.props.auth.selectedChatbot.token);
        }else{
            agents = await agentService.getAgents(this.props.auth.agent.token);
        }

        agents = agents.sort(firstBy("disabled").thenBy((a, b) => {
            if(a.agent_settings.status === "offline"){ return  1 }
            if(b.agent_settings.status === "offline"){ return  -1 }
        }).thenBy("first_name"));
        
        this.setState({ agents, queues, loadingAgents: false })
    }

    render(){
        return (
            <div className="agent_manager">
                <AgentList noCompany={this.props.noCompany} loadAgents={this.loadAgents} currentAgent={this.props.auth.agent.agent} agents={this.state.agents} queues={this.state.queues} loadingAgents={this.state.loadingAgents}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(AgentManager);