import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import { deleteError, deleteErrors, getErrors } from '../../services/errors';
import ErrorItem from './ErrorItem';
import LoadMore from '../Base/LoadMore';
import { HiOutlineTrash } from 'react-icons/hi2';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { isMobile } from '../../services/responsive'
import Select from 'react-select';

class ErrorPage extends React.Component {
    constructor(props){
        super(props);

        this.orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];
        this.state = {
            loadingErrors: true,
            errors: [],
            errorsLimit: 20,
            errorsOffset: 20,
            hasMore: false,
            clearingAll: false,
            filters: {
                startDate: undefined,
                endDate: undefined,
                orderBy: 'desc'
            },
        };

        this.setErrors = this.setErrors.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteAll = this.handleDeleteAll.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    async componentDidMount(){
        await this.setErrors();
    }

    async setErrors(){
        const errors = await getErrors(this.props.auth.selectedChatbot.token, this.state.errorsLimit + 1, this.state.filters);


        this.setState({ loadingErrors: false, hasMore: errors.length > this.state.errorsLimit ? true : false });

        if(errors.length > this.state.errorsLimit){
            errors.pop()
        }

        this.setState({ errors });
    }

    handleLoadMore(){
        this.setState(prevState => ({
            errorsLimit: prevState.errorsLimit + prevState.errorsOffset,
            loadingMore: true
        }), () => {
            this.setErrors().then(() => {
                this.setState({ loadingMore: false });
            })
        });
    }

    async handleDelete(errorId){
        await deleteError(this.props.auth.selectedChatbot.token, errorId);
        await this.setErrors();
    }
    
    async handleDeleteAll(){
        this.setState({ clearingAll: true })
        await deleteErrors(this.props.auth.selectedChatbot.token);
        await this.setErrors();
        this.setState({ clearingAll: false })
    }

    handleFilterChange(fieldName, value){
        this.setState(prevState => ({ loadingErrors: true, filters: { ...prevState.filters, [fieldName]: value } }), () => {
            this.setErrors();
        })
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                            <div className="page_title">Error Logs</div>
                            <button className="btn mb-medium" onClick={this.handleDeleteAll} disabled={this.state.clearingAll}><HiOutlineTrash/>{this.state.clearingAll ? 'Clearing all...' : 'Clear All'}</button>
                            <div className="filters">
                                <div className="filters">
                                    <div className="form-group">
                                        <label>Date Range</label>
                                        <DateRangePicker
                                            isOutsideRange={(day) => day.isAfter(moment())}
                                            noBorder={true}
                                            startDate={this.state.filters.startDate ? moment(this.state.filters.startDate) : undefined} // momentPropTypes.momentObj or null,
                                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                            endDate={this.state.filters.endDate ? moment(this.state.filters.endDate) : undefined} // momentPropTypes.momentObj or null,
                                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                            onDatesChange={({ startDate, endDate }) => { this.handleFilterChange('startDate', startDate ? startDate.format('YYYY-MM-DD') : undefined); this.handleFilterChange('endDate', endDate ? endDate.format('YYYY-MM-DD') + 'T23:59' : undefined); }} // PropTypes.func.isRequired,
                                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
                                            orientation={isMobile() ? 'vertical' : 'horizontal'}
                                            withFullScreenPortal={isMobile() ? true : false}
                                            initialVisibleMonth={() => moment().subtract(1, 'months')}
                                        />
                                    </div>
                                </div>
                                <div className="top_bar_filter">
                                    <label>Sort by</label>
                                    <Select classNamePrefix="custom_select"  onChange={(e) => { this.handleFilterChange('orderBy', e.value) }} className="filter form_select" value={this.orderByOptions.find(orderOption => orderOption.value === this.state.filters.orderBy)} options={this.orderByOptions}/>
                                </div>
                            </div>
                            {!this.state.loadingErrors ? (
                                <div>
                                    {this.state.errors.length > 0 ? (
                                        <div>
                                            {this.state.errors.map(error => <ErrorItem key={error._id} error={error} handleDelete={this.handleDelete}/>)}
                                            {this.state.hasMore && <div><LoadMore onClick={this.handleLoadMore} loadingMore={this.state.loadingMore}/></div>}
                                        </div>
                                        ) : (<div>No errors found.</div>)}
                                </div>
                                ) : <Preloader/>}
                        </div>
                    </div>
                </PageContainer>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ErrorPage);