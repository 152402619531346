import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import AdminAgents from './AdminAgents';
import AdminChatbots from './AdminChatbots';
import AdminCompanies from './AdminCompanies';

class AdminSettingsPage extends React.Component{
    render(){
        return (
            <div>
                <PageContainer>
                    <h2>Admin Settings</h2>
                    <AdminCompanies/>
                    <AdminChatbots/>
                    <AdminAgents/>
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AdminSettingsPage);