import Badge from "../Base/Badge";
import AgentProfileAvatar from "./AgentProfileAvatar";
import { HiOutlinePencil, HiTrash } from 'react-icons/hi2';
import StatusText from "../Base/StatusText";
import { toDateOnly, toTimestamp } from '../../services/dates';
import AgentName from './AgentName';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

const AgentListItem = (props) => {
    const queues = props.getAgentQueues(props.agent);
    return (
        <div className={`agent_list__item ${props.isSelectList && 'select_list_item'} ${props.isSelected && 'selected'}`} onClick={props.onSelect ? () => { props.onSelect(props.agent, props.isSelected) } : () => {}}>
            <AgentProfileAvatar agent={props.agent} size="60" showStatus={true}/>
            <div className="agent_list__item__details">
                <div className="agent_list_item__name"><AgentName agent={props.agent} currentAgent={props.currentAgent}/></div>
                {(props.agent.agent_settings.timed_agent && props.agent.agent_settings.timed_agent.enabled) && <div className="timed_agent_label">Time online: <span className={props.agent.sessions.find(session => moment(session.createdAt).isSame(moment(), 'year') && moment(session.createdAt).isSame(moment(), 'month')) && ((props.agent.sessions.find(session => moment(session.createdAt).isSame(moment(), 'year') && moment(session.createdAt).isSame(moment(), 'month')).seconds_connected / 3600) > props.agent.agent_settings.timed_agent.allowed_time) ? 'exceeded' : ''}>{props.agent.sessions.find(session => moment(session.createdAt).isSame(moment(), 'year') && moment(session.createdAt).isSame(moment(), 'month')) ? Math.round((props.agent.sessions.find(session => moment(session.createdAt).isSame(moment(), 'year') && moment(session.createdAt).isSame(moment(), 'month')).seconds_connected / 3600) * 10) / 10: 0}/{props.agent.agent_settings.timed_agent.allowed_time} hours</span></div>}
                {(props.currentAgent && props.currentAgent.role === 'admin' && (props.agent.expiry_date)) && (!(moment() > moment(props.agent.expiry_date).add(23.9, 'hours')) ? <div className="timed_agent_label">Expiry date: <span >{toDateOnly(props.agent.expiry_date)}</span></div> : <div className="timed_agent_label">Expired on: <span className="exceeded">{toDateOnly(props.agent.expiry_date)}</span></div>)}
                {props.agent.agent_settings.status === 'offline' ? <div className="status_display">{props.agent.last_seen ? <span>Last Seen: {toTimestamp(props.agent.last_seen)}</span> : <span>Not logged in yet</span>}</div> : <StatusText hideIcon={true} status={props.agent.agent_settings.status}/>}
                {queues.map(queue => <Badge key={queue._id} text={queue.name}/>)}
            </div>
            {!props.isSelectList && (
                <div className="agent_list__item__details__actions">
                    <Tooltip title="Edit agent" arrow><div className="agent_list__item__details__action" onClick={() => { props.handleToggleEditAgent(props.agent) }}><HiOutlinePencil/></div></Tooltip>
                    {props.agent._id !== props.currentAgent._id && <Tooltip title="Delete agent" arrow><div className="agent_list__item__details__action" onClick={() => { props.handleDeleteAgentConfirm(props.agent) }}><HiTrash/></div></Tooltip>}
                </div>
            )}
            
        </div>
    )
}

export default AgentListItem;