import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getQueues } from '../../services/queues'
import { companyService } from '../../services/company';
import { getChannelFilters } from '../../services/conversation'
import { getTags } from '../../services/tags'
import { titleCase, channelLabel } from '../../services/misc'
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { isMobile } from '../../services/responsive'
import Tooltip from '@material-ui/core/Tooltip';
import { HiMinus } from 'react-icons/hi2';

class ChatFilters extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            focusedInput: false,
            loadingQueues: true,
            queueOptions: undefined,
            loadingChannels: true,
            channelOptions: undefined,
            loadingTags: true,
            tagOptions: undefined,            
            loadingAgents: true,
            agentOptions: undefined,
            statusOptions: [
                { value: 'bot', label: 'Bot' },
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' }
            ]
        };
    }

    componentDidMount(){
        getQueues(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id).then(queues => {
            this.setState({ loadingQueues: false, queueOptions: queues.map(queue => ({ value: queue._id, label: queue.name })) });
        });
        
        getChannelFilters(this.props.auth.selectedChatbot.token).then(channels => {
            this.setState({ loadingChannels: false,channelOptions: channels.map(channel => ({ value: channel, label: titleCase(channelLabel(channel)) })) });
        })

        getTags(this.props.auth.selectedChatbot.token).then(tags => {
            this.setState({ loadingTags: false, tagOptions: tags.map(tag => ({ value: tag._id, label: tag.label })) });
        });

        companyService.getCompanyAgents(this.props.auth.selectedCompany, this.props.auth.agent.token).then(agents => {
            this.setState({ loadingAgents: false, agentOptions: agents.map(agent => ({ value: agent._id, label: agent.first_name + " " + agent.last_name })) });
        });
    }

    render(){
        return (
            <div className="chats_filter">
                <div className="label_title d-flex">Filter<Tooltip title="Minimize" arrow><div className='queue_list__action' onClick={this.props.handleFilterTrigger}><HiMinus/></div></Tooltip>
                </div>
                <div>
                    <label>Queue</label>
                    <Select classNamePrefix="custom_select"  className="form_select filter" isClearable={true} isLoading={this.state.loadingQueues} onChange={(e) => { this.props.handleSetFilter('queue', e ? e.value : undefined ) }} options={this.state.queueOptions} value={this.state.queueOptions != null ? this.state.queueOptions.find(option => option.value === this.props.filters.queue) : null}/>
                </div>
                <div>
                    <label>Channel</label>
                    <Select classNamePrefix="custom_select"  className="form_select filter" onChange={(e) => { this.props.handleSetFilter('channel', e ? e.value : undefined ) }} isLoading={this.state.loadingChannels} isClearable={true} options={this.state.channelOptions} value={this.state.channelOptions != null ? this.state.channelOptions.find(option => option.value === this.props.filters.channel) : null}/>
                </div>
                <div>
                    <label>Tags</label>
                    <Select classNamePrefix="custom_select"  className="form_select filter" onChange={(e) => { this.props.handleSetFilter('tags', e ? e.value : undefined ) }} isLoading={this.state.loadingTags} isClearable={true} options={this.state.tagOptions} value={this.state.tagOptions != null ? this.state.tagOptions.find(option => option.value === this.props.filters.tag) : null}/>
                </div>
                <div>
                    <label>Agents</label>
                    <Select classNamePrefix="custom_select"  className="form_select filter" onChange={(e) => { this.props.handleSetFilter('agents', e ? e.value : undefined ) }} isLoading={this.state.loadingAgents} isClearable={true} options={this.state.agentOptions} value={this.state.agentOptions != null ? this.state.agentOptions.find(option => option.value === this.props.filters.agents) : null}/>
                </div>
                <div>
                    <label>Status</label>
                    <Select classNamePrefix="custom_select"  className="form_select filter" onChange={(e) => { this.props.handleSetFilter('status', e ? e.value : undefined ) }} isClearable={true} options={this.state.statusOptions} value={this.state.statusOptions != null ? this.state.statusOptions.find(option => option.value === this.props.filters.status) : null}/>
                </div>
                <div>
                    <label>Date Range</label>
                    <div className="date_range_filter">
                        <DateRangePicker
                            isOutsideRange={(day) => day.isAfter(moment())}
                            noBorder={true}
                            startDate={this.props.filters.startDate ? moment(this.props.filters.startDate) : undefined} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={this.props.filters.endDate ? moment(this.props.filters.endDate) : undefined} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) => { this.props.handleSetFilter('startDate', startDate ? startDate.format('YYYY-MM-DD') : undefined); this.props.handleSetFilter('endDate', endDate ? endDate.format('YYYY-MM-DD') + 'T23:59' : undefined); }} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
                            orientation={isMobile() ? 'vertical' : 'horizontal'}
                            withFullScreenPortal={isMobile() ? true : false}
                            initialVisibleMonth={() => moment().subtract(1, 'months')}
                        />
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ChatFilters);