import React from 'react';
import { connect } from 'react-redux';
import { getInitials } from '../../services/misc';
import StatusText from '../Base/StatusText';
import Preloader from '../Preloader';
import AgentPopup from './AgentPopup';
import { newChatHandle } from '../../services/internalChats';
import moment from 'moment';

class AgentProfileAvatar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            showPopup: false
        }

        this.handleHover = this.handleHover.bind(this);
        this.handleDirectMessage = this.handleDirectMessage.bind(this);
    }

    handleHover(showPopup){
        this.setState({showPopup})
    }

    async handleDirectMessage(){
        const internalChat = await newChatHandle(this.props.auth.agent.agent._id, this.props.agent._id, this.props.auth.selectedChatbot.token);
        await this.props.dispatch({
            type: 'SET_SELECTED_CHAT',
            selectedChat: internalChat
        });
        await this.props.dispatch({
            type: 'SET_CHAT_WIDGET_EXPANDED',
            chatWidgetExpanded: true
        });
    }
    
    render(){
        const agent = this.props.agent;
        const agentFullName = `${agent.first_name} ${agent.last_name}`;
        const agentInitials = getInitials(agentFullName);
        const width = this.props.size + 'px' || undefined;
        const height = this.props.size + 'px' || undefined;
        const fontSize = this.props.size / 3 || undefined;
        const avatar = agent.avatar || undefined;
        const popupEnabled = this.props.popupEnabled || false;
        const disabled = agent.disabled || agent.deleted || (agent.expiry_date && moment() > moment(agent.expiry_date).add(23.9, 'hours'));
        
        return (
            <div className={`avatar-container ${this.props.showStatus ? 'status_shown' : ''} ${disabled ? 'disabled' : ''}`}>          
                <div className={`agent_profile_avatar ${avatar ? 'no_background' : ''}`} style={{ width, height }} onMouseEnter={() => { this.handleHover(true) }} onMouseLeave={() => { this.handleHover(false) }}>{this.props.changingAvatar && <Preloader fitToContent={true} color="white"/> }{avatar ? <img alt="Agent Avatar" src={avatar}/> : <div className="agent_initials" style={{ fontSize }}>{agentInitials}</div>}
                </div>
                {this.props.showStatus && <StatusText status={agent.agent_settings.status} hideText={true}/>}
                {popupEnabled && this.state.showPopup &&  <AgentPopup currentAgent={this.props.auth.agent.agent} handleDirectMessage={this.handleDirectMessage} agent={agent} onMouseEnter={() => { this.handleHover(true) }} onMouseLeave={() => { this.handleHover(false) }}/>} 
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AgentProfileAvatar);