import React from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import LoadMore from '../Base/LoadMore';
import ChatDateBadge from './ChatDateBadge';
import Message from './Message';
import { HiOutlineChevronDoubleUp } from 'react-icons/hi2';
import moment from 'moment';
import ChatEvent from './ChatEvent';
import VisibilitySensor from 'react-visibility-sensor';
import { toChatDate } from '../../services/dates';
import ChatNote from './ChatNote';

export default class ChatMessages extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            currentMessages: this.props.currentMessages
        }
        this.scrollableRef = React.createRef();
        this.loadMoreElement = React.createRef();
    }

    componentDidMount(){
        this.props.notificationRead(this.props.currentConversation.conversation_id, this.props.currentAgent._id);
    }

    componentDidUpdate(prevProps){
        if(prevProps.currentMessages !== this.props.currentMessages){
            const currentMessages = this.props.currentMessages.map(currentMessage => ({ ...currentMessage, new_message: true }));
            this.setState({ currentMessages })
            this.props.notificationRead(this.props.currentConversation.conversation_id, this.props.currentAgent._id);
        }
    }

    render(){
        const renderedDates = [];
        let messagesAndEvents = [];
        if(this.state.currentMessages && this.state.currentMessages.length > 0){
            messagesAndEvents = [...this.state.currentMessages, ...this.props.currentConversation.events, ...this.props.currentConversation.notes];
            messagesAndEvents.sort((a, b) => moment(a.createdAt) - moment(b.createdAt))
        }
        return (
            <ScrollableFeed className="chat_messages_scroll" onScroll={this.handleScroll} ref={this.scrollableRef} loadingMore={this.props.loadingMoreMessages}>
                <div className="chat_messages">
                    {(this.props.currentConversation) ? (
                        <div>
                            {this.state.currentMessages && this.state.currentMessages.length > 0 ? (
                                <div>
                                    {this.props.hasMoreMessages && <VisibilitySensor onChange={(isVisible) => { if(isVisible){ this.props.handleLoadMoreMessages(this.scrollableRef) } }}><LoadMore ref={this.loadMoreElement} icon={<HiOutlineChevronDoubleUp/>} onClick={() => { this.props.handleLoadMoreMessages(this.scrollableRef) }} loadingMore={this.props.loadingMoreMessages}/></VisibilitySensor>}
                                    {messagesAndEvents.map((messageOrEvent, index) => {
                                        let dateToRender = toChatDate(messageOrEvent.createdAt)
                                        if(!renderedDates.includes(dateToRender)){
                                            renderedDates.push(dateToRender);
                                        }else{
                                            dateToRender = undefined;
                                        }
                                        return messageOrEvent.message_data ? (
                                            <div key={messageOrEvent._id}>
                                                {dateToRender && <ChatDateBadge date={dateToRender}/>}
                                                {(messageOrEvent.message_data && <Message currentAgent={this.props.currentAgent} conversation={this.props.currentConversation} key={messageOrEvent._id} message={messageOrEvent} handleMediaClick={this.props.handleMediaClick}/>)}
                                            </div>
                                        ) : (moment(this.state.currentMessages[0].createdAt) < moment(messageOrEvent.createdAt) && (messageOrEvent.owner ? <ChatNote key={index} note={messageOrEvent}/> : <ChatEvent key={index} event={messageOrEvent}/>));
                                    })}
                                </div>
                            ) : <div className="no_message">This chat has no messages yet...</div>}
                        </div>
                        
                    ) : <p>Loading messages...</p>}
                </div>
            </ScrollableFeed>
        );
    }
} 