import { isVideo, isImage, isAudio } from '../../services/upload';
import { HiOutlineXCircle, HiPlay } from 'react-icons/hi2'
import {  HiOutlineDocumentText } from 'react-icons/hi2';
import React from "react";
import { connect } from 'react-redux';
import ChatMediaViewer from '../Chats/ChatMediaViewer';
import Preloader from '../Preloader';

class MessageMedia extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mediaItem: undefined,
            media: this.props.media,
            loadingMedia: true,
            dataUrl: undefined,
            failed: false
        };

        this.handleMediaClick = this.handleMediaClick.bind(this);
    }

    componentDidMount(){
        const thisMethod = this;
        function toDataURL(url, type, callback, error) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                var reader = new FileReader();
                if (this.status === 200) {
                    var blob = new Blob([xhr.response], {type});
                    const url = window.URL.createObjectURL(blob);
                    callback(url)
                }else{
                    error();
                }
                reader.readAsDataURL(xhr.response);
            };

            xhr.onerror = function(){
                callback(url)
            }
            
            xhr.open('GET', url);

            xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            
            if(thisMethod.props.auth.selectedChatbot.settings.infobip_enabled && thisMethod.props.auth.selectedChatbot.settings.infobip_api_key && url.includes('.infobip')){
                xhr.setRequestHeader("Authorization", "App " + thisMethod.props.auth.selectedChatbot.settings.infobip_api_key);
            }
            
            xhr.responseType = 'blob';
            xhr.send();
        }

        toDataURL(this.state.media.url, this.state.media.type, function(dataUrl) {
            thisMethod.setState(prevState => ({
                media: {
                    ...prevState.media,
                    url: dataUrl
                },
                loadingMedia: false,
                dataUrl
            }))
        }, () => {
            thisMethod.setState({
                failed: true,
                loadingMedia: false
            });
        });
    }

    handleMediaClick(media){
        this.setState({ mediaItem: media }, () => {
            if(this.state.mediaItem && (!isVideo(this.state.mediaItem.type) && !isImage(this.state.mediaItem.type))){
                window.open(media.url, "_blank")
            }
        });
    }
    
    render(){
        return (
            <div>
                {this.state.loadingMedia ? (
                    <div className="message_media_item">
                        <div className={`message_media_item__fit ${this.props.thumbnail ? 'thumbnail' : ''}`}><div className="message_media_item__preloader"><Preloader fitToContent={true} text="Media loading"/></div></div>
                    </div>
                ) : (!this.state.failed ? (
                    <div className="message_media_item" onClick={() => { this.handleMediaClick(this.state.media) }}>
                        {isImage(this.state.media.type) && <div className={`message_media_item__fit ${this.props.thumbnail ? 'thumbnail' : ''}`}><img src={this.state.media.url} alt="Message Media"/></div>}
                        {isVideo(this.state.media.type) && <div className={`message_media_item__fit ${this.props.thumbnail ? 'thumbnail' : ''}`}><video src={this.state.media.url} alt="Message Media"></video><div className="message_media_item__play"><HiPlay/></div></div>}
                        {isAudio(this.state.media.type) && <div className={`message_media_item__fit ${this.props.thumbnail ? 'thumbnail' : ''}`}><audio controls alt="Message Media"><source src={this.state.media.url} type={this.state.media.type}></source></audio></div>}
                        {(!isImage(this.state.media.type) && !isVideo(this.state.media.type) && !isAudio(this.state.media.type)) && <div className="message_media_item__other" title={this.state.media.name}><HiOutlineDocumentText/><span className="text-truncate">{this.state.media.name}</span></div>}
                    </div>
                ) : (
                    <div className={`message_media_item__failed ${this.props.thumbnail ? 'thumbnail' : ''}`}>
                        <HiOutlineXCircle/>
                        <div className="message_media_item__failed__details">
                            <div className="message_media_item__failed__details__title">Media expired</div>
                            <div className="message_media_item__failed__details__body">Media is missing or has expired.</div>
                        </div>
                    </div>
                ))}
                {this.state.mediaItem && (isVideo(this.state.mediaItem.type) || isImage(this.state.mediaItem.type)) && <ChatMediaViewer media={this.state.mediaItem} handleMediaClick={this.handleMediaClick}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(MessageMedia);