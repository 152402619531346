import React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

class DynamicFieldModal extends React.Component{
    constructor(props){
        super(props);
        this.userTypeOptions = [{ label: 'Agent', value: 'agent' }, { label: 'User', value: 'user' }];
        this.agentOptions = [{ label: 'First Name', value: 'first_name' }, { label: 'Last Name', value: 'last_name' }, { label: 'Email', value: 'email' }];
        this.userOptions = [{ label: 'Name', value: 'name' }, { label: 'Email', value: 'email' }, { label: 'Phone Number', value: 'phone number' }, { label: 'Custom', value: 'custom' }];
        this.state = {
            userType: undefined,
            fieldName: '',
            customFieldOpen: false
        };

        this.handleUserTypeSelect = this.handleUserTypeSelect.bind(this);
        this.handleFieldNameSelect = this.handleFieldNameSelect.bind(this);
        this.handleCustomFieldChange = this.handleCustomFieldChange.bind(this);
    }

    handleUserTypeSelect(e){
        this.setState({ userType: e.value })
    }

    handleFieldNameSelect(e){
        const value = e.value;
        if(value === 'custom'){
            return this.setState({ fieldName: '',customFieldOpen: true })
        }

        this.setState({ fieldName: value })
    }

    handleCustomFieldChange(e){
        this.setState({
            fieldName: e.target.value
        });
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal modal__preloader" onRequestClose={this.props.handleDyanmicMenuToggle}>
                <div className="modal__title">Dynamic Field</div>
                <div className="modal__content">Insert dynamically populated fields for either the user or the agent.</div>
                <label>User Type</label>
                <Select classNamePrefix="custom_select"  onChange={this.handleUserTypeSelect} options={this.userTypeOptions} className="form_select"/>
                {this.state.userType && (
                    <div>
                        <label>Field Name</label>
                        {this.state.userType === 'user' && <span className="info mb-medium">Please note that user fields will only be populated if the user has entered that information in the past.</span>}
                        {this.state.userType === 'agent' ? <Select classNamePrefix="custom_select"  options={this.agentOptions} onChange={this.handleFieldNameSelect} className="form_select"/> : <Select classNamePrefix="custom_select"  options={this.userOptions} onChange={this.handleFieldNameSelect} className="form_select"/>}
                        {this.state.customFieldOpen && <input type="text" className="mt-medium" onChange={this.handleCustomFieldChange} value={this.state.fieldName} placeholder="Enter custom field name"/>}
                    </div>
                )}
                <div className="modal__actions">
                    <button className="btn" onClick={this.props.handleDyanmicMenuToggle}>Cancel</button>
                    <button className="btn" disabled={!this.state.fieldName.trim().length > 0} onClick={() => { this.props.insertDynamicField(this.state.userType, this.state.fieldName) }}>Insert</button>
                </div>
            </Modal>
        );
    }
};

export default DynamicFieldModal;