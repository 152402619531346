import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import TextArea from 'react-textarea-autosize';
import MessageMedia from "../Chats/MessageMedia";
import ChatMediaViewer from '../Chats/ChatMediaViewer';
import ChatMediaPreview from '../Chats/ChatMediaPreview';
import { isVideo, isImage } from '../../services/upload';
import { HiLockClosed, HiOutlinePlusCircle, HiUserGroup, HiOutlineCodeBracket, HiOutlineXCircle } from 'react-icons/hi2';
import { uploadMedia } from '../../services/upload';
import Select from 'react-select';
import Preloader from '../Preloader';
import DynamicFieldModal from './DynamicFieldModal';
import EmojiPicker from '../Base/EmojiPicker';

class EditQuickReplyModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            quickReply: props.quickReply,
            message_data: {
                text: '',
                media: [],
                ...props.quickReply.message_data
            },
            private: props.quickReply.private ? true : false,
            savingQuickReply: false,
            mediaItem: undefined,
            mediaFile: undefined,
            mediaPreview: undefined,
            loadingMediaPreview: false,
            hasMedia: false,
            dynamicFieldModalOpen: false
        };

        this.handleMediaClick = this.handleMediaClick.bind(this);        
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleMediaTrigger = this.handleMediaTrigger.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
        this.handleSaveQuickReply = this.handleSaveQuickReply.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handlePrivateChange = this.handlePrivateChange.bind(this);
        this.handleDyanmicMenuToggle = this.handleDyanmicMenuToggle.bind(this);
        this.insertDynamicField = this.insertDynamicField.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
        this.handleEmojiPick = this.handleEmojiPick.bind(this);

    }

    handleMediaClick(media){
        this.setState({ mediaItem: media }, () => {
            if(this.state.mediaItem && (!isVideo(this.state.mediaItem.type) && !isImage(this.state.mediaItem.type))){
                window.open(media.url, "_blank")
            }
        });
    }

    handleMediaTrigger(input){
        input.click();
    }

    handleMediaChange(e){
        this.setState({ hasMedia: true, loadingMediaPreview: true });
        if(e.target.files && e.target.files[0]){
            const file = e.target.files[0];
            this.setState({ mediaFile: file });
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ mediaPreview: e.target.result, loadingMediaPreview: false });
            }

            reader.readAsDataURL(file);
        }
    } 

    handlePrivateChange(e){
        this.setState({ private: e.value === "private" ? true : false })
    }

    handleTextChange(e){
        this.setState(prevState => ({ message_data: { ...prevState.message_data, text: e.target.value } }));
    }

    handleEmojiPick(event, { emoji }){
        this.setState(prevState => ({ message_data: { ...prevState.message_data, text: prevState.message_data.text + emoji } }));
    }

    async handleSaveQuickReply(){
        this.setState({ savingQuickReply: true })
        let media = null;
        if(this.state.mediaFile && !this.state.mediaFile.url){
            const uploadResponse = await uploadMedia(this.props.auth.selectedChatbot.token, this.state.mediaFile);
            if(uploadResponse.url){
                media = {
                    url: uploadResponse.url,
                    name: this.state.mediaFile.name,
                    type: this.state.mediaFile.type
                };
            }
        }

        if(media){
            this.setState(prevState => ({ message_data: { ...prevState.message_data, media: [media] } }))
        }

        await this.props.saveQuickReply(this.state.quickReply._id, { private: this.state.private, message_data: this.state.message_data });
        this.props.toggleEdit()
    }

    handleDyanmicMenuToggle(){
        this.setState(prevState => ({ dynamicFieldModalOpen: !prevState.dynamicFieldModalOpen }))
    }

    insertDynamicField(userType, fieldName){
        const dynamicFieldString = `{{${userType}[${fieldName.toLowerCase().trim()}]}}`;
        this.setState(prevState => ({
            dynamicFieldModalOpen: false,
            message_data: {
                ...prevState.message_data,
                text: prevState.message_data.text + dynamicFieldString
            }
        }))
    }

    handleRemoveMedia(){
        this.setState(prevState => ({
            message_data: {
                ...prevState.message_data,
                media: []
            }
        }))
    }

    componentDidUpdate(prevProps){
        if(prevProps.quickReply !== this.props.quickReply){
            this.setState({ quickReply: this.props.quickReply });
        }
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="action_modal modal__preloader" onRequestClose={this.props.toggleEdit}>
                {!this.state.savingQuickReply ? (
                    <div>
                        <div className="modal__title px-medium">{!this.state.quickReply._id ? 'New' : 'Edit'} Quick Reply</div>
                        <div className="container">
                            <div className="col-8 px-medium mb-medium">
                                {['admin', 'manager'].includes(this.props.auth.agent.agent.role) && <Select classNamePrefix="custom_select"  className="mb-medium form_select" value={this.state.private ? {label: <span className="flex-middle"><HiLockClosed/><div className="select-label">Private:</div> Can only be used by you</span>, value: 'private'} : {label: <span className="flex-middle"><HiUserGroup/><div className="select-label">Public:</div> All agents can use this</span>, value: 'public'}} onChange={this.handlePrivateChange} options={[{label: <span className="flex-middle"><HiLockClosed/><div className="select-label">Private:</div> Can only be used by you</span>, value: 'private'}, {label: <span className="flex-middle"><HiUserGroup/><div className="select-label">Public:</div> All agents can use this</span>, value: 'public'}]}/>}
                                <div className="modal__subtitle">Content</div>
                                <button className="btn mb-medium" onClick={this.handleDyanmicMenuToggle}><HiOutlineCodeBracket/>Insert Dynamic Field</button>
                                <div className="flex-middle">
                                    <TextArea placeholder="Enter your quick reply" minRows="3" onChange={this.handleTextChange} value={this.state.message_data.text}/>
                                    <EmojiPicker handleEmojiPick={this.handleEmojiPick}/>
                                </div>
                            </div>
                            <div className="col-3 px-medium mb-medium">
                                <div className="modal__subtitle">File Attachment</div>
                                {(!this.state.hasMedia && !this.state.mediaPreview && this.state.message_data.media.length > 0) && <button className="btn mb-medium" onClick={this.handleRemoveMedia}><HiOutlineXCircle/>Remove Media</button>}
                                {(!this.state.hasMedia && !this.state.mediaPreview) && this.state.message_data.media.length > 0 ? <MessageMedia thumbnail={true} media={this.state.message_data.media[0]} handleMediaClick={this.handleMediaClick}/> : <button className="btn" onClick={() => { this.handleMediaTrigger(this.mediaUploadElement) }}><HiOutlinePlusCircle/>{this.state.mediaFile ? 'Change' : 'Add'} Media</button>}
                                {(this.state.hasMedia && this.state.mediaPreview) && <ChatMediaPreview handleRemoveMedia={this.handleRemoveMedia} loadingMediaPreview={this.state.loadingMediaPreview} mediaPreview={this.state.mediaPreview} mediaFile={this.state.mediaFile}/>}
                                <input ref={input => this.mediaUploadElement = input} type="file" style={{display: 'none'}} onChange={this.handleMediaChange}/>
                            </div>
                        </div>
                        <div className="modal__actions">
                            <button className="btn" onClick={this.props.toggleEdit}>Cancel</button>
                            <button className="btn" onClick={this.handleSaveQuickReply}>Save</button>
                        </div>
                        {this.state.mediaItem && (isVideo(this.state.mediaItem.type) || isImage(this.state.mediaItem.type)) && <ChatMediaViewer media={this.state.mediaItem} handleMediaClick={this.handleMediaClick}/>}
                        <DynamicFieldModal isOpen={this.state.dynamicFieldModalOpen} insertDynamicField={this.insertDynamicField} handleDyanmicMenuToggle={this.handleDyanmicMenuToggle}/>
                    </div>
                ) : <Preloader text="Saving quick reply" padding="100px 0"/>}
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(EditQuickReplyModal);