import EmojiPicker from '../Base/EmojiPicker';
import TextArea from 'react-textarea-autosize';
import { HiOutlinePaperClip, HiPaperAirplane } from 'react-icons/hi2';
import React from 'react';
import ChatMediaPreview from '../Chats/ChatMediaPreview';

class InternalChatRoomInputArea extends React.Component {
    render(){
        return (
            <div>
                {(this.props.hasMedia && this.props.mediaPreview) && <ChatMediaPreview handleRemoveMedia={this.props.handleRemoveMedia} loadingMediaPreview={this.props.loadingMediaPreview} mediaPreview={this.props.mediaPreview} mediaFile={this.props.mediaFile}/>}
                {!(!this.props.isGroupChat && (this.props.chattingTo[0].disabled || this.props.chattingTo[0].deleted)) ? (
                    <form className="internal_chat_room__input_area" onSubmit={this.props.handleMessageSubmit}>
                        <div className="chat_message_bar__message_bar__media" onClick={() => { this.props.handleMediaTrigger(this.mediaUploadElement) }}><HiOutlinePaperClip/></div>
                        <TextArea onKeyPress={this.props.handleTextEnter} value={this.props.messageValue} autoFocus={true} onChange={this.props.handleMessageType} type="text" placeholder="Enter your message"/>
                        <EmojiPicker handleEmojiPick={this.props.handleEmojiPick}/>
                        <button className='send_button' disabled={this.props.messageValue.trim().length === 0 && !this.props.hasMedia}><HiPaperAirplane/></button>
                        <input ref={input => this.mediaUploadElement = input} type="file" style={{display: 'none'}} onChange={this.props.handleMediaChange}/>
                    </form>
                ) : (
                    <div className="internal_chat_room__input_area disabled">{this.props.chattingTo[0].deleted ? 'This account was removed' : 'This account is disabled'}</div>
                )}</div>
    
        );
    }
};

export default InternalChatRoomInputArea;