import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { goToConversation } from '../../actions/chats';
import { getConversation } from '../../services/conversation';
import ChatChannelIcon from '../Chats/ChatChannelIcon';

class ConversationCard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            conversation: undefined
        }

        this.handleViewChatClick = this.handleViewChatClick.bind(this);
    }

    async componentDidMount(){
        const conversation = await getConversation(this.props.auth.selectedChatbot.token, this.props.conversationId);
        this.setState({ conversation })
    }

    async handleViewChatClick(){
        const conversationId = this.state.conversation.conversation_id;
        this.setState({ conversation: undefined })
        await goToConversation(this.props, conversationId);
        await this.props.dispatch({
            type: 'SET_CHAT_WIDGET_EXPANDED',
            chatWidgetExpanded: false
        })
    }

    render(){
        return (
            <div className="conversation_card">
                {this.state.conversation ? (
                    <div className="conversation_card__inner">
                        <div className="conversation_title">Shared Conversation</div>
                        <div className="conversation_info">
                            <ChatChannelIcon channel={this.state.conversation.channel}/>
                            <div className="conversation_details">
                                <div className="conversation_details_name text-truncate">{this.state.conversation.user && this.state.conversation.user.user_info && this.state.conversation.user.user_info.name ? this.state.conversation.user.user_info.name : this.state.conversation.user_id}</div>
                                <div className="btn" onClick={this.handleViewChatClick}>View Chat</div>
                            </div>
                        </div>
                    </div>
                ) : <Preloader padding="29px 0"/>}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ConversationCard);