import React from 'react';
import { connect } from 'react-redux';
import { getQueues, createQueue, updateQueue, deleteQueue } from '../../services/queues';
import Preloader from '../Preloader';
import QueueListItem from './QueueListItem';
import { HiPlusCircle } from 'react-icons/hi2';
import EditQueueModal from './EditQueueModal';
import LoadingModal from '../Base/LoadingModal';
import ConfirmModal from '../Base/ConfirmModal';

class QueueManager extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            queues: [],
            loadingQueues: true,
            editQueueFormOpen: false,
            selectedQueue: undefined,
            savingQueue: false,
            queueToDelete: undefined,
            deletingQueue: false
        }

        this.handleQueueClick = this.handleQueueClick.bind(this);
        this.handleQueueSave = this.handleQueueSave.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.handleDeleteQueue = this.handleDeleteQueue.bind(this);
        this.loadQueues = this.loadQueues.bind(this);
    }

    async componentDidMount(){
        await this.loadQueues();
    }

    async loadQueues(){
        const queues = await getQueues(this.props.auth.selectedChatbot.token);
        this.setState({ loadingQueues: false, queues });
        if(this.state.selectedQueue){
            const queue = this.state.queues.find(queue => this.state.selectedQueue._id === queue._id);
            if(queue){
                this.setState({ selectedQueue: queue });
            }
        }
    }

    handleQueueClick(queue){
        this.setState({ editQueueFormOpen: queue ? true : false, selectedQueue: queue });
    }

    async handleQueueSave(queue){
        this.setState({ savingQueue: true, selectedQueue: undefined, editQueueFormOpen: false });
        queue.agents = queue.agents.map(queueListItem => queueListItem._id)
        if(queue._id){
            await updateQueue(this.props.auth.selectedChatbot.token, queue._id, queue);
            await this.loadQueues();
            return this.setState({ savingQueue: false });
        }

        await createQueue(this.props.auth.selectedChatbot.token, queue);
        await this.loadQueues();
        return this.setState({ savingQueue: false });
    }

    handleDeleteConfirm(queueToDelete){
        this.setState({ queueToDelete  })
    }

    async handleDeleteQueue(){
        const queueToDelete = this.state.queueToDelete;
        this.setState({ deletingQueue: true, queueToDelete: undefined });
        await deleteQueue(this.props.auth.selectedChatbot.token, queueToDelete._id);
        await this.loadQueues();
        this.setState({ deletingQueue: false });
    }

    render(){
        return (
            <div className="queue_manager">
                <button className="btn" onClick={() => { this.handleQueueClick({ name: '', agents: [] }) }}><HiPlusCircle/>Add Queue</button>
                {!this.state.loadingQueues ? this.state.queues && this.state.queues.length > 0 ? (
                    this.state.queues.map(queue => <QueueListItem key={queue._id} handleDeleteConfirm={this.handleDeleteConfirm} handleQueueClick={this.handleQueueClick} queue={queue}/>)
                ) : <div className="mt-medium">No queues found</div> : <Preloader/>}
                {this.state.selectedQueue && <EditQueueModal loadQueues={this.loadQueues} onConfirm={this.handleQueueSave} onRequestClose={() => { this.handleQueueClick() }} queues={this.state.queues} isOpen={this.state.editQueueFormOpen} queue={this.state.selectedQueue}/>}
                {this.state.savingQueue && <LoadingModal isOpen={this.state.savingQueue} text="Saving queue"/>}
                {this.state.queueToDelete && <ConfirmModal isOpen={this.state.queueToDelete ? true : false} title="Delete Queue" onConfirm={this.handleDeleteQueue} onRequestClose={() => { this.handleDeleteConfirm() }} description={<span>You are about to delete this queue. This will transfer all conversations currently in this queue to "All Agents"<br/><br/>Would you like to proceed?</span>} confirmText="Delete"/>}                
                {this.state.deletingQueue && <LoadingModal isOpen={this.state.deletingQueue} text="Deleting queue"/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(QueueManager);