import React from 'react';
import { connect } from 'react-redux';
import AgentProfileAvatar from './Agents/AgentProfileAvatar'
import Status from './Base/Status';
import { HiOutlineCog, HiOutlineArrowRightOnRectangle } from 'react-icons/hi2';
import { FaUserEdit } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { agentActions } from '../actions/agents';
import onClickOutside from "react-onclickoutside";

class MiniAgentProfile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agent: this.props.agent,
            profileShown: false
        };

        this.handleProfileTrigger = this.handleProfileTrigger.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.agent !== this.props.agent){
            this.setState({
                agent: this.props.agent
            });
        }
    }

    handleProfileTrigger(){
        this.setState(prevState => ({
            profileShown: !prevState.profileShown
        }))
    }

    handleLogout(){
        this.props.dispatch(agentActions.logout());
    }

    handleClickOutside = evt => {
        if(this.state.profileShown){ this.handleProfileTrigger() }
    };

    render(){
        return (
            <div className="mini_agent_profie">
                <div className="mini_agent_profie_trigger" onClick={this.handleProfileTrigger}>
                    <AgentProfileAvatar agent={this.state.agent} showStatus={true}/>
                </div>
                {this.state.profileShown && (
                    <div className="mini_agent_profile__profile">
                        <div className="mini_agent_profie__title">Profile</div>
                        <div className="mini_agent_profile_details">
                            <AgentProfileAvatar size="60" agent={this.state.agent}/>
                            <div className="mini_agent_profile_details__container">
                                <div className="mini_agent_profile_details__name text-truncate">{this.state.agent.first_name} {this.state.agent.last_name}</div>
                                <Status agent={this.state.agent}/> 
                            </div>
                        </div>
                        <div className="mini_agent_profile__actions">
                            <NavLink to="/profile" className="mini_agent_profile__action"><FaUserEdit/> Edit Profile</NavLink>
                            {['admin', 'manager'].includes(this.props.auth.agent.agent.role) && <NavLink to="/settings" className="mini_agent_profile__action"><HiOutlineCog/> Settings</NavLink>}
                            <div onClick={this.handleLogout} className="mini_agent_profile__action"><HiOutlineArrowRightOnRectangle/> Logout</div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}


export default connect(mapStateToProps)(onClickOutside(MiniAgentProfile));