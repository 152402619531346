import React from 'react';
import { connect } from 'react-redux';
import { closeChat, joinChat, addAgentToConversation, removeAgentFromConversation, requestUserEngagement } from '../../services/humanHandoff';
import { acceptHumanHandoff } from '../../actions/humanHandoff';
import { HiOutlineXMark, HiVideoCamera, HiOutlineUserPlus, HiOutlineInformationCircle, HiCheck } from 'react-icons/hi2';
import { RiUserVoiceLine } from 'react-icons/ri';
import ChatActionButton from './ChatActionButton';
import MeetingSetup from './MeetingSetup';
import moment from 'moment';
import onClickOutside from "react-onclickoutside";
import { createEvent } from '../../services/conversation';
import ConfirmModal from '../Base/ConfirmModal';

class ChatActions extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            handoffPending: false,
            leavingChat: false,
            joiningChat: false,
            meetingSetupOpen: false,
            acceptingHandoff: false,
            confirmRequestEngagement: false
        };

        this.handleTransferToggle = this.handleTransferToggle.bind(this);
        this.handleCloseChat = this.handleCloseChat.bind(this);
        this.conversationHandoffPending = this.conversationHandoffPending.bind(this);
        this.handleJoinChat = this.handleJoinChat.bind(this);
        this.handleLeaveChat = this.handleLeaveChat.bind(this);
        this.handleSetupMeetingToggle = this.handleSetupMeetingToggle.bind(this);
        this.handleLeaveChatActionsTrigger = this.handleLeaveChatActionsTrigger.bind(this);
        this.handleAcceptHandoff = this.handleAcceptHandoff.bind(this);
        this.handleRequestEngagmentClick = this.handleRequestEngagmentClick.bind(this);
        this.handleRequestEngagement = this.handleRequestEngagement.bind(this);
    }

    componentDidMount(){
        this.setState({ handoffPending: this.conversationHandoffPending() });
    }

    componentDidUpdate(prevProps){
        if(prevProps.notifications.notifications !== this.props.notifications.notifications){
            this.setState({ handoffPending: this.conversationHandoffPending() });
        }
    }

    conversationHandoffPending(){
        const humanHandoff = this.props.notifications.notifications.find(notification => notification.props.humanHandoff && notification.props.humanHandoff.conversation_id === this.props.currentConversation.conversation_id);
        return humanHandoff || false;
    }

    handleTransferToggle(){
        this.setState(prevState => ({
            transferShown: !prevState.transferShown
        }));
    }

    handleClickOutside = evt => {
        this.handleLeaveChatActionsTrigger(false)
    };

    async handleCloseChat(){
        this.setState({ closingChat: true })
        await closeChat(this.props.auth.selectedChatbot.token, this.props.currentConversation.conversation_id, this.props.currentConversation.channel);
        await this.props.setCurrentConversation(this.props.auth.selectedChatbot.token, this.props.currentConversation.conversation_id);
        this.setState({ closingChat: false, leaveChatOptionsShown: false })
    }

    async handleJoinChat(){
        this.setState({ joiningChat: true });
        const currentChat = this.props.currentConversation;
        await createEvent(this.props.auth.selectedChatbot.token, currentChat.conversation_id, 'Agent Join', `${this.props.auth.agent.agent.first_name} ${this.props.auth.agent.agent.last_name} joined the chat.`)
        if(!(currentChat.agents_connected && currentChat.agents_connected.length > 0)){
            await joinChat(this.props.auth.selectedChatbot.token, currentChat.conversation_id, this.props.auth.agent.agent, currentChat.channel);
        }

        await addAgentToConversation(this.props.auth.selectedChatbot.token, currentChat.conversation_id, this.props.auth.agent.agent._id);
        await this.props.setCurrentConversation(this.props.auth.selectedChatbot.token, currentChat.conversation_id);
        this.setState({ joiningChat: false, leaveChatOptionsShown: false });
    }

    async handleRequestEngagmentClick(){
        this.setState(prevState => ({ confirmRequestEngagement: !prevState.confirmRequestEngagement }));
    }

    async handleLeaveChat(){
        this.setState({ leavingChat: true });
        const currentChat = this.props.currentConversation;
        await createEvent(this.props.auth.selectedChatbot.token, currentChat.conversation_id, 'Agent Leave', `${this.props.auth.agent.agent.first_name} ${this.props.auth.agent.agent.last_name} left the chat.`)
        await removeAgentFromConversation(this.props.auth.selectedChatbot.token, currentChat.conversation_id, this.props.auth.agent.agent._id);
        await this.props.setCurrentConversation(this.props.auth.selectedChatbot.token, currentChat.conversation_id);
        this.setState({ leavingChat: false, leaveChatOptionsShown: false })
    }

    async handleRequestEngagement(){
        this.setState({ requestingEngagement: true, confirmRequestEngagement: false });
        const currentChat = this.props.currentConversation;       
         await createEvent(this.props.auth.selectedChatbot.token, currentChat.conversation_id, 'User Engagement Requested', `${this.props.auth.agent.agent.first_name} ${this.props.auth.agent.agent.last_name} requested engagement from the user.`)

        await requestUserEngagement(this.props.auth.selectedChatbot.token, currentChat.conversation_id, this.props.auth.agent.agent, currentChat.channel);

        await this.props.setCurrentConversation(this.props.auth.selectedChatbot.token, currentChat.conversation_id);
        await this.props.setCurrentMessages(this.props.auth.selectedChatbot.token, currentChat.conversation_id);
        this.setState({ requestingEngagement: false });        

    }

    handleSetupMeetingToggle(){
        this.setState(prevState => ({ meetingSetupOpen: !prevState.meetingSetupOpen }));
    }
    
    handleLeaveChatActionsTrigger(open){
        this.setState({ leaveChatOptionsShown: open });
    }

    async handleAcceptHandoff(){
        this.setState({ acceptingHandoff: true });
        await acceptHumanHandoff(this.props, this.state.handoffPending.props.humanHandoff);
    }

    render(){
        const userConnectedToChat = this.props.currentConversation.agents_connected && this.props.currentConversation.agents_connected.find(agent => agent._id.toString() === this.props.auth.agent.agent._id.toString())
        const agentChats = this.props.currentMessages.filter(message => message.sender === 'user');
        const inSession = (agentChats.length > 0 && moment(agentChats[agentChats.length - 1].createdAt) > moment().subtract(this.props.auth.selectedChatbot.settings.chat_session_time, 'minutes')) ? true : false;
        return (
            <div className="chat_actions">
                {userConnectedToChat && this.props.auth.selectedChatbot.settings.enable_video_meetings && <ChatActionButton icon={<HiVideoCamera/>} onClick={this.handleSetupMeetingToggle} label={this.props.currentConversation.latestMeeting ? "View Meeting" : "Meet Now"}/>}
                {(userConnectedToChat && this.props.currentConversation.agents_connected.length > 1) && <ChatActionButton icon={<HiOutlineXMark/>} disabled={this.state.leavingChat} onClick={() => { this.handleLeaveChatActionsTrigger(!this.state.leaveChatOptionsShown) }} label={this.state.leaveChatOptionsShown ? 'Cancel' : 'Leave Chat'}/>}
                {(userConnectedToChat && this.props.currentConversation.agents_connected.length === 1) && <ChatActionButton isHovered={true} icon={<HiOutlineXMark/>} disabled={this.state.closingChat} onClick={this.handleCloseChat}  label={!this.state.closingChat ? 'End Chat' : 'Ending chat...' }/>}
                {userConnectedToChat && this.state.leaveChatOptionsShown && (
                    <div className="chat_actions_dropdown">
                        <button disabled={this.state.closingChat || this.state.leavingChat} onClick={this.handleLeaveChat} className="chat_actions_dropdown__item">{!this.state.leavingChat ? 'Leave Chat' : 'Leaving chat...' }</button>
                        <button onClick={this.handleCloseChat} disabled={this.state.closingChat || this.state.leavingChat} className="chat_actions_dropdown__item">{!this.state.closingChat ? 'End Chat' : 'Ending chat...'}</button>
                    </div>
                )}
                {(this.props.auth.selectedChatbot.settings.human_handoff_enabled && !inSession && !this.state.handoffPending && !userConnectedToChat && this.props.auth.selectedChatbot.settings.allow_chat_join && this.props.auth.selectedChatbot.settings.enable_request_engagement) && <ChatActionButton icon={<RiUserVoiceLine/>} disabled={this.state.requestingEngagement} onClick={this.handleRequestEngagmentClick} label={!this.state.requestingEngagement ? 'Request User Engagement' : 'Requesting...'}/>}
                {(this.props.auth.selectedChatbot.settings.human_handoff_enabled && inSession && !this.state.handoffPending && !userConnectedToChat && this.props.auth.selectedChatbot.settings.allow_chat_join) ? <ChatActionButton icon={<HiOutlineUserPlus/>} disabled={this.state.joiningChat} onClick={this.handleJoinChat} label={!this.state.joiningChat ? 'Join' : 'Joining...'}/> : (this.state.handoffPending && !userConnectedToChat && <ChatActionButton icon={<HiCheck/>} disabled={this.state.acceptingHandoff} onClick={this.handleAcceptHandoff} label={!this.state.acceptingHandoff ? 'Accept handoff' : 'Accepting...'}/>)}
                {this.state.meetingSetupOpen && (
                    <MeetingSetup 
                        meeting={this.props.currentConversation.latestMeeting} 
                        conversation={this.props.currentConversation} 
                        setCurrentConversation={this.props.setCurrentConversation}
                        toggleMeetingSetup={this.handleSetupMeetingToggle}  
                    />
                )}
                <ChatActionButton className="mobile_info" isHovered={true} icon={<HiOutlineInformationCircle/>} onClick={this.props.handleMobileSidebarTrigger} label="Info"/>
                <ConfirmModal confirmText="Request User Engagement" title="Request User Engagement" description="This will trigger a message to the user to request they start engagement with the conversation." isOpen={this.state.confirmRequestEngagement} onConfirm={this.handleRequestEngagement} onRequestClose={this.handleRequestEngagmentClick}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(onClickOutside(ChatActions));