import React from "react";
import Quill from 'quill'
import { connect } from 'react-redux';
import EmojiPicker from '../Base/EmojiPicker';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PlainClipboard from "./PlainClipboard";
import { HiOutlinePlus } from "react-icons/hi";

Quill.register('modules/clipboard', PlainClipboard, true)

class WhatsAppTemplateBodyEditor extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cursorIndex: 0
        };

        this.handleCursorFocus = this.handleCursorFocus.bind(this);
        this.handleEmojiPick = this.handleEmojiPick.bind(this);
        this.handleInsertVariale = this.handleInsertVariale.bind(this);
    }

    handleCursorFocus(e){
        if(e){
            this.setState({
                cursorIndex: e.index
            })
        }
    }

    handleEmojiPick(emoji){
        const quill = this.reactQuillRef.getEditor();
        quill.insertText(this.state.cursorIndex, emoji.emoji)
    }

    handleInsertVariale(){
        const quill = this.reactQuillRef.getEditor();
        const currentVariables = quill.getText().match(/[^{{]+(?=}\})/g)
        quill.insertText(this.state.cursorIndex, '{{'+((currentVariables ? currentVariables.length : 0) + 1)+'}}')
    }

    modules = {
        toolbar: [
        ['bold', 'italic', 'strike']],
        clipboard: {
            matchVisual: false
        }
    }

    render(){
        return (
            <div className="path_item_editor_text_editor">
                <ReactQuill 
                    format="json" 
                    ref={(el) => { this.reactQuillRef = el }} 
                    theme="snow" value={this.props.text} 
                    onChangeSelection={(e) => { this.handleCursorFocus(e) }} 
                    onChange={this.props.handleTextChange} 
                    modules={this.modules}
                />

                <div className="path_item_editor_text_editor__actions">
                    <div className="btn" onClick={this.handleInsertVariale}><HiOutlinePlus/>Add Variable</div>
                    <div className="path_item_editor_text_editor__actions">
                        {this.props.maxLengthText}
                        <EmojiPicker handleEmojiPick={(e, emoji) => { this.handleEmojiPick(emoji) }}/>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(WhatsAppTemplateBodyEditor);