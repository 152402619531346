import React from 'react'
import { connect } from 'react-redux';
import AdminMenu from './AdminMenu';

class AdminDashboard extends React.Component{
    render(){
        return (
            <div className="admin_dashboard">
                <AdminMenu/>
                <div className="admin_settings_container">{this.props.component}</div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AdminDashboard);