import React from 'react';
import { connect } from 'react-redux';
import { HiMagnifyingGlass, HiOutlineXMark } from 'react-icons/hi2';

class ChatSearch extends React.Component{
    render(){
        return this.props.auth.selectedChatbot.settings.enable_search ? (
            <div className="internal_chat_top_bar">
                <form className="chat_search internal_chat_search" onSubmit={this.props.handleSearchSubmit}>
                    <input value={this.props.searchTerm} type="text" autoFocus={true} placeholder="Enter your search query" onChange={this.props.handleSearchChange}/>
                    {this.props.isSearched && <div className="search_action_button" onClick={this.props.handleClearSearchClick}><HiOutlineXMark/></div>}
                    <button className="search_action_button"><HiMagnifyingGlass/></button>
                </form>
            </div>
        ) : <div className="internal_chat_top_bar">
                <center><label>Search is currently disabled</label></center>
            </div>;
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ChatSearch);