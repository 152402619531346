import { handleResponse, serialize } from './misc';

const getHelpRequests = (authToken, limit, filters) => {
    return new Promise(function(resolve, reject){
        try{
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
            };
        
            let queryString = '';
            queryString = limit ? '?limit=' + limit : '';
        
            queryString += filters ? '&' + serialize(filters) : '';
            
            fetch('/api/help-requests' + queryString, requestOptions).then(handleResponse).then((helpRequests) => {
                resolve(helpRequests);
            });
        }catch(e){
            reject(e);
        }
    });
}

const getHelpRequestsCount = (authToken, agentId) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/help-requests/count?agent=' + agentId, requestOptions).then(handleResponse).then(({count}) => {
        return count;
    });
}

const getHelpRequest = (authToken, id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };
    
    return fetch('/api/help-requests/' + id, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
}

const searchHelpRequests = (authToken, term, limit, filters) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    let queryString = '';
    queryString = limit ? '?limit=' + limit : '';

    queryString += filters ? '&' + serialize(filters) : '';

    return fetch('/api/help-requests/search/' + term + queryString, requestOptions).then(handleResponse).then((helpRequests) => {
        return helpRequests;
    });
}

const setHelpRequestCount = async (connectedProps) => {
    const helpRequestCount = await getHelpRequestsCount(connectedProps.auth.selectedChatbot.token, connectedProps.auth.agent.agent._id);
    connectedProps.dispatch({
        type: 'HELP_REQUESTS_SET_COUNT',
        count: helpRequestCount
    });
}

const setHelpRequests = async (connectedProps) => {
    let helpRequests = [];
    if(!(connectedProps.helpRequests.searchTerm && connectedProps.helpRequests.searchTerm.length > 0)){
        helpRequests = await getHelpRequests(connectedProps.auth.selectedChatbot.token, connectedProps.helpRequests.limit + 1, { ...connectedProps.helpRequests.filters, agent: connectedProps.auth.agent.agent._id });
    }else{
        helpRequests = await searchHelpRequests(connectedProps.auth.selectedChatbot.token, connectedProps.helpRequests.searchTerm, connectedProps.helpRequests.limit + 1, { ...connectedProps.helpRequests.filters, agent: connectedProps.auth.agent.agent._id });
    }

    const hasMore = helpRequests.length > connectedProps.helpRequests.limit;
    if(hasMore){
        helpRequests.pop();
    }

    const helpRequestCount = await getHelpRequestsCount(connectedProps.auth.selectedChatbot.token, connectedProps.auth.agent.agent._id);

    connectedProps.dispatch({
        type: 'HELP_REQUESTS_SET',
        helpRequests,
        loadingHelpRequests: false,
        isSearched: (connectedProps.helpRequests.searchTerm && connectedProps.helpRequests.searchTerm.length > 0),
        hasMore,
        count: helpRequestCount
    });
}

const resolveHelpRequest = (authToken, helpRequestId, agentId, comment = null) => {
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    if(comment){
        requestOptions = {
            ...requestOptions,
            body: JSON.stringify({ comment })
        };
    }

    return fetch('/api/help-requests/' + helpRequestId + '/resolve/' + agentId, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
};

const transferHelpRequest = (authToken, helpRequestId, queueId) => {
    let requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/help-requests/' + helpRequestId + '/transfer/' + queueId, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
};

const deleteHelpRequest = (authToken, helpRequestId) => {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authToken }
    };

    return fetch('/api/help-requests/' + helpRequestId, requestOptions).then(handleResponse).then((helpRequest) => {
        return helpRequest;
    });
};

export {
    getHelpRequests,
    getHelpRequest,
    setHelpRequests,
    setHelpRequestCount,
    resolveHelpRequest,
    deleteHelpRequest,
    searchHelpRequests,
    transferHelpRequest,
    getHelpRequestsCount
};