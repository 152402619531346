import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import InternalChatRoomInputArea from './InternalChatRoomInputArea';
import InternalChatRoomMessages from './InternalChatRoomMessages';
import InternalChatRoomTopBar from './InternalChatRoomTopBar';
import { createdMessage, setCurrentMessages, createNotification, notificationRead } from '../../services/internalChats';
import { uploadMedia } from '../../services/upload';

class InternalChatRoom extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            messageValue: '',
            emojiPickerShown: false,
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            loadingMediaPreview: false,
        };

        this.handleMessageType = this.handleMessageType.bind(this);
        this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
        this.handleEmojiPick = this.handleEmojiPick.bind(this);
        this.handleTextEnter = this.handleTextEnter.bind(this);
        this.handleMediaTrigger = this.handleMediaTrigger.bind(this);
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
        this.handleLoadMoreMessages = this.handleLoadMoreMessages.bind(this);
    }

    componentDidMount(){
        setCurrentMessages(this.props);
        notificationRead(this.props.internalChats.selectedChat.chat._id, this.props.auth.agent.agent._id, this.props.auth.selectedChatbot.token);
    }

    componentDidUpdate(prevProps){
        if(prevProps.internalChats.selectedChat.messages !== this.props.internalChats.selectedChat.messages){
            notificationRead(this.props.internalChats.selectedChat.chat._id, this.props.auth.agent.agent._id, this.props.auth.selectedChatbot.token);
        }
    }
    
    handleMessageType(e){
        this.setState({ messageValue: e.target.value });
    }

    handleEmojiPick(emojiData){
        this.setState(prevState => ({
            messageValue: prevState.messageValue + emojiData.emoji
        }))
    }

    async handleLoadMoreMessages(){
        await this.props.dispatch({
            type: 'INTERNAL_CHATS_UPDATE',
            updates: {
                messagesLimit: (this.props.internalChats.messagesLimit + this.props.internalChats.messagesOffset)
            }
        });
        await setCurrentMessages(this.props);
    }

    async handleMessageSubmit(e){
        e.preventDefault();
        if(this.state.messageValue.trim().length > 0 || this.state.hasMedia){
            let media = null;
            if(this.state.mediaFile && !this.state.mediaFile.url){
                const uploadResponse = await uploadMedia(this.props.auth.selectedChatbot.token, this.state.mediaFile);
                if(uploadResponse.url){
                    media = {
                        url: uploadResponse.url,
                        name: this.state.mediaFile.name,
                        type: this.state.mediaFile.type
                    };
                }
            }
    
            if(this.state.mediaFile && this.state.mediaFile.url){
                media = this.state.mediaFile;
            }

            const message = {
                sender: this.props.auth.agent.agent._id,
                message_data: {
                    text: this.state.messageValue && this.state.messageValue.trim().length > 0 ? this.state.messageValue.trim() : undefined,
                    media: media ? [media] : null
                }
            };
            this.setState({ 
                messageValue: '', 
                hasMedia: false,
                mediaFile: undefined,
                mediaPreview: undefined,
                loadingMediaPreview: false 
            });
            this.props.dispatch({
                type: 'SET_CURRENT_MESSAGES',
                messages: [...this.props.internalChats.selectedChat.messages, {...message, sender: this.props.auth.agent.agent, sending: true}]
            });
            createdMessage(this.props.internalChats.selectedChat.chat._id, message, this.props.auth.selectedChatbot.token).then(message => {
                setCurrentMessages(this.props);
                const currentAgent = this.props.auth.agent.agent;
                const chattingTo = this.props.internalChats.selectedChat.chat.participants.filter(participant => participant._id !== currentAgent._id);
                const notificationBody = chattingTo.map(participant => ({ agent: participant._id, message: message._id }));
                createNotification(this.props.internalChats.selectedChat.chat._id, notificationBody, this.props.auth.selectedChatbot.token);
            });
        }
    }

    handleTextEnter(e){
        if(e.which === 13 && !e.shiftKey){
            e.preventDefault();
            this.handleMessageSubmit(e);
        }
    }

    handleMediaTrigger(input){
        input.click();
    }

    handleMediaChange(e){
        this.setState({ hasMedia: true, loadingMediaPreview: true });
        if(e.target.files && e.target.files[0]){
            const file = e.target.files[0];
            this.setState({ mediaFile: file });
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ mediaPreview: e.target.result, loadingMediaPreview: false });
            }

            reader.readAsDataURL(file);
        }
    } 
    
    handleRemoveMedia(){
        this.setState({
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            loadingMediaPreview: false
        });
    }

    render(){
        const chat = this.props.internalChats.selectedChat.chat;
        const currentAgent = this.props.auth.agent.agent;
        const participants = chat.participants;
        const chattingTo = participants.filter(participant => participant._id !== currentAgent._id);
        const isGroupChat = chattingTo.length > 1;
        return (
            <div className="internal_chat_room">
                <InternalChatRoomTopBar handleToggleChat={this.props.handleToggleChat} handleDeselectChat={this.props.handleDeselectChat} isGroupChat={isGroupChat} chattingTo={chattingTo}/>
                {!this.props.internalChats.selectedChat.loadingMessages ? (
                    <div className="internal_chat_room_content">
                        <InternalChatRoomMessages currentAgent={this.props.auth.agent.agent} handleLoadMoreMessages={this.handleLoadMoreMessages} messages={this.props.internalChats.selectedChat.messages} hasMoreMessages={this.props.internalChats.hasMoreMessages}/>
                        <InternalChatRoomInputArea handleTextEnter={this.handleTextEnter} handleEmojiPick={this.handleEmojiPick} handleMessageSubmit={this.handleMessageSubmit} handleMessageType={this.handleMessageType} messageValue={this.state.messageValue} isGroupChat={isGroupChat} chattingTo={chattingTo} handleMediaTrigger={this.handleMediaTrigger} handleMediaChange={this.handleMediaChange} mediaFile={this.state.mediaFile} mediaPreview={this.state.mediaPreview} handleRemoveMedia={this.handleRemoveMedia} loadingMediaPreview={this.state.loadingMediaPreview} hasMedia={this.state.hasMedia} />
                    </div>
                ) : <Preloader/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalChatRoom);