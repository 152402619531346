import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { createChatbot, getChatbots, updateChatbot, deleteChatbot } from '../../services/chatbots';
import { HiOutlinePencil, HiTrash } from 'react-icons/hi2';
import Tooltip from '@material-ui/core/Tooltip';
import { HiPlus } from 'react-icons/hi2';
import EditChatbotModal from './EditChatbotModal';
import ConfirmModal from '../Base/ConfirmModal';
import LoadingModal from '../Base/LoadingModal';

class AdminChatbots extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            chatbots: [],
            loadingChatbots: true,
            selectedChatbot: undefined,
            chatbotToDelete: undefined,
            deletingChatbot: false,
            savingChatbot: false
        };

        this.handleEditChatbot = this.handleEditChatbot.bind(this);
        this.handleChatbotSave = this.handleChatbotSave.bind(this);
        this.handleDeleteChatbotConfirm = this.handleDeleteChatbotConfirm.bind(this);
        this.handleDeleteChatbot = this.handleDeleteChatbot.bind(this);
    }

    async componentDidMount(){
        const chatbots = await getChatbots(this.props.auth.agent.token);
        this.setState({
            chatbots,
            loadingChatbots: false
        });
    }

    handleEditChatbot(chatbot){
        this.setState({ selectedChatbot: chatbot });
    }

    async handleChatbotSave(chatbotItem){
        this.setState({ savingChatbot: true, selectedChatbot: undefined })
        if(chatbotItem._id){
            await updateChatbot(chatbotItem._id, this.props.auth.agent.token, { ...chatbotItem, owner: chatbotItem.owner._id });
            const chatbots = await getChatbots(this.props.auth.agent.token);
            return this.setState({
                chatbots,
                loadingChatbots: false,
                savingChatbot: false
            });
        }else{
            await createChatbot(this.props.auth.agent.token, { ...chatbotItem, owner: chatbotItem.owner._id });
            const chatbots = await getChatbots(this.props.auth.agent.token);
            return this.setState({
                chatbots,
                loadingChatbots: false,
                savingChatbot: false
            });
        }
    }

    handleDeleteChatbotConfirm(chatbot){
        this.setState({ chatbotToDelete: chatbot });
    }

    async handleDeleteChatbot(){
        await deleteChatbot(this.props.auth.agent.token, this.state.chatbotToDelete._id);
        this.setState({ deletingChatbot: true, chatbotToDelete: undefined });
        const chatbots = await getChatbots(this.props.auth.agent.token);
        return this.setState({
            chatbots,
            deletingChatbot: false
        });
    }

    render(){
        return (
            <div className="admin_settings_widget">
                <h3>Chatbots</h3>
                <p>Edit chatbot and change owners.</p>
                <button className="btn mb-medium" onClick={() => { this.handleEditChatbot({ name: '', owner: undefined }) }}><HiPlus/>Add Chatbot</button>
                {!this.state.loadingChatbots ? (this.state.chatbots.length > 0 ? (
                    this.state.chatbots.map(chatbot => (
                        <div className="company_list_item">
                            <div className="company_list_item__badge">{(chatbot.owner && chatbot.owner.logo_icon) && <img src={chatbot.owner.logo_icon} alt="Admin Company Logo"/>}{chatbot.owner && chatbot.owner.name}</div>
                            <div className="company_list_item__name">{chatbot.name}</div>
                            <div className="company_list_item__actions">
                                <Tooltip title="Edit chatbot" arrow><div className="company_list_item__action" onClick={() => { this.handleEditChatbot(chatbot) }}><HiOutlinePencil/></div></Tooltip>
                                <Tooltip title="Delete chatbot" arrow><div className="company_list_item__action" onClick={() => { this.handleDeleteChatbotConfirm(chatbot) }}><HiTrash/></div></Tooltip>
                            </div>                
                            {this.state.selectedChatbot && <EditChatbotModal onSave={this.handleChatbotSave} isOpen={this.state.selectedChatbot ? true : false} onRequestClose={() => { this.handleEditChatbot(undefined) }} chatbot={this.state.selectedChatbot}/>}
                            {this.state.chatbotToDelete && <ConfirmModal isOpen={this.state.chatbotToDelete ? true : false} title="Delete Chatbot" onConfirm={this.handleDeleteChatbot} description={<span>You are about to delete <b>{this.state.chatbotToDelete.name}</b><br/><br/>Please note that deleting this chatbot will be a permanent action. Are you sure you want to do this?</span>} confirmText="Delete" onRequestClose={() => { this.handleDeleteChatbotConfirm(undefined) }}/>}
                            {this.state.deleteChatbot && <LoadingModal isOpen={this.state.deletingChatbot} text="Deleting chatbot"/>}
                            {this.state.savingChatbot && <LoadingModal isOpen={this.state.savingChatbot} text="Saving chatbot"/>}
                        </div>
                    ))
                    ) : 'No Chatbots') : <Preloader/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AdminChatbots);