import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { getChatbot, updateSetting } from '../../services/chatbots';
import { companyService } from '../../services/company';

class BotSettings extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingSettings: true,
            settings: this.props.auth.selectedChatbot.settings,
            company: undefined
        };
    }

    async componentDidMount(){
        const chatbot = await getChatbot(this.props.auth.selectedChatbot.token, this.props.auth.selectedChatbot._id);
        const company = await companyService.getCompany(this.props.auth.selectedCompany, this.props.auth.selectedChatbot.token)
        this.props.dispatch({ type: 'SET_CURRENT_CHATBOT_SETTINGS', settings: chatbot.settings });
        this.setState({ loadingSettings: false, company })
    }

    async handleToggleSettingChage(name, value){
        this.setState({ [name + '_disabled']: true })
        this.props.dispatch({ type: 'SET_CURRENT_CHATBOT_SETTINGS', settings: { [name]: value } });
        this.setState(prevState => ({ settings: { ...prevState.settings, [name]: value }}));
        await updateSetting(this.props.auth.selectedChatbot.token, { [name]: value })
        this.setState({ [name + '_disabled']: false })
    }

    async handleInputSettingChage(name, value){
        this.setState({ [name + '_disabled']: true })
        this.props.dispatch({ type: 'SET_CURRENT_CHATBOT_SETTINGS', settings: { [name]: value } });
        this.setState(prevState => ({ settings: { ...prevState.settings, [name]: value }}));
        this.setState({ [name + '_disabled']: false })
    }

    async handleInputSettingSave(name, value){
        this.setState({ [name + '_disabled']: true })
        await updateSetting(this.props.auth.selectedChatbot.token, { [name]: value })
        this.setState({ [name + '_disabled']: false })
    }

    render(){
        return !this.state.loadingSettings ? (
            <div className="settings_container">
                {['admin'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Azure Bot Endpoint</div>
                            <div className="setting_name__body">Bot endpoint to send agent messages to.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <input type="text" placeholder="Enter bot endpoint" disabled={this.state['bot_endpoint_disabled']} value={this.props.auth.selectedChatbot.settings.bot_endpoint} onBlur={(e) => { this.handleInputSettingSave('bot_endpoint', e.target.value) }} onChange={(e) => { this.handleInputSettingChage('bot_endpoint', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Max Waiting Time (Minutes)</div>
                            <div className="setting_name__body">Amout of second a user can wait for a human agent before timing out. These will not be applied to current users waiting.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <input type="number" placeholder="Enter your time in minutes" disabled={this.state['bot_waiting_time_disabled']} value={this.props.auth.selectedChatbot.settings.bot_waiting_time} onBlur={(e) => { this.handleInputSettingSave('bot_waiting_time', e.target.value) }} onChange={(e) => { this.handleInputSettingChage('bot_waiting_time', e.target.value) }}/>
                        </div>
                    </div>)
                }
                {['admin', 'manager', 'agent'].includes(this.props.auth.agent.agent.role) && (
                    <div className="settings_row">
                        <div className="settings_col setting_name">
                            <div className="setting_name__name">Chat Session Time (Minutes)</div>
                            <div className="setting_name__body">The amount of time it takes for an inactive agent chat to timeout. These will not be applied to current users already in sessions.</div>
                        </div>
                        <div className="settings_col setting_value">
                            <input type="number" placeholder="Enter your time in minutes" disabled={this.state['bot_timeout_time_disabled']} value={this.props.auth.selectedChatbot.settings.bot_timeout_time} onBlur={(e) => { this.handleInputSettingSave('bot_timeout_time', e.target.value) }} onChange={(e) => { this.handleInputSettingChage('bot_timeout_time', e.target.value) }}/>
                        </div>
                    </div>)
                }
            </div>
        ) : <Preloader/>
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(BotSettings);