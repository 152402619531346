import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'
import AdminDashboard from '../components/AdminSettings/AdminDashboard';

class AdminRoute extends React.Component{
    render(){
        const { dispatch, isAuthenticated, component: Component, loggedIn, auth, agent, ...rest } = this.props;
        let componentToRoute = undefined;

        if(!localStorage.getItem('user')){
            componentToRoute = <Redirect to="/login" />;
        }

        if(!(agent && agent.role === 'admin')){
            componentToRoute = <Redirect to="/login" />;
        }

        return (
            <Route {...rest} component={(props) => (
                <div>
                    {componentToRoute ? componentToRoute : <AdminDashboard component={<Component {...props}/>}/>}
                </div>
            )}/>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.auth.uid,
    auth: state.auth ? state.auth : undefined,
    agent: state.auth.agent ? state.auth.agent.agent : undefined
})

export default connect(mapStateToProps)(AdminRoute)