const initialState = { 
    loadingConversation: false,
    currentConversation: undefined,
    messageLimit: 10,
    messageOffset: 10,
    hasMoreMessages: false,
    loadingMoreMessages: false,
    goToConversation: null
}

export default function chats(state = initialState, action){
    switch(action.type){
        case 'UPDATE_CHATS':
            return {
                ...state,
                ...action.chats
            }
        case 'RESET_MESSAGE_LIMIT':
            return {
                ...state,
                ...action.chats,
                messageLimit: initialState.messageLimit,
                messageOffset: initialState.messageOffset,
                hasMoreMessages: initialState.hasMoreMessages,
                loadingMoreMessages: initialState.loadingMoreMessages
            }
        case 'CLEAR_CHATS':
            return initialState;
        default: 
            return state;
    }
}