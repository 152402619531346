import { history } from '../helpers/history';

const updateChats = (state) => {
    return async (dispatch) => {
        dispatch({
            type: 'UPDATE_CHATS',
            chats: state
        });
    
        return Promise.resolve('test');
    }
};

const setGoToConversation = async (connectedProps, goToConversation) => {
    await connectedProps.dispatch(updateChats({
        goToConversation: goToConversation
    }));
}

const goToConversation = async (connectedProps, conversation_id) => {
    await setGoToConversation(connectedProps, conversation_id);
    
    if(history.location.pathname !== '/chats'){
        history.push('/chats');
    }
}

export { updateChats, goToConversation, setGoToConversation };