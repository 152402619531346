import React from 'react';
import EmojiPicker from '../Base/EmojiPicker';
import ChatMessageBarActions from './ChatMessageBarActions';
import { HiOutlineBolt, HiOutlineDocumentPlus, HiPaperAirplane, HiOutlineCheckCircle, HiOutlinePlusCircle, HiOutlineXMark } from 'react-icons/hi2'
import ChatMediaPreview from './ChatMediaPreview';
import TextArea from 'react-textarea-autosize';
import QuickReplyList from '../QuickReplies/QuickReplyList';
import { Tooltip } from '@material-ui/core';
import Preloader from '../Preloader';

export default class ChatMessageBar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            message: '',
            public: true,
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            loadingMediaPreview: false,
            quickRepliesShown: false,
            requiresUpload: false,
            savedQuickReply: false
        };

        this.handleMessageType = this.handleMessageType.bind(this);
        this.handleMessageSend = this.handleMessageSend.bind(this);
        this.handleEmojiPick = this.handleEmojiPick.bind(this);
        this.handleTogglePublic = this.handleTogglePublic.bind(this);
        this.handleMediaTrigger = this.handleMediaTrigger.bind(this);
        this.handleMediaChange = this.handleMediaChange.bind(this);
        this.handleRemoveMedia = this.handleRemoveMedia.bind(this);
        this.handleTextEnter = this.handleTextEnter.bind(this);
        this.handleQuickRepliesToggle = this.handleQuickRepliesToggle.bind(this);
        this.handleQuickReplySelect = this.handleQuickReplySelect.bind(this);
        this.handleAddToQuickReplyClick = this.handleAddToQuickReplyClick.bind(this);
    }

    handleMessageType(e){
        if(e.target.value.toLowerCase() === '/r'){
            return this.setState({
                message: '',
                quickRepliesShown: true
            });
        }

        this.setState({
            message: e.target.value
        });

        this.props.handleTypingEvent();
    }

    handleMessageSend(e){
        e.preventDefault();
        if(this.state.message.trim().length > 0 || this.state.mediaFile){
            this.props.handleSendMessage(this.state.message, this.state.public, this.state.mediaFile, this.props.currentConversation.channel);
            
            this.setState({
                message: '',
                hasMedia: false,
                mediaFile: undefined,
                mediaPreview: undefined,
                loadingMediaPreview: false
            });
        }
    }

    handleTogglePublic(publicBool){
        this.setState({ public: publicBool });
    }

    handleEmojiPick(emojiData){
        this.setState(prevState => ({
            message: prevState.message + emojiData.emoji
        }))
    }
    
    handleMediaTrigger(input){
        input.click();
    }

    handleMediaChange(e){
        this.setState({ hasMedia: true, loadingMediaPreview: true });
        if(e.target.files && e.target.files[0]){
            const file = e.target.files[0];
            this.setState({ mediaFile: file });
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({ hasMedia: true, mediaPreview: e.target.result, loadingMediaPreview: false });
            }

            reader.readAsDataURL(file);
        }
    } 
    
    handleRemoveMedia(){
        this.setState({
            hasMedia: false,
            mediaFile: undefined,
            mediaPreview: undefined,
            loadingMediaPreview: false
        });
    }
    
    handleTextEnter(e){
        if(e.which === 13 && !e.shiftKey){
            e.preventDefault();
            this.handleMessageSend(e);
        }
    }

    handleQuickRepliesToggle(){
        this.setState(prevState => ({ quickRepliesShown: !prevState.quickRepliesShown }), () => {
            if(this.state.quickRepliesShown){
                this.chatMessageTextArea.focus();
            }
        })
    }

    handleQuickReplySelect(quickReply){
        this.handleQuickRepliesToggle();
        this.setState(prevState => ({
            message: quickReply.message_data.text || prevState.message,
            hasMedia: quickReply.message_data.media && quickReply.message_data.media.length > 0 ? quickReply.message_data.media[0] : false,
            mediaFile: (quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0],
            mediaPreview: (quickReply.message_data.media && quickReply.message_data.media.length > 0) && quickReply.message_data.media[0].url,
        }));
    }

    async handleAddToQuickReplyClick(){
        this.setState({ savingQuickReply: true })
        await this.props.handleAddToQuickReplies(this.state.message, this.state.mediaFile);
        this.setState({ savingQuickReply: false, savedQuickReply: true })
        setTimeout(() => { 
            this.setState({ savedQuickReply: false });
        }, 5000);
    }

    render(){
        return(
            <div className="chat_message_bar">
                {this.state.quickRepliesShown && <QuickReplyList messageValue={this.state.message} currentConversation={this.props.currentConversation} onSelect={this.handleQuickReplySelect}/>}
                {(this.state.hasMedia && this.state.mediaPreview) && <ChatMediaPreview handleRemoveMedia={this.handleRemoveMedia} loadingMediaPreview={this.state.loadingMediaPreview} mediaPreview={this.state.mediaPreview} mediaFile={this.state.mediaFile}/>}               
                <div className='chat_message_bar__message_bar'>
                    <form onSubmit={this.handleMessageSend}>
                        <div className='chat_message_bar__message_bar__input'>
                            <div className={`chat_message_bar__message_bar__input_area ${!this.state.public ? 'internal' : ''}`}>
                                <TextArea ref={(input) => { this.chatMessageTextArea = input; }} onKeyPress={this.handleTextEnter} type="text" value={this.state.message} onChange={this.handleMessageType} placeholder={this.state.quickRepliesShown ? 'Type to search quick replies' : this.state.public ? 'Enter your message to user' : 'Enter your internal message'}/>  
                                {(this.state.message.trim().length > 0 || this.state.hasMedia) && (
                                    <div className="add_to_quick_reply__container">
                                        {(this.state.savedQuickReply ? <div className="add_to_quick_reply_loader"><HiOutlineCheckCircle/></div> : (this.state.savingQuickReply ? <div className="add_to_quick_reply_loader"><Preloader size="15" padding="0"/></div> : <Tooltip arrow title="Add to quick replies"><div className="add_to_quick_reply" onClick={this.handleAddToQuickReplyClick}><HiOutlinePlusCircle/></div></Tooltip>))}
                                    </div>
                                )}
                            </div>
                            <button className="chat_send_button" title="Send message" disabled={(this.state.message.length === 0 || this.state.message.length >= 2800) && !this.state.hasMedia}><HiPaperAirplane/></button>
                        </div>
                    </form>
                    <div className='chat_message_bar__actions'>
                        <Tooltip title="Attach file" arrow placement='top'><button className="chat_message_bar__action" onClick={() => { this.handleMediaTrigger(this.mediaUploadElement) }}><HiOutlineDocumentPlus/></button></Tooltip>
                        <Tooltip title={this.state.quickRepliesShown ? 'Close quick replies' : 'Show quick replies'} arrow placement='top'><button className="chat_message_bar__action" onClick={this.handleQuickRepliesToggle}>{!this.state.quickRepliesShown ? <HiOutlineBolt/> : <HiOutlineXMark/>}</button></Tooltip>          
                        <EmojiPicker tooltip handleEmojiPick={this.handleEmojiPick}/>
                        <ChatMessageBarActions 
                            currentConversation={this.props.currentConversation}
                            public={this.state.public} 
                            handleTogglePublic={this.handleTogglePublic}
                        />
                    </div>
                </div>
                <input ref={input => this.mediaUploadElement = input} type="file" style={{display: 'none'}} onChange={this.handleMediaChange}/>
                {this.state.savedQuickReply && <div className="message_bar_alert"><HiOutlineCheckCircle/>Added to quick replies</div>}
            </div>
        );
    }
}