import React from 'react';
import { connect } from 'react-redux';
import ChatMediaViewer from './ChatMediaViewer';
import ChatMessageBar from './ChatMessageBar';
import ChatMessages from './ChatMessages';
import ChatTopBar from './ChatTopBar';
import { isImage, isVideo } from '../../services/upload';
import { createTypingEvent, notificationRead } from '../../services/conversation';

class Chat extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            mediaItem: undefined
        };

        this.handleMediaClick = this.handleMediaClick.bind(this);
        this.notificationRead = this.notificationRead.bind(this);
        this.handleTypingEvent = this.handleTypingEvent.bind(this);
    }

    handleMediaClick(media){
        this.setState({ mediaItem: media }, () => {
            if(this.state.mediaItem && (!isVideo(this.state.mediaItem.type) && !isImage(this.state.mediaItem.type))){
                window.open(media.url, "_blank")
            }
        });
    }
    
    notificationRead(conversationId, agentId){
        return notificationRead(conversationId, agentId, this.props.auth.selectedChatbot.token);
    }

    handleTypingEvent(){
        if(!this.state.isTyping){
            this.setState({ isTyping: true }, () => {
                setTimeout(() => {
                    this.setState({ isTyping: false })
                }, 1500)
            })
            createTypingEvent(this.props.auth.selectedChatbot.token, this.props.currentConversation.conversation_id, 'agent');
        }
    }

    render(){
        return (
            <div className="chat" style={{ backgroundImage: 'linear-gradient( rgba(250,250,250,.755), rgba(250,250,250,.825) ), url(/images/chats_background.webp)' }}>
                <ChatTopBar 
                    filters={this.props.filters} 
                    handleMobileSidebarTrigger={this.props.handleMobileSidebarTrigger} 
                    handleChatBackClick={this.props.handleChatBackClick} 
                    currentMessages={this.props.currentMessages} 
                    setCurrentMessages={this.props.setCurrentMessages}
                    currentConversation={this.props.currentConversation}
                    setCurrentConversation={this.props.setCurrentConversation}
                />
                <ChatMessages 
                    loadingConversation={this.props.loadingConversation}
                    currentConversation={this.props.currentConversation} 
                    currentMessages={this.props.currentMessages} 
                    currentAgent={this.props.auth.agent.agent}
                    hasMoreMessages={this.props.hasMoreMessages}
                    loadingMoreMessages={this.props.loadingMoreMessages}
                    handleLoadMoreMessages={this.props.handleLoadMoreMessages}
                    handleMediaClick={this.handleMediaClick}
                    notificationRead={this.notificationRead}
                />
                {this.props.currentConversation.agents_connected && 
                    this.props.currentConversation.agents_connected.some((agent) => agent._id === this.props.auth.agent.agent._id) && 
                    <ChatMessageBar 
                        handleTypingEvent={this.handleTypingEvent}
                        currentConversation={this.props.currentConversation} 
                        handleSendMessage={this.props.handleSendMessage} 
                        handleAddToQuickReplies={this.props.handleAddToQuickReplies}/>
                    }
                    {this.state.mediaItem && (isVideo(this.state.mediaItem.type) || isImage(this.state.mediaItem.type)) && <ChatMediaViewer media={this.state.mediaItem} handleMediaClick={this.handleMediaClick}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Chat);