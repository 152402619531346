import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CompanyList from './CompanyList';

class CompanySelectPage extends React.Component{
    render(){
        return(
            <div>
                {!this.props.auth.agent ? <Redirect to="/"/> : (
                    <div>
                        {this.props.auth.selectedCompany ? <Redirect to="/"/> : (
                            <div className="company_select_page">
                                <div className="company_select_page__container">
                                    <div className="container">
                                        <div>
                                            <h2>Select your portal</h2>
                                            <CompanyList/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(CompanySelectPage);