import React from 'react';
import MeetingIdForm from './MeetingIdForm';
import { validateMeeting } from '../../services/meetings';
import { history } from '../../helpers/history';
import VideoChat from './VideoChat';
import { isMobile } from '../../services/responsive';
import Draggable from 'react-draggable';

export default class MeetingRoom extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            meetingId: this.props.meeting ? this.props.meeting._id : this.props.match.params.meetingId,
            alertMessage: undefined,
            validatingId: false,
            meetingIdInput: '',
            isValid: false,
            meeting: this.props.meeting || undefined,
            minimizable: this.props.minimizable || false,
            minimized: false,
            draggable: true
        };

        this.handleMeetingIdInput = this.handleMeetingIdInput.bind(this);
        this.handleMeetingIdSubmit = this.handleMeetingIdSubmit.bind(this);
        this.handleMinimize = this.handleMinimize.bind(this);
    }

    componentDidMount(){
        if(isMobile()){
            this.setState({ draggable: false })
        }

        if(this.state.meetingId){
            this.setState({ validatingId: true })
            validateMeeting(this.state.meetingId).then(meeting => {
                this.setState({ validatingId: false, alertMessage: undefined, meetingId: meeting._id, meeting })
            }).catch((e) => {
                this.setState({ alertMessage: e, validatingId: false, meetingId: undefined })
                this.setState({  })
                history.push('/meeting');
            })
        }
    }

    handleMeetingIdInput(e){
        this.setState({
            meetingIdInput: e.target.value
        })
    }

    handleMeetingIdSubmit(e){
        e.preventDefault();
        this.setState({ validatingId: true })
        validateMeeting(this.state.meetingIdInput).then(meeting => {
            this.setState({ validatingId: false, alertMessage: undefined, meetingId: meeting._id, meeting })
            history.push('/meeting/' + meeting._id);
        }).catch((e) => {
            this.setState({ alertMessage: e })
            this.setState({ validatingId: false })
        })
    }

    handleMinimize(){
        this.setState(prevState => ({
            minimized: !prevState.minimized
        }));
    };

    render(){
        const mobile = isMobile();
        return (
            <Draggable disabled={!this.state.draggable || !this.state.minimized}>
                <div onClick={mobile && this.state.minimized ? () => { this.handleMinimize() } : () => {}} className={`meeting_room ${this.props.agent ? 'agent_view' : ''} ${this.state.minimized ? 'minimized' : 'maximized'}`} style={{ backgroundImage: this.state.meeting && this.state.meeting.company && !this.props.agent ? `url(${this.state.meeting.company.background_image})` :''}}>
                    {this.state.meetingId && this.state.meeting ? <VideoChat displayName={this.props.displayName} agent={this.props.agent} onDisconnect={this.props.onDisconnect} handleMinimize={this.handleMinimize} minimizable={this.state.minimizable} minimized={this.state.minimized} meeting={this.state.meeting}  autoJoin={this.props.meeting ? true : false} validatingId={this.state.validatingId}/> : <MeetingIdForm alertMessage={this.state.alertMessage} meetingIdInput={this.state.meetingIdInput} validatingId={this.state.validatingId} handleMeetingIdSubmit={this.handleMeetingIdSubmit} handleMeetingIdInput={this.handleMeetingIdInput}/>}
                </div>
            </Draggable>
        );
    }
}