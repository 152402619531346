import React from 'react';
import { connect } from 'react-redux';
import { titleCase } from '../../services/misc';
import { HiCheck, HiDocumentText, HiOutlineEnvelope, HiXMark } from 'react-icons/hi2';
import { updateAgent } from '../../services/user';
import UserInfoAttachmentModal from './UserInfoAttachmentModal';
import onClickOutside from "react-onclickoutside";
import MessageMedia from './MessageMedia';
import { Tooltip } from '@material-ui/core';
import validator from 'validator';

class UserInfoItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            key: props.keyName,
            value: props.value,
            initValue: props.value,
            editingField: false,
            user: this.props.user,
            savingField: false,
            userInfoAttachment: undefined
        }

        this.handleEditFieldClick = this.handleEditFieldClick.bind(this);
        this.handleCancelEditFieldClick = this.handleCancelEditFieldClick.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleFieldSaveClick = this.handleFieldSaveClick.bind(this);
        this.handleViewAttachmentClick = this.handleViewAttachmentClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({value: this.props.value});
            if(prevProps.value === this.state.userInfoAttachment){
                this.setState({userInfoAttachment: this.props.value});
            }
        }
      }

    handleEditFieldClick(){
        this.setState((prevState) => {
            return {
                editingField: !prevState.editingField
            };
        })
    }

    handleCancelEditFieldClick(){
        this.setState((prevState) => {
            return {
                value: prevState.initValue,
                editingField: false
            };
        })
    }

    handleClickOutside = evt => {
        if(this.state.editingField){
            this.handleCancelEditFieldClick()
        }
    };

    handleFieldChange(e){
        this.setState({
            value: e.target.value
        });
    }

    handleFieldSaveClick(){
        const updates = `{"updates": {"${this.state.key}": "${this.state.value}"}${this.props.objectName ? ', "objectName": "' + this.props.objectName + '"': ''}}`;
        const updatesObject = JSON.parse(updates);
        this.setState({
            savingField: true,
            editingField: false
        });
        updateAgent(this.props.auth.selectedChatbot.token, this.state.user.user_id, updatesObject).then(() => {
            this.setState({
                savingField: false
            });
        }).catch(() => {
            this.setState((prevState) => {
                return {
                    savingField: false,
                    value: prevState.initValue
                };
            });
        });
    }

    handleViewAttachmentClick(attachment){
        this.setState({ userInfoAttachment: attachment })
    }

    render(){
        return (
            <div>
                {(typeof this.state.value !== 'object' || this.state.value === null) ? (  
                    <div className="user_info_list_item">
                        <div className="user_info_list_item__details">
                            <div className="user_info_list_item__key">{titleCase(this.state.key)}</div>
                            {!this.state.editingField ? <div className={`user_info_list_item__value ${this.state.savingField ? 'text_preloader' : ''}`} onClick={this.handleEditFieldClick}>{this.state.value ? <span className="user_info_item_value__inner">{this.state.value}{validator.isEmail(this.state.value) && <a onClick={(e) => { e.stopPropagation() }} href={`mailto:${this.state.value}`} target="_blank" rel="noreferrer" className="user_info_item_value__action"><HiOutlineEnvelope/></a>}</span> : <span className="italics">None entered</span>}</div> : <div className="user_info_list_edit"><input type="text" placeholder="Enter a value" value={this.state.value} onChange={this.handleFieldChange} autoFocus={true}/><div className="user_info_list_item__actions"><Tooltip title="Discard Changes" arrow><div className="user_info_list_item__action cancel" onClick={this.handleCancelEditFieldClick}><HiXMark/></div></Tooltip><Tooltip title="Save changes" arrow><div className="user_info_list_item__action save" onClick={this.handleFieldSaveClick}><HiCheck/></div></Tooltip></div></div>}
                        </div>
                    </div>
                ) : (
                    <div>
                        {(typeof this.state.value === 'object' && this.state.value !== null && this.state.value.type && this.state.value.type === 'table') && <div className="user_info_list_item"><div className="user_info_list_item__details"><div className="user_info_list_item__key">{titleCase(this.state.key)}</div><div className={`user_info_list_item__value`}><table className="user_info_subtable">{Object.keys(this.state.value.data).map(key => <tr><td className="user_info_subtable_title">{titleCase(key)}</td><td>{this.state.value.data[key]}</td></tr>)}</table></div></div></div>}
                        {(typeof this.state.value === 'object' && this.state.value !== null && this.state.value.url) && <div className="user_info_list_item"><div className="user_info_list_item__details"><div className="user_info_list_item__key">{titleCase(this.state.key)}</div><div className={`user_info_list_item__value`}><MessageMedia thumbnail={true} media={this.state.value} handleMediaClick={this.props.handleMediaClick}/></div></div></div>}
                        {typeof this.state.value === 'object' && this.state.value !== null && (!this.state.value.type && <div className="user_info_list_item"><div className="attachment" onClick={() => { this.handleViewAttachmentClick(this.state.value) }}><HiDocumentText/><span>View {titleCase(this.state.key)}</span></div></div>)}
                    </div>
                )}
                {this.state.userInfoAttachment && <UserInfoAttachmentModal isOpen={this.state.userInfoAttachment ? true : false} attachmentName={this.state.key} userInfoAttachment={this.state.userInfoAttachment}  user={this.props.user} onRequestClose={() => { this.handleViewAttachmentClick(undefined) }}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(onClickOutside(UserInfoItem));