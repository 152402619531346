import React from 'react';
import { connect } from 'react-redux';
import InternalNotification from './InternalNotification';
import { notificationShown } from '../../services/internalChats';

class InternalNotifications extends React.Component{
    constructor(props){
        super(props);
        this.handleMarkAsShown = this.handleMarkAsShown.bind(this);
    }

    handleMarkAsShown(chatId, agentId){
        notificationShown(chatId, agentId, this.props.auth.selectedChatbot.token);
    }

    render(){
        let agentNotifications = [];
        const chats = this.props.internalChats.chats;
        const currentAgent = this.props.auth.agent.agent;
        return (
            <div className="internal_notifications">
                {chats.forEach(chat => {
                    const notification = chat.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
                    if(notification && !notification.shown && notification.unread && notification.messages && notification.messages.length > 0){
                        notification.messages.forEach(message => {
                            const agentNotificationIndex = agentNotifications.findIndex(agentNotification => agentNotification.agent._id.toString() === message.sender._id.toString());
                            if(agentNotificationIndex > -1){
                                let agentNotification =  agentNotifications[agentNotificationIndex];
                                agentNotification.messages = [...agentNotification.messages, message];
                            }else{
                                agentNotifications.push({
                                    agent: message.sender,
                                    messages: [message],
                                    chat
                                });
                            }
                        })
                    }
                })}
                {agentNotifications.map(agentNotification => <InternalNotification handleNotificationClick={this.props.handleNotificationClick} notification={agentNotification} currentAgent={currentAgent} handleMarkAsShown={() => { this.handleMarkAsShown(agentNotification.messages[0].conversation, currentAgent._id) }}/>)}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalNotifications);