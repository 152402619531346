import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import AgentList from '../Agents/AgentList';
import { companyService } from '../../services/company';
import { agentService } from '../../services/agents';
import { firstBy } from "thenby";
import Select from 'react-select';

class EditCompanyModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            company: this.props.company,
            allAgents: [],
            companyAgents: [],
            loadingAgents: true
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleAgentListSelect = this.handleAgentListSelect.bind(this);
        this.loadAgents = this.loadAgents.bind(this);
    }


    componentDidMount(){
        this.loadAgents();
    }

    componentDidUpdate(prevProps){
        if(prevProps.company !== this.props.company){
            this.setState({ company: this.props.company })
        }
    }

    handleInputChange(e){
        this.setState(prevState => ({ company: { ...prevState.company, [e.target.name]: e.target.value } }))
    }

    async loadAgents(){
        let agents = await agentService.getAgents(this.props.auth.agent.token);
        let companyAgents = [];
        if(this.state.company._id){
            companyAgents = await companyService.getCompanyAgents(this.state.company._id, this.props.auth.agent.token);
        }

        agents = agents.sort(firstBy("disabled").thenBy((a, b) => {
            if(a.agent_settings.status === "offline"){ return  1 }
            if(b.agent_settings.status === "offline"){ return  -1 }
        }).thenBy("first_name"));
        companyAgents = companyAgents.sort(firstBy("disabled").thenBy((a, b) => {
            if(a.agent_settings.status === "offline"){ return  1 }
            if(b.agent_settings.status === "offline"){ return  -1 }
        }).thenBy("first_name"));
        this.setState({ allAgents: agents, companyAgents, loadingAgents: false })
    }

    handleAgentChange(e){
        this.setState(prevState => ({ companyAgents: e.map(element => element.value) }))
    }

    handleAgentListSelect(agent, isSelected){
        if(isSelected){
            this.setState(prevState => ({ companyAgents: prevState.companyAgents.filter(companyAgent => companyAgent._id !== agent._id ) }))
        }else{            
            this.setState(prevState => ({ companyAgents: [ ...prevState.companyAgents, agent ] }))
        }
    }
    
    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="action_modal modal__preloader">
                <div className="modal__title">{!this.state.company._id ? 'New' : 'Edit'} Company</div>
                <div className="form-group">
                    <label>Name</label>
                    <input value={this.state.company.name} onChange={this.handleInputChange} type="text" name="name" placeholder="Enter company name"/>
                </div>
                <div className="form-group">
                    <label>Icon Logo (URL)</label>
                    <input value={this.state.company.logo_icon} onChange={this.handleInputChange} type="text" name="logo_icon" placeholder="Enter company logo icon url"/>
                </div>
                <div className="form-group">
                    <label>Logo (URL)</label>
                    <input value={this.state.company.logo} onChange={this.handleInputChange} type="text" name="logo" placeholder="Enter company logo url"/>
                </div>
                <div className="form-group">
                    <label>Backgroudn Image (URL)</label>
                    <input value={this.state.company.background_image} onChange={this.handleInputChange} type="text" name="background_image" placeholder="Enter company background image url"/>
                </div>
                <div className="form-group">
                    <label>Agents in company</label>
                    <Select classNamePrefix="custom_select"  className="form_select" onChange={this.handleAgentChange} value={this.state.companyAgents.map(agent => ({ value: agent, label: agent.first_name + ' ' + agent.last_name }))} isMulti options={this.state.allAgents.map(agent => ({ value: agent, label: agent.first_name + ' ' + agent.last_name }))}/>
                </div>
                <AgentList loadingAgents={this.state.loadingAgents} isSelectList={true} selectedAgents={this.state.companyAgents} onSelect={this.handleAgentListSelect} loadAgents={this.props.loadQueues} agents={this.state.allAgents} queues={this.props.queues} currentAgent={this.props.auth.agent.agent}/>
                <div className="modal__actions mt-medium">
                    <div className="btn button_fit" onClick={(e) => { this.props.onRequestClose() }}>Cancel</div>
                    <button className="btn button_fit" disabled={this.state.company.name.trim().length === 0} onClick={() => { this.props.onSave(this.state.company, this.state.companyAgents) }}>Save</button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditCompanyModal);