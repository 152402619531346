import React from "react";
import Select from 'react-select';

class LocalSetttings extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            cameraDeviceList: undefined,
            cameraOptions: {},
            microphoneDeviceList: undefined,
            microphoneOptions: {},
            speakerDeviceList: undefined,
            speakerOptions: {}
        };
    }
    async componentDidMount(){
        const cameras = await this.props.deviceManager.getCameras();
        const cameraOptions = cameras.map(camera => ({ value: camera, label: camera.name }))
        this.setState({ cameraDeviceList: cameras, cameraOptions });

        const microphones = await this.props.deviceManager.getMicrophones();
        const microphoneOptions = microphones.map(microphone => ({ value: microphone, label: microphone.name }))
        this.setState({ microphoneDeviceList: cameras, microphoneOptions });

        const speakers = await this.props.deviceManager.getSpeakers();
        const speakerOptions = speakers.map(speaker => ({ value: speaker, label: speaker.name }))
        this.setState({ speakerDeviceList: speakers, speakerOptions });
    }

    render(){
        return (
            <div className="local_settings">
                {!(this.props.videoOn === false) && (this.state.cameraDeviceList ? (
                    <div className="select-item">
                        <label>Select your camera:</label>
                        <Select classNamePrefix="custom_select"  className="form_select" value={this.state.cameraOptions.find(option => option.value.id === this.props.selectedCameraDeviceId)} options={this.state.cameraOptions} onChange={this.props.handleVideoChange}/>
                        {this.props.showCameraNotFoundWarning && <div className="alert_box alert-danger my-medium">No camera found</div>}
                    </div>
                ) : 'Loading...')}
                {this.state.microphoneDeviceList ? (
                    this.props.selectedMicrophoneDeviceId && (
                        <div className="select-item">
                            <label>Select your microphone:</label>
                            <Select classNamePrefix="custom_select"  className="form_select" value={this.state.microphoneOptions.find(option => option.value.id === this.props.selectedMicrophoneDeviceId)} options={this.state.microphoneOptions} onChange={this.props.handleAudioChange}/>
                            {this.props.showMicrophoneNotFoundWarning && <div className="alert_box alert-danger my-medium">No microphone found</div>}
                        </div>
                    )
                    
                ) : 'Loading...'}
                {this.state.speakerDeviceList ? (
                    <div className="select-item speakers">
                        <label>Select your speaker:</label>
                        <Select classNamePrefix="custom_select"  className="form_select" value={this.state.speakerOptions.find(option => option.value.id === this.props.selectedSpeakerDeviceId)} options={this.state.speakerOptions} onChange={this.props.handleSpeakerChange}/>
                        {this.props.showMicrophoneNotFoundWarning && <div className="alert_box alert-danger my-medium">No speakers found</div>}
                    </div>
                ) : ''}
            </div>
        );
    }
};

export default LocalSetttings;