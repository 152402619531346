import React from 'react';
import { getInitials } from '../../services/misc';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';

export default class ChatMessageAvater extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isHovered: false
        }
    }

    render(){
        const message = this.props.message;
        const conversation = this.props.conversation;
        const senderInfo = {
            senderType: undefined,
            senderName: undefined,
            senderInitials: undefined,
            senderImage: undefined
        };
        
        switch(message.sender){
            case 'bot':
                senderInfo.senderType = 'bot';
                senderInfo.senderImage = '/favicon.ico';
                break;
            case 'user':
                senderInfo.senderType = 'user';
                if(conversation.user && conversation.user.user_info && conversation.user.user_info.name){
                    senderInfo.senderName = conversation.user.user_info.name; 
                    senderInfo.senderInitials = getInitials(senderInfo.senderName);
                }
                senderInfo.senderImage = '/images/user_icon.svg';
                break;
            default:
                senderInfo.senderType = 'agent';
                senderInfo.senderImage = '/images/user_icon.svg';
                break;
        };
        
        if(senderInfo.senderType === 'agent' && message.agent_sender_object){
            return(
                <AgentProfileAvatar popupEnabled={true} agent={message.agent_sender_object}/>
            );
        }else{
            return (
                <div className={`chat_message_avatar ${senderInfo.senderType}`}>
                    {senderInfo.senderInitials ? <div className="chat_message_avatar__initials">{this.props.svg ?<svg
                        width="100%"
                        height="100%"
                        viewBox="0 -30 500 200"
                      >
                            <text
                                y="50%"
                                x="50%"
                                font-size="200"
                                alignmentBaseline="middle"
                                textAnchor="middle"
                                
                                
                            >{senderInfo.senderInitials}</text>
                          </svg>  : senderInfo.senderInitials}</div> : (<img alt="Messge Icon" src={senderInfo.senderImage}/>)}
                </div>
            );
        }
    }
}