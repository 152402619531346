const DisplayNameSetForm = (props) => {
    return (
        <form className="display_name meeting_room_form" onSubmit={props.handleDisplayNameSave}>
            {props.company.logo && <img className="meeting_room__display_name_logo" src={props.company.logo} alt="Company logo"/>}
            {props.chatbot && props.chatbot.settings.meeting_room_writeup && <div className="meeting_room__content_box__body">{props.chatbot.settings.meeting_room_writeup}</div>}
            <div className="meeting_room__content_box__sub_title mt-xl">Set your display name</div>
            <div className="meeting_room__content_box__body">This is the name that will appear in the meeting</div>
            <input onChange={props.handleDisplayNameChange} type="text" placeholder="Enter your display name" value={props.displayName}/>
            <button disabled={props.displayName.length === 0}>Set display name</button>
        </form>
    );
}

export default DisplayNameSetForm;