import React from 'react';
import { toWordDate } from '../../services/dates';
import QueueBadge from '../Base/QueueBadge';
import ChatActions from './ChatActions';
import { HiOutlineChevronLeft } from 'react-icons/hi2';

export default class ChatTopBar extends React.Component{
    render(){
        const currentConversation = this.props.currentConversation;
        let userName = currentConversation.conversation_id
        if(currentConversation.user && currentConversation.user.user_info && currentConversation.user.user_info.name){
            userName = currentConversation.user.user_info.name;
        }

        return (
            <div className="chat_top_bar">                    
                <div className="chat_top_bar_back" onClick={this.props.handleChatBackClick}><HiOutlineChevronLeft/></div>
                <div className="chat_top_bar_details">
                    <div className="chat_top_bar__username"><div className="name text-truncate">{userName}</div><QueueBadge queue={currentConversation.queue}/></div>
                    <div className="chat_top_bar__created">Date Created: {toWordDate(currentConversation.createdAt)}</div>
                </div>
                <ChatActions 
                    handleMobileSidebarTrigger={this.props.handleMobileSidebarTrigger} 
                    currentConversation={this.props.currentConversation}                    
                    setCurrentConversation={this.props.setCurrentConversation}
                    currentMessages={this.props.currentMessages}
                    setCurrentMessages={this.props.setCurrentMessages}
                />
            </div>
        );
    }
}