import moment from 'moment';
import React from 'react';
import EventsWidgetItem from './EventsWidgetItem';

export default class EventsWidget extends React.Component{
    render(){
        const events = this.props.events.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
        return (
            <div className="events_widget">
                {events.map(eventItem => (
                    <EventsWidgetItem key={eventItem._id} event={eventItem}/>
                ))}
            </div>
        )
    }
}