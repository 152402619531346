import AgentProfileAvatar from "./Agents/AgentProfileAvatar";
import { toTimestamp } from '../services/dates';
import React from "react";
import { HiOutlineChevronDown, HiOutlineChevronUp } from 'react-icons/hi2';
import AgentName from "./Agents/AgentName";
import moment from "moment";

class AgentHistoryItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            dropdownExpanded: false
        };

        this.handleDropdownClick = this.handleDropdownClick.bind(this);
    }

    handleDropdownClick(){
        this.setState((prevState) => {
            return {
                dropdownExpanded: !prevState.dropdownExpanded
            };
        });
    }

    render(){
        let combinedAgentHistory = this.props.combinedAgentHistory;
        const agent = combinedAgentHistory ? combinedAgentHistory[0].agent : this.props.agent;
        if(this.props.combinedAgentHistory){
            combinedAgentHistory = this.props.combinedAgentHistory.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
        }
        
        return (
            <div className="agent_history_item">
                <div className="agent_history_item_inner">
                    <AgentProfileAvatar size="50" agent={agent}/>
                    <div className="agent_history_item__details">
                        <div className="agent_history_item__details__name"><AgentName agent={agent}/></div>
                        <div className="agent_history_item__details__status timestamp">{this.props.connected ? <div className="agent_history_item__connected">Connected</div> : (combinedAgentHistory && toTimestamp(combinedAgentHistory[0].createdAt))}</div>
                    </div>
                    {combinedAgentHistory && combinedAgentHistory.length > 1 && <div className="agent_history_item__expand" onClick={this.handleDropdownClick}>{this.state.dropdownExpanded ? <HiOutlineChevronUp/> : <HiOutlineChevronDown/>}</div>}
                </div>
                {combinedAgentHistory && combinedAgentHistory.length > 1 && 
                    this.state.dropdownExpanded && (
                        <div className="agent_history_item_dropdown">
                            {combinedAgentHistory.map((agentHistory) => {
                                return <div key={agentHistory._id}>
                                    <div className="agent_history_item_inner">
                                        <div style={{ width: '66px' }}/>
                                        <div className="agent_history_item__details">
                                            <div className="agent_history_item__details__status timestamp">{agentHistory.agent.connected ? <div className="agent_history_item__connected">Connected</div> : toTimestamp(agentHistory.createdAt)}</div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    )
                }
            </div>
        );
    }
};

export default AgentHistoryItem;