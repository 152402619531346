import AlertBox from '../AlertBox';
import NotificationsActions from './NotificationsActions';
import React from 'react';
import { connect } from 'react-redux';
import IncomingMeetingContainer from '../Meetings/IncomingMeetingContainer';

class NotificationsContainer extends React.Component {
    render(){
        const notifications = this.props.notifications.notifications;
        return (
            <div className="notifications_top_container">
                {( this.props.meetings.meetingsWaiting.length > 0) && <IncomingMeetingContainer/>}
                <div className="notifications_container">
                        <NotificationsActions/>
                        {notifications.length > 0 ? (
                            <div className="notifications_container__inner">
                                {notifications.map((notification) => { return notification; })}
                            </div>
                        ) : <AlertBox type="alert-clear">No notifications</AlertBox>
                    }
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(NotificationsContainer);