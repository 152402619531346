import React from 'react';
import { toTimestamp } from '../../services/dates';
import { HiCheck } from 'react-icons/hi2';
import Preloader from '../Preloader';
import TextArea from 'react-textarea-autosize';
import onClickOutside from "react-onclickoutside";

class RejectedQuestionItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            approving: false,
            editing: false,
            saving: false,
            editedQuestion: ''
        };

        this.handleApproveQuestion = this.handleApproveQuestion.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleEditToggle = this.handleEditToggle.bind(this);
        this.handleEditSave= this.handleEditSave.bind(this);
    }

    async handleApproveQuestion(){
        this.setState({ approving: true })
        await this.props.handleApproveQuestion(this.props.question._id);
        this.setState({ approving: false })
    }

    handleEditToggle(){
        this.setState(prevState => ({ editing: !prevState.editing }))
        this.setState({ editedQuestion: this.props.question.message_data.text })
    }

    handleEditChange(e){
        this.setState({ editedQuestion: e.target.value });
    }

    async handleEditSave(){
        this.setState({ saving: true });
        await this.props.handleEditQuestion(this.props.question, this.state.editedQuestion);
        this.setState({ editing: false, saving: false });
    }

    handleClickOutside = evt => {
        if(this.state.editing){
            this.handleEditToggle()
        }
    };

    render(){
        return (
            <div className="unanswered_question">
                <div className="pending_question__details">
                    <div className="unanswered_question__question">{this.state.editing ? (
                        !this.state.saving ? (
                            <div className="unanswered_question__form">
                                <TextArea placeholder="Enter unanswered question." value={this.state.editedQuestion} onChange={this.handleEditChange}/>
                                <div className="unanswered_question__form__actions">
                                    <button className="btn" disabled={this.state.editedQuestion.trim().length === 0} onClick={this.handleEditSave}>Save</button>
                                    <button className="btn" onClick={this.handleEditToggle}>Cancel</button>
                                </div>
                            </div>
                        ) : <div className="text_preloader fit">{this.state.editedQuestion}</div>
                        ): <div onClick={this.handleEditToggle}>{this.props.question.message_data.text}</div>}</div>
                    <div className="timestamp">{toTimestamp(this.props.question.createdAt)}</div>
                </div>
                <div className="pending_question__actions">                    
                    {!this.state.approving ? <div className="pending_question__action icon_only" onClick={this.handleApproveQuestion}><HiCheck/></div> : <div className="pending_question__action"><Preloader padding="0" color="white" size="13"/></div>}
                </div>
            </div>
        );
    }
};

export default onClickOutside(RejectedQuestionItem);