import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import { titleCase } from '../../services/misc';
import AgentProfileAvatar from './AgentProfileAvatar';
import Toggle from 'react-toggle';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {  SingleDatePicker } from 'react-dates';
import moment from 'moment';
import validator from 'validator';
import AlertBox from '../AlertBox';
import { isMobile } from '../../services/responsive';

class EditAgentModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agent: this.props.agent,
            queues: this.props.agent._id ? this.props.getAgentQueues(this.props.agent) : [],
            expiryDateOpen: false,
            errorMessage: this.props.errorMessage,
            isValid: true
        }

        this.allFieldsFilled = this.allFieldsFilled.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.handleQueueChange = this.handleQueueChange.bind(this);
        this.handleAgentSave = this.handleAgentSave.bind(this);
        this.handleDisabled = this.handleDisabled.bind(this);
        this.handleExpiryDateTrigger = this.handleExpiryDateTrigger.bind(this);
        this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
        this.handleTimedAgentChange = this.handleTimedAgentChange.bind(this);
        this.handleTimeAllowedChange = this.handleTimeAllowedChange.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.agent !== this.props.agent){
            this.setState({ agent: this.props.agent })
        }

        if(prevProps.errorMessage !== this.props.errorMessage){
            this.setState({ errorMessage: this.props.errorMessage })
        }
    }

    async handleAgentSave(){
        this.props.handleAgentSave(this.state.agent, this.state.queues)
    }

    allFieldsFilled(){
        let allFill = true;
        if(this.state.agent.first_name === '' || !this.state.agent.first_name){ allFill = false }
        if(this.state.agent.last_name === '' || !this.state.agent.last_name){ allFill = false }
        if(this.state.agent.email === '' || !this.state.agent.email){ allFill = false }
        if(this.state.agent.role === '' || !this.state.agent.role){ allFill = false }

        return allFill;
    }

    handleFieldChange(e){
        this.setState(prevState => ({ isValid: true, errorMessage: '', agent: { ...prevState.agent, [e.target.name]: e.target.value } }), () => {
            if(this.state.agent.first_name.trim().length < 2 || this.state.agent.last_name.trim().length < 2){
                return this.setState({ errorMessage: 'First and last name must contain at least 2 characters', isValid: false })
            }

            if(this.state.agent.first_name.trim().length < 2 || this.state.agent.last_name.trim().length < 2){
                return this.setState({ errorMessage: 'First and last name must contain at least 2 characters', isValid: false })
            }

            if(!validator.isEmail(this.state.agent.email)){
                return this.setState({ errorMessage: 'Must contain a valid email', isValid: false })
            }
        });
    }

    handleTimedAgentChange(e){
        this.setState(prevState => ({ agent: { ...prevState.agent, agent_settings: { ...prevState.agent.agent_settings, timed_agent: { ...prevState.agent.agent_settings.timed_agent, enabled: !prevState.agent.agent_settings.timed_agent.enabled } }}}))
    }

    handleTimeAllowedChange(e){
        this.setState(prevState => ({ agent: { ...prevState.agent, agent_settings: { ...prevState.agent.agent_settings, timed_agent: { ...prevState.agent.agent_settings.timed_agent, allowed_time: e.target.value } }}}))
    }

    handleRoleChange(e){
        this.setState(prevState => ({ agent: { ...prevState.agent, role: e.value } }))
    }

    handleQueueChange(e){
        this.setState({ queues: e.map(element => element.value ) })
    }

    handleDisabled(){
        this.setState(prevState => ({ agent: { ...prevState.agent, disabled: !prevState.agent.disabled } }))
    }

    handleExpiryDateTrigger(){
        this.setState(prevState => ({ expiryDateOpen: !prevState.expiryDateOpen }))
    }

    handleExpiryDateChange(e){
        this.setState({ agent: { ...this.state.agent, expiry_date: e } })
    }

    render(){
        const roleOptions = [{ value: 'agent', label: 'Agent' }, { value: 'manager', label: 'Manager' }];
        if(this.props.auth.agent.agent.role === 'admin'){
            roleOptions.push({value: 'admin', label: 'Admin'})
        }
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="action_modal modal__preloader">
                <div className="modal__title">{!this.state.agent._id ? 'New' : 'Edit'} Agent
                <AgentProfileAvatar size="60" agent={this.state.agent}/></div>
                    <form onSubmit={this.handleAgentSave}>
                        <div className="form-group__split">
                        <div className="form-group">
                            <label>First Name</label>
                            <input type="text" name="first_name" onChange={this.handleFieldChange} value={this.state.agent.first_name} placeholder="Enter agent first name"/>
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input type="text" name="last_name" onChange={this.handleFieldChange} value={this.state.agent.last_name} placeholder="Enter agent last name"/>
                        </div>
                    </div>
                    <div className="form-group__split">
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" name="email" onChange={this.handleFieldChange} value={this.state.agent.email} placeholder="Enter agent email"/>
                        </div>
                        <div className="form-group">
                            <label>Role</label>
                            <Select classNamePrefix="custom_select"  className="form_select" onChange={this.handleRoleChange} name="role" options={roleOptions} value={this.state.agent._id ? { value: this.state.agent.role, label: titleCase(this.state.agent.role) } : undefined}/>
                        </div>
                    </div>
                    {!this.props.noCompany && this.props.queues && (
                        <div className="form-group">
                            <label>Queues</label>
                            <Select classNamePrefix="custom_select"  className="form_select" onChange={this.handleQueueChange} value={this.state.queues.map(queue => ({ value: queue, label: queue.name }))} options={this.props.queues.map(queue => ({ value: queue, label: queue.name }))} isMulti/>
                        </div>
                    )}
                    {this.props.auth.agent.agent.role === 'admin' && (
                        <div>
                            <div className="form-group__split">
                                <div className="form-group">
                                    <label>Timed Agent</label>
                                    <Toggle checked={(this.state.agent.agent_settings && this.state.agent.agent_settings.timed_agent.enabled) ? true : false} name="timed_agent" onChange={this.handleTimedAgentChange}/>
                                    {this.state.agent.agent_settings.timed_agent.enabled && (
                                        <div>
                                            <label>Allowed Time (Hours)</label>
                                            <input type="number" name="timed_agent.allowed_time" onChange={this.handleTimeAllowedChange} value={this.state.agent.agent_settings.timed_agent.allowed_time} placeholder="Enter agent allowed time"/>
                                        </div>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label>Disabled</label>
                                    <Toggle checked={this.state.agent.disabled} name="disabled" onChange={this.handleDisabled}/>
                                </div>
                            </div>
                            <div className="form-group__split">
                                <div className="form-group">
                                    <label>Expiry Date</label>
                                    <SingleDatePicker 
                                        date={this.state.agent.expiry_date ? moment(this.state.agent.expiry_date) : undefined}
                                        onDateChange={this.handleExpiryDateChange}
                                        focused={this.state.expiryDateOpen}
                                        onFocusChange={this.handleExpiryDateTrigger}
                                        id="test"
                                        noBorder
                                        openDirection="up"
                                        numberOfMonths={1}
                                        small
                                        showClearDate
                                        orientation={isMobile() ? 'vertical' : 'horizontal'}
                                        withFullScreenPortal={isMobile() ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {!this.state.agent._id && <AlertBox className="mb-medium">Please note that adding this agent will result in an additional charge.</AlertBox>}
                    {this.state.errorMessage && this.state.errorMessage !== '' && <AlertBox type="alert-danger" className="mb-medium">{this.state.errorMessage}</AlertBox>}
                    <div className="modal__actions">
                        <div className="btn" onClick={(e) => { this.props.onRequestClose() }}>Cancel</div>
                        <button className="btn" disabled={!this.state.isValid || (this.allFieldsFilled() ? false : true)}>Save</button>
                    </div>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditAgentModal);