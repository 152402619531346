import React from 'react';
import { connect } from 'react-redux';
import { agentActions } from '../../actions/agents';

class AdminMenu extends React.Component{
    constructor(props){
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
        this.props.dispatch(agentActions.logout());
    }

    render(){
        return (
            <div className="admin_menu">
                <img className="admin_menu__logo" src="/images/logo.svg" alt="Verge AI Logo"/>
                <h2>Admin Dashboard</h2>
                <div className="admin_menu_inner">
                    <button className="admin_menu__item logout" onClick={this.handleLogout}>Logout</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AdminMenu);