import React from 'react';
import { connect } from 'react-redux';
import AgentManager from '../Agents/AgentManager';

class AdminAgents extends React.Component{
    render(){
        return (
            <div className="admin_settings_widget">
                <h3>Agents</h3>
                <p>Edit agent information.</p>
                <AgentManager noCompany={true}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(AdminAgents);