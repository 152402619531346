import { connect } from 'react-redux';
import React from 'react';
import { Redirect } from 'react-router-dom';
import SetPasswordForm from './SetPasswordForm';

class SetPasswordPage extends React.Component {
    render(){
        return (
            <div className="login_page">
                {!this.props.auth.agent && <Redirect to="/"/>}
                <div className="login_page__container">
                    <div className="container">
                        <div className="col-6">
                            <img alt="Set Password" className="login_page__image" src="/images/password_set_page.svg"/>
                        </div>
                        <div className="col-6">
                            <img className="login_page__logo" src="/images/logo_full.webp" alt="Verge AI Logo"/>
                            <SetPasswordForm/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(SetPasswordPage);