const initialState = {
    meetingsWaiting: [],
    currentMeeting: undefined
}

export default function meetings(state = initialState, action){
    switch(action.type){
        case 'SET_MEETINGS':
            return {
                ...state,
                ...action.meetings
            }
        case 'MEETINGS_CLEAR':
            return initialState;
        default: 
            return state;
    }
}