import React from 'react';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import { GiSpeakerOff, GiSpeaker } from 'react-icons/gi';
import { agentService } from '../../services/agents';

class NotificationSoundToggle extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            muted: this.props.auth.agent.agent.agent_settings.notifications_muted
        }
        this.handleNotificationMute = this.handleNotificationMute.bind(this);
    }
    handleNotificationMute(){
        this.setState(prevState => ({ muted: !prevState.muted}), () => {
            this.props.dispatch({
                type: 'SET_CURRENT_AGENT_SET',
                agent: {
                    ...this.props.auth.agent.agent,
                    agent_settings: {
                        ...this.props.auth.agent.agent.agent_settings,
                        notifications_muted: this.state.muted
                    }
                }
            });
            agentService.setMuted(this.props.auth.agent.token, this.props.auth.agent.agent._id, this.state.muted)
        })
    }
    render(){
        return (
            <Toggle className="toggle_custom" onChange={this.handleNotificationMute} checked={!this.state.muted} icons={{
                checked: <GiSpeaker/>,
                unchecked: <GiSpeakerOff/>
            }}/>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(NotificationSoundToggle);