import React from "react";
import Modal from 'react-modal';

class ChatFlowResponse extends React.Component{
    render(){
        const flowData = this.props.flowResponse.Data;
        delete flowData["flow_reply_to"];
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal_flow_response modal modal__preloader" onRequestClose={this.props.onRequestClose}>
                <div className="modal__title">{this.props.title}</div>
                <div className="modal__content">
                    <div className="modal_flow_response__response">
                        <div className="modal_flow_response__response_label">JSON Response</div>
                        <pre>{JSON.stringify(this.props.flowResponse.Data, null, 2)}</pre>
                    </div>
                </div>
                <div className="modal__actions">
                    <button className="btn" onClick={this.props.onRequestClose}>Close</button>
                </div>
            </Modal>
        )
    }
}

export default ChatFlowResponse;