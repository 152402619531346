import React from 'react';
import { connect } from 'react-redux';
import { getTags, createTag } from '../../services/tags';
import { addTag, updateTags } from '../../services/conversation';
import Creatable from 'react-select/creatable';

class TagWidget extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            chat: this.props.chat,
            tagOptions: [],
            loadingTagOptions: true,
            disabled: false
        }

        this.createNewTag = this.createNewTag.bind(this);
        this.addTagToChat = this.addTagToChat.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.chat && (prevProps.chat.tags !== this.props.chat.tags)){
            this.setState({ chat: this.props.chat })
        }
    }

    async componentDidMount(){
        const tags = await getTags(this.props.auth.selectedChatbot.token);
        this.setState({ tagOptions: tags, loadingTagOptions: false });
    }

    async createNewTag(label){
        this.setState({ loadingTagOptions: true });
        const newTag = { label };
        const addedTag = await createTag(this.props.auth.selectedChatbot.token, newTag);
        const tags = await getTags(this.props.auth.selectedChatbot.token);
        this.setState({ tagOptions: tags, loadingTagOptions: false });
        await addTag(this.props.auth.selectedChatbot.token, this.state.chat._id, addedTag._id)
        
        this.setState({ loadingTagOptions: false });
    }

    async addTagToChat(e){   
        const tags = e.map(tagItem => tagItem.value);
        
        this.setState((prevProps) => ({ loadingTagOptions: true, disabled: true, chat: {
            ...prevProps.chat,
            tags: e.map(tagItem => ({ _id: tagItem.value, label: tagItem.label }))
        } }))
        await updateTags(this.props.auth.selectedChatbot.token, this.state.chat._id, tags)
        this.setState({ disabled: false, loadingTagOptions: false });
    }

    render(){
        return (
            <div className="tag_widget">
                <Creatable classNamePrefix='custom_select' className="form_select" isDisabled={this.state.disabled} onCreateOption={this.createNewTag} isLoading={this.state.loadingTagOptions} onChange={this.addTagToChat} value={this.state.chat.tags.map(tag => ({ value: tag._id, label: tag.label }))} options={this.state.tagOptions.map(tag => ({ value: tag._id, label: tag.label }))} isMulti/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(TagWidget);