import React from 'react';
import Preloader from '../Preloader';

export default class EditAgentForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agent: this.props.agent
        }
        
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        this.setState((prevState) => ({
            ...prevState,
            agent: {
                ...prevState.agent,
                [e.target.name]: e.target.value
            } 
        }));
    }

    render(){
        return (
            <form onSubmit={(e) => {this.props.onSubmit(e, this.state.agent)}} method="POST">
                <div className="form-group__split">
                    <div className="form-group">
                        <label htmlFor="email">First Name</label>
                        <input disabled={this.props.submiting} type="text" name="first_name" value={this.state.agent.first_name} onChange={this.handleChange} placeholder="Enter your first name" required/>
                        {this.state.submitted && !this.state.agent.first_name && <div className="help-block">First name is required</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Last Name</label>
                        <input disabled={this.props.submiting} type="text" name="last_name" value={this.state.agent.last_name} onChange={this.handleChange} placeholder="Enter your last name" required/>
                        {this.state.submitted && !this.state.agent.last_name && <div className="help-block">Last name is required</div>}
                    </div>
                </div>
                <div className="form-group__split">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" value={this.state.agent.email} placeholder="Enter your email" disabled/>
                        {this.state.submitted && !this.state.agent.email && <div className="help-block">Email is required</div>}
                    </div>
                </div>
                <button className="btn" disabled={this.props.submiting}>{!this.props.submiting ? 'Save' : <Preloader color="white" size="14px" padding="0px 1rem"/>}</button>
            </form>
        );
    }
}