import React from 'react';
import { connect } from 'react-redux';
import UserInfoItem from './UserInfoItem';
import { HiEyeSlash, HiEye, HiOutlineArrowUpTray, HiOutlinePaperAirplane } from 'react-icons/hi2';
import ChatTransfer from './ChatTransfer';
import { newChatHandle, createdMessage, createNotification } from '../../services/internalChats';
import { exportLeadInfo } from '../../services/user';

class UserInfoList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            shareAgentListOpen: false,
            sharing: false,
            exporting: false,
            maxItems: this.props.maxItems,
            showAllItems: false
        }

        this.handleShare = this.handleShare.bind(this);
        this.handleShareToggle = this.handleShareToggle.bind(this);
        this.handleExport = this.handleExport.bind(this);
        this.toggleShowAll = this.toggleShowAll.bind(this);
    }

    handleShareToggle(){
        this.setState(prevState => ({ shareAgentListOpen: !prevState.shareAgentListOpen }))
    }

    async handleShare(id){
        const internalChat = await newChatHandle(this.props.auth.agent.agent._id, id, this.props.auth.selectedChatbot.token);
        const message = await createdMessage(internalChat._id, {
            sender: this.props.auth.agent.agent._id,
            message_data: {
                meta: {
                    type: 'conversation_card',
                    id: this.props.conversation.conversation_id
                }
            }
        }, this.props.auth.selectedChatbot.token);
        
        const currentAgent = this.props.auth.agent.agent;
        const chattingTo = internalChat.participants.filter(participant => participant._id !== currentAgent._id);
        const notificationBody = chattingTo.map(participant => ({ agent: participant._id, message: message._id }));
        createNotification(internalChat._id, notificationBody, this.props.auth.selectedChatbot.token);

        await this.props.dispatch({
            type: 'SET_SELECTED_CHAT',
            selectedChat: internalChat
        });
        await this.props.dispatch({
            type: 'SET_CHAT_WIDGET_EXPANDED',
            chatWidgetExpanded: true
        });
    }

    async handleExport(){
        this.setState({ exporting: true });
        const response = await exportLeadInfo(this.props.auth.selectedChatbot.token, this.props.conversation.user._id, this.state.filters);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `Leads.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        window.URL.revokeObjectURL(blob);
        this.setState({ exporting: false });
    }

    toggleShowAll(){
        this.setState(prevState => ({ showAllItems: !prevState.showAllItems }))
    }

    render(){
        const user = this.props.conversation.user;
        let userInfo = this.props.conversation.user && this.props.conversation.user.user_info ? Object.keys(this.props.conversation.user.user_info).map((key) => ({ key, value: user.user_info[key] })) : null;
        if(userInfo){
            userInfo = userInfo.sort(function(x,y){ return x.key === "phone_number" ? -1 : y.key === "phone_number" ? 1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return x.key === "email" ? -1 : y.key === "email" ? 1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return x.key === "name" ? -1 : y.key === "name" ? 1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return typeof x.value === "object" ? 1 : typeof y.value === "object" ? -1 : 0; });
            userInfo = userInfo.sort(function(x,y){ return (x.value && typeof x.value === "object" && x.value.type) ? -1 : ((y.value && typeof y.value === "object") && y.value.type) ? 1 : 0; });
        }

        let itemsToShow = userInfo;
        if(this.state.maxItems && userInfo){
            if(!this.state.showAllItems){
                itemsToShow = userInfo.slice(0, this.state.maxItems);
            }
        }

        return (
            <div className="user_info_list">
                {user && user.user_info ? itemsToShow.map((item) => {
                    return <UserInfoItem user={this.props.conversation.user} key={item.key} keyName={item.key} value={user.user_info[item.key]}/>
                }) : <div className="alert_box">No information on user</div>}
                {(this.state.maxItems && userInfo && this.state.maxItems < userInfo.length) && (this.state.showAllItems ? <button className="btn mt-medium" onClick={this.toggleShowAll}><HiEyeSlash/>Hide all info</button> : <button className="btn mt-medium" onClick={this.toggleShowAll}><HiEye/>Show all info</button>)}
                {this.props.showActions && (
                    <div className="user_info_list__actions">
                        <button className="btn" onClick={this.handleShareToggle}><HiOutlinePaperAirplane/>Share Chat</button>
                        {(userInfo && userInfo.length > 0) && <button className="btn" disabled={this.state.exporting} onClick={this.handleExport}><HiOutlineArrowUpTray/>{this.state.exporting ? 'Exporting...' : 'Export User'}</button>}
                    </div>
                )}              
                {this.state.shareAgentListOpen && <ChatTransfer allowOffline={true} agentPicker={true} title="Share chat with agent" actionText="Sharing" onSelect={this.handleShare} handleTransferToggle={this.handleShareToggle} conversation={this.props.conversation}/>}
            </div>
            
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(UserInfoList);