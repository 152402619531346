import React from 'react';
import ChatChannelIcon from "../Chats/ChatChannelIcon";
import { connect } from 'react-redux';
import { toTimestamp, timerRenderer } from '../../services/dates';
import { declineChat } from '../../services/humanHandoff';
import Preloader from '../Preloader';
import QueueBadge from '../Base/QueueBadge';
import ChatTransfer from '../Chats/ChatTransfer';
import moment from 'moment';
import { RiTimerLine } from 'react-icons/ri';
import { HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2';
import { goToConversation } from '../../actions/chats';
import { acceptHumanHandoff } from '../../actions/humanHandoff';
import Countdown from 'react-countdown';
import { Tooltip } from '@material-ui/core';

class HumanHandoffNotifcation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            performingAction: undefined,
            showingTransfer: false,
            viewingChat: false
        };

        this.handleAcceptClick = this.handleAcceptClick.bind(this);
        this.handleDeclineClick = this.handleDeclineClick.bind(this);
        this.handleToggleChat = this.handleToggleChat.bind(this);
        this.handleViewChatClick = this.handleViewChatClick.bind(this);
    }

    async handleAcceptClick(){
        this.setState({
            performingAction: "Joining chat..."
        })

        acceptHumanHandoff(this.props, this.props.humanHandoff)
    }

    handleDeclineClick(){
        declineChat(this.props.auth.selectedChatbot.token, this.props.humanHandoff.conversation.conversation_id, this.props.auth.agent.agent, this.props.humanHandoff, this.props.humanHandoff.conversation.channel);
        this.setState({
            performingAction: "Declining chat..."
        })
    }

    handleToggleChat(){
        this.setState(prevState => ({showingTransfer: !prevState.showingTransfer  }))
    }

    async handleViewChatClick(){
        this.setState({ viewingChat: true });
        await goToConversation(this.props, this.props.humanHandoff.conversation.conversation_id);
        this.setState({ viewingChat: false });
    }

    render(){
        const humanHandoff = this.props.humanHandoff;
        const conversation = humanHandoff.conversation;
        return (
            <div className="human_handoff_notification">
                {this.state.performingAction && <div className="human_handoff_notification__overlay">
                    <Preloader text={this.state.performingAction}/>
                </div>}
                <div className="human_handoff_notification__topbar">
                    <div className="human_handoff_notification__title">Live Chat Request</div>
                    <div className="queue_badge timer"><RiTimerLine/><Countdown date={moment(this.props.humanHandoff.updatedAt).add(this.props.auth.selectedChatbot.settings.bot_waiting_time, 'minutes').toDate()} renderer={timerRenderer}/></div>
                </div>
                <div className="human_handoff_notification__info">
                    <ChatChannelIcon channel={conversation.channel}/>
                    <div className="human_handoff_notification__info__user">
                        <div className="human_handoff_notification__info__queue"><QueueBadge queue={conversation.queue}/></div>
                        <div className="human_handoff_notification__info__user__name text-truncate">{conversation.user && conversation.user.user_info && conversation.user.user_info.name ? conversation.user.user_info.name : conversation.conversation_id}</div>   
                        <div className="timestamp">{toTimestamp(humanHandoff.createdAt)}</div>
                    </div>            
                    {!this.state.viewingChat ? <Tooltip title="View Chat" arrow><div className="human_handoff_notification__view_chat" onClick={this.handleViewChatClick}><HiOutlineChatBubbleLeftEllipsis/></div></Tooltip> : <Preloader size="23"/>}        
                </div>
                <div className="human_handoff_actions">
                    <div className="human_handoff_action accept" onClick={this.handleAcceptClick}>Accept</div>
                    <div className="human_handoff_action transfer" onClick={this.handleToggleChat}>Transfer</div>
                    <div className="human_handoff_action decline" onClick={this.handleDeclineClick}>Decline</div>
                </div>
                {this.state.showingTransfer && <ChatTransfer handleTransferToggle={this.handleToggleChat} conversation={conversation}/>}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(HumanHandoffNotifcation);