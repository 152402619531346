import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import HelpRequestList from './HelpRequestList';
import { history } from "../../helpers/history";
import { goToConversation } from '../../actions/chats';
import { sendMessage } from '../../services/humanHandoff';
import { resolveHelpRequest, setHelpRequests, deleteHelpRequest, getHelpRequest, transferHelpRequest } from '../../services/helpRequest';
import HelpRequestFilters from './HelpRequestFilters';
import { getQueues } from '../../services/queues';
import HelpRequestListItem from './HelpRequestListItem';
import { NavLink } from 'react-router-dom';
import { HiEyeSlash, HiOutlineArrowLeft, HiOutlineFunnel } from 'react-icons/hi2';

import socket from '../../services/socket';

class HelpRequestPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loadingMore: false,
            queues: undefined,
            loadingQueues: true,
            selectedHelpRequest: undefined,
            mobileFiltersShown: false
        };

        this.handleViewChatClick = this.handleViewChatClick.bind(this);
        this.handleSendQuickReply = this.handleSendQuickReply.bind(this);
        this.handleResolveHelpRequest = this.handleResolveHelpRequest.bind(this);
        this.handleDeleteHelpRequest = this.handleDeleteHelpRequest.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.handleHelpRequestTransfer = this.handleHelpRequestTransfer.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.loadMoreClick = this.loadMoreClick.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.handleFiltersTrigger = this.handleFiltersTrigger.bind(this);
    }

    async componentDidMount(){
        if(this.props.match.params.helpRequestId){
            try{
                const helpRequest = await getHelpRequest(this.props.auth.selectedChatbot.token, this.props.match.params.helpRequestId)
                this.setState({ selectedHelpRequest: helpRequest })
            }catch(e){
                history.push('/help-requests');
            }
        }

        const queues = await getQueues(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        this.setState({ queues, loadingQueues: false })
        socket.on('helpRequestUpdate', async ({ id }) => {
            if(this.props.auth.selectedChatbot._id === id && this.state.selectedHelpRequest){
                
                setHelpRequests(this.props);
                try{
                    const helpRequest = await getHelpRequest(this.props.auth.selectedChatbot.token, this.state.selectedHelpRequest._id)
                    this.setState({ selectedHelpRequest: helpRequest })
                }catch(e){
                    history.push('/help-requests');
                }
            }
        });     
        
        setHelpRequests(this.props);
    }

    componentWillUnmount(){
        socket.off('helpRequestUpdate');
    }
    
    async handleViewChatClick(conversation){
        const conversationId = conversation.conversation_id;
        await goToConversation(this.props, conversationId);
    }
    
    async handleResolveHelpRequest(helpRequestId, message = null){
        await resolveHelpRequest(this.props.auth.selectedChatbot.token, helpRequestId, this.props.auth.agent.agent._id, message);
        await setHelpRequests(this.props);
    }

    async handleDeleteHelpRequest(helpRequestId){
        await deleteHelpRequest(this.props.auth.selectedChatbot.token, helpRequestId);
        await setHelpRequests(this.props);
    }

    async handleSendQuickReply(helpRequestId, message, conversation){
        const messageObject = {
            conversation_id: conversation.conversation_id,
            sender: 'agent',
            agent_sender: this.props.auth.agent.agent._id,
            agent_sender_object: this.props.auth.agent.agent,
            message_data: message
        }

        await sendMessage(this.props.auth.selectedChatbot.token, { ...messageObject, message_data: { ...messageObject.message_data, text: `*Submitted Question Response*\n\n_${this.props.auth.selectedChatbot.settings.anonymous_quick_resolve ? (this.props.auth.selectedChatbot.settings.anonymous_quick_resolve_alias != null && this.props.auth.selectedChatbot.settings.anonymous_quick_resolve_alias !== ""  ? this.props.auth.selectedChatbot.settings.anonymous_quick_resolve_alias : "An agent") : this.props.auth.agent.agent.first_name} has replied to your question saying:_\n\n` + messageObject.message_data.text + '\n\n_Please note you cannot directly reply to this message. Type *HELP* if you wish to speak to an agent._'} }, conversation.channel);
        await this.handleResolveHelpRequest(helpRequestId, message);
        await setHelpRequests(this.props);
    }

    handleSearchChange(e){
        this.props.dispatch({
            type: 'HELP_REQUESTS_SEARCH_TERM_UPDATE',
            searchTerm: e.target.value
        });
    }

    async handleSearchSubmit(e){
        e.preventDefault();
        this.props.dispatch({
            type: 'LOADING_HELP_REQUESTS',
            loadingHelpRequests: true
        });

        await setHelpRequests(this.props);
    }

    async clearSearch(){
        await this.props.dispatch({
            type: 'LOADING_HELP_REQUESTS',
            loadingHelpRequests: true
        });
        await this.props.dispatch({
            type: 'HELP_REQUESTS_SEARCH_TERM_UPDATE',
            searchTerm: ''
        });
        await setHelpRequests(this.props);
    }

    async loadMoreClick(){
        this.setState({ loadingMore: true });
        await this.props.dispatch({
            type: 'SET_HELP_REQUESTS_LIMIT',
            limit: this.props.helpRequests.limit + this.props.helpRequests.offset
        });
        await setHelpRequests(this.props);
        this.setState({ loadingMore: false });
    }

    async filterChange(e, name){
        await this.props.dispatch({
            type: 'LOADING_HELP_REQUESTS',
            loadingHelpRequests: true
        });
        await this.props.dispatch({
            type: 'SET_HELP_REQUEST_FILTERS',
            filter: {
                [name]: e ? e.value : undefined
            }
        });

        await setHelpRequests(this.props);
    }    

    async handleHelpRequestTransfer(helpRequestId, queueId){
        await transferHelpRequest(this.props.auth.selectedChatbot.token, helpRequestId, queueId);
        await setHelpRequests(this.props);
    }

    handleFiltersTrigger(){
        this.setState(prevState => ({ mobileFiltersShown: !prevState.mobileFiltersShown }))
    }

    render(){
        return (
            <div className="container column">
                {this.props.match.params.helpRequestId ? (
                    <PageContainer>
                        <div className="container">
                            <div className="col-12">
                                <div className="help_request_single">
                                    <NavLink to="/help-requests" className="btn"><HiOutlineArrowLeft/>Back to help requests</NavLink>
                                    {this.state.selectedHelpRequest ? <HelpRequestListItem helpRequest={this.state.selectedHelpRequest} handleResolveHelpRequest={this.handleResolveHelpRequest} handleDeleteHelpRequest={this.handleDeleteHelpRequest} handleSendQuickReply={this.handleSendQuickReply} handleViewChatClick={this.handleViewChatClick} helpRequests={this.props.helpRequests.helpRequests} handleHelpRequestTransfer={this.handleHelpRequestTransfer}/> : <Preloader/>}
                                </div>
                            </div>
                        </div>
                    </PageContainer>
                ) : (
                    <PageContainer>
                        <div className="container">
                            <div className="col-12">
                                <div className="page_title">Help Requests</div>
                                <div className="help_request_container">
                                    {this.state.mobileFiltersShown ? <button className="btn mobile_filter_trigger" onClick={this.handleFiltersTrigger}><HiEyeSlash/>Hide Filters</button> : <button className="btn mobile_filter_trigger" onClick={this.handleFiltersTrigger}><HiOutlineFunnel/>Filters</button> }
                                    <div className={`help_request_top_bar ${this.state.mobileFiltersShown ? 'mobile_filters_shown' : ''}`}>  
                                        <HelpRequestFilters loadingQueues={this.state.loadingQueues} queues={this.state.queues} filterChange={this.filterChange} filters={this.props.helpRequests.filters}/>
                                        {/*<HelpRequestSearch searchTerm={this.props.helpRequests.searchTerm} clearSearch={this.clearSearch} isSearched={this.props.helpRequests.isSearched} handleSearchChange={this.handleSearchChange} handleSearchSubmit={this.handleSearchSubmit}/>*/}                                   
                                    </div>
                                    {this.props.helpRequests.loadingHelpRequests === false ? (
                                        <div>
                                            <HelpRequestList loadingMore={this.state.loadingMore} loadMoreClick={this.loadMoreClick} handleHelpRequestTransfer={this.handleHelpRequestTransfer} hasMore={this.props.helpRequests.hasMore} handleResolveHelpRequest={this.handleResolveHelpRequest} handleDeleteHelpRequest={this.handleDeleteHelpRequest} handleMediaClick={this.handleMediaClick} handleSendQuickReply={this.handleSendQuickReply} handleViewChatClick={this.handleViewChatClick} helpRequests={this.props.helpRequests.helpRequests}/>
                                        </div>
                                    ) : (
                                        <Preloader/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </PageContainer>
                )}
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(HelpRequestPage);