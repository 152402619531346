import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import StatusText from '../Base/StatusText';

class CreateGroupChatModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            agents: this.props.agents,
            selectedAgents: []
        };

        this.handleAgentSelect = this.handleAgentSelect.bind(this);
    }

    componentDidUpdate(prevProps){
        if(prevProps.agents !== this.props.agents){
            this.setState({ agents: this.props.agents })
        }
    }

    handleAgentSelect(agent){
        const agentSelectedIndex = this.state.selectedAgents.findIndex(selectedAgent => selectedAgent._id === agent._id);
        if(agentSelectedIndex > -1){
            let selectedAgents = this.state.selectedAgents;
            selectedAgents.splice(agentSelectedIndex, 1)
            this.setState({ selectedAgents })
        }else{
            let selectedAgents = this.state.selectedAgents;
            selectedAgents.push(agent)
            this.setState({ selectedAgents })
        }
    }

    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} className="modal modal__preloader" onRequestClose={this.props.onRequestClose}>
                <div>
                    <div className="modal__title py-medium">Create Group Chat</div>
                    <div className="modal__subtitle">{this.state.selectedAgents.length} agent{this.state.selectedAgents.length !== 1 ? 's' : ''} selected.</div>
                    <div className="internal_create_group_chat_list">
                        {this.state.agents.map(agent => {
                            const agentSelected = this.state.selectedAgents.find(selectedAgent => selectedAgent._id === agent._id);
                            return (
                                <div key={agent._id} className="internal_agent_list_item" onClick={() => { this.handleAgentSelect(agent) }}>
                                    <div className={`company_list__item__checkbox ${agentSelected ? 'selected' : ''}`}></div>
                                    <AgentProfileAvatar size="50" agent={agent}/>
                                    <div className="internal_agent_list_item__details">
                                        <div className="internal_agent_list_item__details__name text_truncate">{agent.first_name} {agent.last_name}</div>
                                        <StatusText status={agent.agent_settings.status}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="modal__actions">
                        <button className="btn" onClick={this.props.onRequestClose}>Cancel</button>
                        <button className="btn" disabled={this.state.selectedAgents.length < 2} onClick={() => { this.props.handleCreateGroupChat(this.state.selectedAgents) }}>Create Group Chat</button>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(CreateGroupChatModal);