import React from 'react';
import { connect } from 'react-redux';
import PageContainer from '../Base/PageContainer';
import Preloader from '../Preloader';
import { getUnansweredQuestions, updateQuestion } from '../../services/unansweredQuestions';
import LoadMore from '../Base/LoadMore';
import { NavLink } from 'react-router-dom';
import PendingQuestionItem from './PendingQuestionItem';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import Select from 'react-select';
import { getQueues } from '../../services/queues';
import { isMobile } from '../../services/responsive';

class PendingQuestionsPage extends React.Component {
    constructor(props){
        super(props);
        
        this.orderByOptions = [{ value: 'desc', label: 'Newest to oldest' }, { value: 'asc', label: 'Oldest to newest' }];
        this.state = {
            loadingPendingQuestions: true,
            queues: undefined,
            loadingQueues: true,
            pendingQuestions: [],
            pendingQuestionsLimit: 50,
            pendingQuestionsOffset: 50,
            hasMore: false,
            loadingMore: false,
            filters: {
                startDate: undefined,
                endDate: undefined,
                orderBy: 'desc',
                validQuestion: null
            },
        };

        this.setPendingQuestions = this.setPendingQuestions.bind(this);
        this.handleLoadMorePendingQuestions = this.handleLoadMorePendingQuestions.bind(this);
        this.handleApproveQuestion = this.handleApproveQuestion.bind(this);
        this.handleRejectQuestion = this.handleRejectQuestion.bind(this);
        this.handleEditQuestion = this.handleEditQuestion.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    async componentDidMount(){
        await this.setPendingQuestions();
        const queues = await getQueues(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);
        this.setState({ queues, loadingQueues: false });
    }

    async setPendingQuestions(){
        const unanswerdQuestions = await getUnansweredQuestions(this.props.auth.selectedChatbot.token, this.state.pendingQuestionsLimit + 1, this.state.filters);

        this.setState({ hasMore: unanswerdQuestions.length > this.state.pendingQuestionsLimit ? true : false });
        if(unanswerdQuestions.length > this.state.pendingQuestionsLimit){
            unanswerdQuestions.pop()
        }

        this.setState({
            pendingQuestions: unanswerdQuestions,
            loadingPendingQuestions: false
        });

        this.setState({
            unanswerdQuestions,
            loadingUnansweredQuestions: false
        });
        
        // const count = await getUnansweredQuestionCount(this.props.auth.selectedChatbot.token);
        // this.setState({ count })
    }

    handleLoadMorePendingQuestions(){
        this.setState(prevState => ({
            pendingQuestionsLimit: prevState.pendingQuestionsLimit + prevState.pendingQuestionsOffset,
            loadingMore: true
        }), () => {
            this.setPendingQuestions().then(() => {
                this.setState({ loadingMore: false });
            })
        });
    }

    async handleApproveQuestion(questionId){
        await updateQuestion(this.props.auth.selectedChatbot.token, questionId, { valid_unanswered_question: true })
        await this.setPendingQuestions();
    }

    async handleRejectQuestion(questionId){
        await updateQuestion(this.props.auth.selectedChatbot.token, questionId, { valid_unanswered_question: false })
        await this.setPendingQuestions();
    }

    async handleEditQuestion(question, newText){
        await updateQuestion(this.props.auth.selectedChatbot.token, question._id, { message_data: { ...question.message_data, text: newText } })
        await this.setPendingQuestions();
    }

    handleFilterChange(fieldName, value){
        this.setState(prevState => ({ loadingPendingQuestions: true, filters: { ...prevState.filters, [fieldName]: value } }), () => {
            this.setPendingQuestions();
        })
    }

    render(){
        return (
            <div className="container column">
                <PageContainer>
                    <div className="container">
                        <div className="col-12">
                            <div className="page_title">Unanswered Questions</div>
                            <div className="unanswered_questions_actions">
                                <NavLink to="/unanswered-questions" className="btn">Approved {this.state.count && <span>({this.state.count.approved_count})</span>}</NavLink>
                                <NavLink to="/unanswered-questions/pending" className="btn selected">Pending {this.state.count && <span>({this.state.count.pending_count})</span>}</NavLink>
                                <NavLink to="/unanswered-questions/rejected" className="btn">Rejected {this.state.count && <span>({this.state.count.rejected_count})</span>}</NavLink>
                            </div>
                            <div className="unanswered_questions_top_bar filters">
                                <div className="filters">
                                    <div className="form-group">
                                        <label>Date Range</label>
                                        <DateRangePicker
                                            isOutsideRange={(day) => day.isAfter(moment())}
                                            noBorder={true}
                                            startDate={this.state.filters.startDate ? moment(this.state.filters.startDate) : undefined} // momentPropTypes.momentObj or null,
                                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                            endDate={this.state.filters.endDate ? moment(this.state.filters.endDate) : undefined} // momentPropTypes.momentObj or null,
                                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                            onDatesChange={({ startDate, endDate }) => { this.handleFilterChange('startDate', startDate ? startDate.format('YYYY-MM-DD') : undefined); this.handleFilterChange('endDate', endDate ? endDate.format('YYYY-MM-DD') + 'T23:59' : undefined); }} // PropTypes.func.isRequired,
                                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired
                                            orientation={isMobile() ? 'vertical' : 'horizontal'}
                                            withFullScreenPortal={isMobile() ? true : false}
                                            initialVisibleMonth={() => moment().subtract(1, 'months')}
                                        />
                                    </div>
                                </div>
                                <div className="filters">
                                    <div className="top_bar_filter">
                                        <label>Sort by</label>
                                        <Select classNamePrefix="custom_select"  onChange={(e) => { this.handleFilterChange('orderBy', e.value) }} className="filter form_select" value={this.orderByOptions.find(orderOption => orderOption.value === this.state.filters.orderBy)} options={this.orderByOptions}/>
                                    </div>
                                    <div className="top_bar_filter">
                                        <label>Queue</label>
                                        <Select classNamePrefix="custom_select"  className="filter form_select" isClearable={true} isLoading={this.state.loadingQueues} onChange={(e) => { this.handleFilterChange("queue", e ? e.value : undefined) }} options={this.state.queues && this.state.queues.map(queueItem => ({ value: queueItem._id, label: queueItem.name }))}/>
                                    </div>
                                </div>
                            </div>
                            {!this.state.loadingPendingQuestions ? this.state.pendingQuestions.length > 0 ? (
                                <div>
                                    {this.state.pendingQuestions.map(unanswerdQuestion => <PendingQuestionItem key={unanswerdQuestion._id} question={unanswerdQuestion} handleApproveQuestion={this.handleApproveQuestion} handleRejectQuestion={this.handleRejectQuestion} handleEditQuestion={this.handleEditQuestion}/>)}
                                    {this.state.hasMore && <LoadMore onClick={this.handleLoadMorePendingQuestions} loadingMore={this.state.loadingMore}/>}
                                </div>
                            ) : <div>No pending questions</div> : <Preloader/>}
                        </div>
                    </div>
                </PageContainer>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(PendingQuestionsPage);