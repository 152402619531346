const initialState = { 
    chats: [],
    selectedChat: undefined,
    messagesLimit: 10,
    messagesOffset: 10,
    hasMoreMessages: false,
    loadingChats: true,
    internalChatsLimit: 10,
    internalChatsOffset: 10,
    hasMore: false,
    loadingMore: false,
}

export default function internalChat(state = initialState, action){
    switch(action.type){
        case 'INTERNAL_CHATS_SET':
            return {
                ...state,
                chats: action.chats,
                loadingChats: action.loadingChats
            }
        case 'INTERNAL_CHATS_UPDATE':
            return {
                ...state,
                ...action.updates
            }
        case 'SET_SELECTED_CHAT':
            return {
                ...state,
                messagesLimit: initialState.messagesLimit,
                selectedChat: {
                    chat: action.selectedChat,
                    messages: [],
                    loadingMessages: true
                }
            }
        case 'INTERNAL_CHATS_CLEAR':
            return initialState;
        case 'UPDATE_SELECTED_CHAT':
            return {
                ...state,
                selectedChat: {
                    ...state.selectedChat,
                    chat: action.selectedChat,
                }
            }
        case 'SET_CHAT_WIDGET_EXPANDED':
            return {
                ...state,
                chatWidgetExpanded: action.chatWidgetExpanded
            }
        case 'DESELECT_CHAT':
            return {
                ...state,
                selectedChat: undefined
            }
        case 'SET_CURRENT_MESSAGES':
            return {
                ...state,
                selectedChat: {
                    ...state.selectedChat,
                    messages: action.messages,
                    loadingMessages: false
                }
            }
        default: 
            return state;
    }
}