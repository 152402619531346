import moment from "moment";
import { expiryTime } from '../services/misc';

let agent = JSON.parse(localStorage.getItem('user'));
let selectedCompany = localStorage.getItem('selectedCompany') || undefined;
let selectedChatbot = localStorage.getItem('selectedChatbot') ? JSON.parse(localStorage.getItem('selectedChatbot')) : undefined;

if(agent && moment(agent.expiryTime) < moment()){
    agent = undefined;
    localStorage.removeItem('user');
    localStorage.removeItem('selectedChatbot');
    localStorage.removeItem('selectedCompany');
}else{
    if(agent){
        agent.expiryTime = expiryTime();
        localStorage.setItem('user', JSON.stringify(agent))
    }
}

const initialState = agent ? { loggedIn: true, agent, selectedCompany, selectedChatbot, notificationsExpanded: false } : {};

export default function authentication(state = initialState, action){
    switch(action.type){
        case 'AGENT_LOGIN_REQUEST':
            return {
                loggingIn: true,
                agent: action.agent
            }
        case 'AGENT_LOGIN_SUCCESS':
            return {
                loggedIn: true,
                agent: action.agent
            }
        case 'AGENT_PASSWORD_SET_REQUEST':
            return {
                settingPassword: true,
                ...state
            }
        case 'AGENT_PASSWORD_SET_SUCCESS':
            return {
                ...state,
                agent: {
                    ...state.agent,
                    agent: action.agent
                }
            }
        case 'SET_CURRENT_COMPANY':
            return {
                ...state,
                selectedCompany: action.companyId
            }
        case 'SET_NOTIFICATIONS_EXPANDED':
            state.notificationsExpanded = action.notificationsExpanded
            return state;
        case 'SET_CURRENT_CHATBOT':
                return { ...state, selectedChatbot: { ...state.selectedChatbot, ...action.chatbot } };
        case 'SET_CURRENT_CHATBOT_SETTINGS':
            state.selectedChatbot = { ...state.selectedChatbot, settings: { ...state.selectedChatbot.settings, ...action.settings}  }
            localStorage.setItem('selectedChatbot', JSON.stringify(state.selectedChatbot))
            return state;
        case 'SET_CURRENT_AGENT_SET':
        case 'AVATAR_SET':
            state.agent.agent.agent_settings = action.agent.agent_settings
            state.agent.agent.avatar = action.agent.avatar
            state.agent.agent.first_name = action.agent.first_name
            state.agent.agent.last_name = action.agent.last_name
            state.agent.agent.email = action.agent.email
            state.agent.agent.role = action.agent.role
            state.agent.agent._id = action.agent._id
            state.agent.agent.__v = action.agent.__v
            state.agent.agent.companies = action.agent.companies
            return state;
        case 'AGENT_PASSWORD_SET_FAILURE':
        case 'AGENT_LOGOUT':
        case 'AGENT_LOGIN_FAILURE':
            return { }
        default: 
            return state;
    }
}