import React from 'react';
import Modal from 'react-modal';
import UserInfoList from '../Chats/UserInfoList';

export default class LeadInfoModal extends React.Component{
    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="modal modal__preloader">
                <div className="modal__title">All user info</div>
                <UserInfoList conversation={{ user: this.props.user }}/>
                <div className="modal__actions mt-medium">
                    <div className="btn" onClick={(e) => { this.props.onRequestClose() }}>Close</div>
                </div>
            </Modal>
        )
    }
}