import { connect } from 'react-redux';
import React from 'react';
import { agentActions } from '../../actions/agents';
import AlertBox from '../AlertBox';
import Preloader from '../Preloader';
import { NavLink } from 'react-router-dom';

class LoginForm extends React.Component{
    constructor(props){
        super(props);

        this.props.dispatch(agentActions.logout())

        this.state = {
            email: '',
            password: '',
            submitted: false
        };
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }); 
    }

    handleSubmit(e){
        e.preventDefault();

        this.setState({
            submitted: true
        });

        const { email, password } = this.state;
        const { dispatch } = this.props;
        if(email && password){
            dispatch(agentActions.login(email, password));
        }
    }

    render(){
        const { auth, alerts } = this.props;
        const loggingIn = auth.loggingIn;
        return(
            <div className="login_form">
                <h2>Login</h2>
                <form className="login_form__form" name="form" onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" name="email" value={this.state.email} onChange={this.handleChange} autoComplete="username" placeholder="Enter your email address"/>
                        {this.state.submitted && !this.state.email && <div className="help-block">Email is required</div>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Password</label>
                        <input type="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="current-password" placeholder="Enter your password"/>
                        {this.state.submitted && !this.state.password && <div className="help-block">Password is required</div>}
                    </div>
                    <div className="form-group">
                        <button disabled={loggingIn} className="button">{loggingIn ? <Preloader color="white" size="16" padding="0px"/> : 'Log In'}</button>
                        <p>Forgot your password? Click <NavLink to="/reset-password">here</NavLink> to reset it.</p>
                    </div>
                    {alerts.message && <AlertBox type={alerts.type}>{alerts.message}</AlertBox>}
                </form>
            </div>
        );
    }
};

function mapStateToProps(state){
    return state;
}

export default connect(mapStateToProps)(LoginForm);