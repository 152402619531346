import React from 'react';
import { connect } from 'react-redux';
import MenuItem from './MenuItem';
import { agentActions } from '../../actions/agents';
import { HiOutlineXMark,  } from 'react-icons/hi2';
import { HiUserCircle, HiChartBar, HiChatBubbleLeftEllipsis, HiEnvelope, HiEnvelopeOpen, HiUsers, HiExclamationTriangle, HiCog6Tooth  } from 'react-icons/hi2';
import { RiQuestionnaireFill, RiWhatsappFill } from 'react-icons/ri';

class Menu extends React.Component{
    constructor(props){
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout(){
        this.props.dispatch(agentActions.logout());
    }

    render(){
        return (
            <div className={`dashboard_menu ${this.props.mobileMenuOpen ? 'mobile_expanded' : ''}`}>
                <div className="dashboard_menu__top_bar">
                    <div className="dashboard_topbar__menu_trigger" onClick={this.props.mobileMenuTrigger}><HiOutlineXMark/></div>
                    <img className="dashboard_topbar__full_logo" src="/images/logo_full.png" alt="Mobile Logo"></img>
                </div>
                <div className="dashboard_menu__inner_top">
                    <img className="dashboard_menu__logo" src="/images/logo.svg" alt="Verge AI Logo"/>
                    <MenuItem rolesAllowed={['admin', 'manager']} to="/analytics" label="Analytics"><HiChartBar/></MenuItem>
                    <MenuItem rolesAllowed={['admin', 'manager', 'agent']} to="/chats" label="Chats"><HiChatBubbleLeftEllipsis/></MenuItem>
                    <MenuItem rolesAllowed={['admin', 'manager']} to="/leads" label="Leads"><HiUsers/></MenuItem>
                    <MenuItem rolesAllowed={['admin', 'manager', 'agent']} to="/help-requests" label="Help Requests" notificationCount={this.props.helpRequests.helpRequestsCount}>{this.props.helpRequests.helpRequests.filter(helpRequest => !helpRequest.resolved).length === 0 ? <HiEnvelope/> : <HiEnvelopeOpen/>}</MenuItem>
                    {this.props.auth.selectedChatbot.settings.whatsapp_template_sender_enabled && <MenuItem rolesAllowed={['admin', 'manager']} to="/whatsapp-push-notifications" label="WhatsApp Push Notifications"><RiWhatsappFill/></MenuItem>}
                </div>
                <div className="dashboard_menu__inner_bottom">
                    <MenuItem rolesAllowed={['admin']} to="/errors" label="Errors"><HiExclamationTriangle/></MenuItem>
                    <MenuItem rolesAllowed={['admin', 'manager']} to="/unanswered-questions/pending" label="Unanswered Questions"><RiQuestionnaireFill/></MenuItem>
                    <MenuItem rolesAllowed={['admin', 'manager', 'agent']} to="/profile" label="Edit Profile"><HiUserCircle/></MenuItem>
                    <MenuItem rolesAllowed={['admin', 'manager']} to="/settings" label="Settings"><HiCog6Tooth/></MenuItem>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(Menu);