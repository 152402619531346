import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getQueues } from '../../services/queues'
import { agentService } from '../../services/agents'
import { getChannelFilters } from '../../services/conversation'
import { getTags } from '../../services/tags'
import { titleCase, channelLabel } from '../../services/misc'

class ChatSort extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            focusedInput: false,
            loadingQueues: true,
            queueOptions: undefined,
            loadingChannels: true,
            channelOptions: undefined,
            loadingTags: true,
            tagOptions: undefined,            
            loadingAgents: true,
            agentOptions: undefined,
            statusOptions: [
                { value: 'bot', label: 'Bot' },
                { value: 'open', label: 'Open' },
                { value: 'closed', label: 'Closed' }
            ]
        };
    }

    componentDidMount(){
        getQueues(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id).then(queues => {
            this.setState({ loadingQueues: false, queueOptions: queues.map(queue => ({ value: queue._id, label: queue.name })) });
        });
        
        getChannelFilters(this.props.auth.selectedChatbot.token).then(channels => {
            this.setState({ loadingChannels: false,channelOptions: channels.map(channel => ({ value: channel, label: titleCase(channelLabel(channel)) })) });
        })

        getTags(this.props.auth.selectedChatbot.token).then(tags => {
            this.setState({ loadingTags: false, tagOptions: tags.map(tag => ({ value: tag._id, label: tag.label })) });
        });

        agentService.getAgents(this.props.auth.agent.token).then(agents => {
            this.setState({ loadingAgents: false, agentOptions: agents.map(agent => ({ value: agent._id, label: agent.first_name + " " + agent.last_name })) });
        });
    }

    render(){
        return (
            <div className="chats_filter">
                <div className="label_title">Sort by</div>
                <div>
                    <Select classNamePrefix="custom_select"  className="form_select filter" value={{ value: this.props.sortBy, label: this.props.sortBy === 'desc' ? "Newest to oldest" : "Oldest to newest" }} onChange={(e) => { this.props.handleSetSort(e.value) }} options={[
                        { value: 'desc', label: 'Newest to oldest' },
                        { value: 'asc', label: 'Oldest to newest' }
                    ]}/>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ChatSort);