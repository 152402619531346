import React from 'react';
import HelpRequestWidgetItem from './HelpRequestWidgetItem';


export default class HelpRequestWidget extends React.Component{
    render(){
        return (
            <div className="help_request_widget">
                {this.props.helpRequests.map(helpRequestItem => (
                    <HelpRequestWidgetItem key={helpRequestItem._id} helpRequest={helpRequestItem}/>
                ))}
            </div>
        )
    }
}