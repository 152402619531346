import React from 'react';
import { connect } from 'react-redux';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import InternalChatListItem from './InternalChatListItem';
import LoadMore from '../Base/LoadMore';
import { searchAgentChats } from '../../services/internalChats';
import Preloader from '../Preloader';

class InternalChatSearchResults extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            searchTerm: this.props.searchTerm,
            loadingResults: true,
            searchResults: [],
            loadingMore: false,
            hasMore: false,
            searchLimit: 20,
            searchOffset: 20

        }

        this.handleSearch = this.handleSearch.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
    }

    componentDidMount(){
        this.setState({ loadingResults: true });
        this.handleSearch();
    }

    componentDidUpdate(prevProps){
        if(prevProps.searchTerm !== this.props.searchTerm){
            this.setState({ loadingResults: true });
            this.setState({ searchTerm: this.props.searchTerm }, () => {
                this.handleSearch();
            });
        }
    }

    async handleSearch(){
        const chats = await searchAgentChats(this.props.auth.agent.agent._id, this.state.searchTerm, this.props.auth.selectedChatbot.token, this.state.searchLimit + 1);
        this.setState({ hasMore: chats.length > this.state.searchLimit });
        
        if(chats.length > this.state.searchLimit){
            chats.pop()
        }
        
        this.setState({ loadingResults: false, searchResults: chats })
    }

    async handleLoadMore(){
        this.setState(prevState => ({ searchLimit: prevState.searchLimit + prevState.searchOffset, loadingMore: true }), async () => {
            await this.handleSearch();
            this.setState({ loadingMore: false })
        });
    }

    render(){
        return (
            <div className="internal_chats_list">
                {!this.state.loadingResults ? (
                    this.state.searchResults.length > 0 ? (
                        <div>
                        {this.state.searchResults.map(internalChat => <InternalChatListItem key={internalChat._id} handleSelectChat={this.props.handleSelectChat} chat={internalChat} currentAgent={this.props.auth.agent.agent}/>)}
                        {this.state.hasMore && <LoadMore onClick={this.handleLoadMore} loadingMore={this.state.loadingMore}/>}
                        </div>
                    ) : (
                        <div className="internal_chats_list__message_badge">
                            <HiChatBubbleLeftRight className="internal_chats_list__message_badge__icon"/> 
                            No results
                        </div>
                    )
                ) : <Preloader/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalChatSearchResults);