import React from 'react';
import { connect } from 'react-redux';
import { HiOutlineChatBubbleLeftEllipsis, HiOutlineEye, HiOutlineEyeSlash, HiOutlineTrash } from 'react-icons/hi2';
import { toTimestamp } from '../../services/dates';
import { titleCase } from '../../services/misc';
import { goToConversation } from '../../actions/chats';

class ErrorItem extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            traceShown: false,
            deleting: false
        };

        this.handleStackTrace = this.handleStackTrace.bind(this);
        this.handleViewChatClick = this.handleViewChatClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    handleStackTrace(){
        this.setState(prevState => ({ traceShown: !prevState.traceShown }))
    }

    async handleViewChatClick(){
        const conversationId = this.props.error.conversation_id;
        await goToConversation(this.props, conversationId);
    }

    handleDeleteClick(){
        this.setState({ deleting: true })
        this.props.handleDelete(this.props.error._id);
    }

    render(){
        return (
            <div className="error_item">
                <div className="error_details">
                    <label>Message</label>
                    <div className="error_message mb-medium">{this.props.error.message}</div>
                    <div className="error_item__actions">
                        {this.state.traceShown ? <button className="btn" onClick={this.handleStackTrace}><HiOutlineEyeSlash/>Hide Trace</button> : <button className="btn" onClick={this.handleStackTrace}><HiOutlineEye/>Show Trace</button>} 
                        <button className="btn" onClick={this.handleDeleteClick} disabled={this.state.deleting}><HiOutlineTrash/><span>{this.state.deleting ? 'Deleting...' : 'Delete'}</span></button>
                    </div>

                    {this.state.traceShown && <div className="error_trace"><label>Stack Trace</label>{this.props.error.trace}</div>}
                </div>
                <div className="error_meta">
                    <div className="error_meta_item">
                        <label>Created At</label>
                        {toTimestamp(this.props.error.createdAt)}
                    </div>
                    <div className="error_meta_item">
                        <label>Type</label>
                        {titleCase(this.props.error.type)} Error
                    </div>
                    {this.props.error.conversation_id && (
                        <div className="error_meta_item">
                            <label>Conversation</label>
                            {this.props.error.conversation_id}                                    
                            <button className="btn mt-medium" onClick={this.handleViewChatClick}><HiOutlineChatBubbleLeftEllipsis/><span>View Chat</span></button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(ErrorItem);