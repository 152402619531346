import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import AgentList from '../Agents/AgentList';
import { companyService } from '../../services/company';
import { firstBy } from "thenby";
import Select from 'react-select';

class EditQueueModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            queue: this.props.queue,
            allAgents: [],
            loadingAgents: true
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAgentChange = this.handleAgentChange.bind(this);
        this.handleAgentListSelect = this.handleAgentListSelect.bind(this);
        this.loadAgents = this.loadAgents.bind(this);
    }


    componentDidMount(){
        this.loadAgents();
    }

    componentDidUpdate(prevProps){
        if(prevProps.queue !== this.props.queue){
            this.setState({ queue: this.props.queue })
        }
    }

    handleInputChange(e){
        this.setState(prevState => ({ queue: { ...prevState.queue, [e.target.name]: e.target.value } }))
    }

    async loadAgents(){
        let agents = await companyService.getCompanyAgents(this.props.auth.selectedCompany, this.props.auth.agent.token);

        agents = agents.sort(firstBy("disabled").thenBy((a, b) => {
            if(a.agent_settings.status === "offline"){ return  1 }
            if(b.agent_settings.status === "offline"){ return  -1 }
        }).thenBy("first_name"));
        this.setState({ allAgents: agents, loadingAgents: false })
    }

    handleAgentChange(e){
        this.setState(prevState => ({ queue: { ...prevState.queue, agents: e.map(element => element.value) } }))
    }

    handleAgentListSelect(agent, isSelected){
        if(isSelected){
            this.setState(prevState => ({ queue: { ...prevState.queue, agents: prevState.queue.agents.filter(queueAgent => queueAgent._id !== agent._id ) } }))
        }else{            
            this.setState(prevState => ({ queue: { ...prevState.queue, agents: [...prevState.queue.agents, agent] } }))
        }
    }
    
    render(){
        return (
            <Modal ariaHideApp={false} isOpen={this.props.isOpen} onRequestClose={() => { this.props.onRequestClose() }} className="action_modal modal__preloader">
                <div className="modal__title">{!this.state.queue._id ? 'New' : 'Edit'} Queue</div>
                <div className="form-group">
                    <label>Name</label>
                    <input value={this.state.queue.name} onChange={this.handleInputChange} type="text" name="name" placeholder="Enter queue name"/>
                </div>
                <div className="form-group">
                    <label>Agents in queue</label>
                    <Select classNamePrefix="custom_select"  className="form_select" onChange={this.handleAgentChange} value={this.state.queue.agents.map(agent => ({ value: agent, label: agent.first_name + ' ' + agent.last_name }))} isMulti options={this.state.allAgents.map(agent => ({ value: agent, label: agent.first_name + ' ' + agent.last_name }))}/>
                </div>
                <AgentList loadingAgents={this.state.loadingAgents} isSelectList={true} selectedAgents={this.state.queue.agents} onSelect={this.handleAgentListSelect} loadAgents={this.props.loadQueues} agents={this.state.allAgents} queues={this.props.queues} currentAgent={this.props.auth.agent.agent}/>
                <div className="modal__actions mt-medium">
                    <div className="btn" onClick={(e) => { this.props.onRequestClose() }}>Cancel</div>
                    <button className="btn" disabled={this.state.queue.name.trim().length === 0} onClick={() => { this.props.onConfirm(this.state.queue) }}>Save</button>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(EditQueueModal);