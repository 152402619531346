import UserInfoList from "../Chats/UserInfoList";
import Widget from '../Chats/Widget';
import { HiOutlineCheck, HiCheckCircle, HiOutlineChatBubbleLeftEllipsis, HiOutlineTrash } from 'react-icons/hi2'
import QuickMessage from "../Chats/QuickMessage";
import MessageMedia from "../Chats/MessageMedia";
import AgentProfileAvatar from '../Agents/AgentProfileAvatar';
import QueueBadge from '../Base/QueueBadge';
import { toTimestamp } from '../../services/dates';
import React from "react";
import Preloader from "../Preloader";
import ConfirmModal from "../Base/ConfirmModal";
import { BsArrowLeftRight } from 'react-icons/bs'
import ChatTransfer from "../Chats/ChatTransfer";
import moment from "moment";
import { history } from '../../helpers/history';

class HelpRequestListItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            resolving: false,
            deleting: false,
            deletePromptShown: false,
            transferOpen: false,
            confirmResolve: false,
            confirmQuickReply: false,
            loadingConversation: false
        };

        this.handleResolveClick =  this.handleResolveClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleHelpRequestDeleteModalTrigger = this.handleHelpRequestDeleteModalTrigger.bind(this);
        this.onSendQuickReply = this.onSendQuickReply.bind(this);
        this.handleTransferTrigger = this.handleTransferTrigger.bind(this);
        this.handleTransfer = this.handleTransfer.bind(this);
        this.handleConfirmResolve = this.handleConfirmResolve.bind(this);
        this.handleViewChatClick = this.handleViewChatClick.bind(this);
    }

    handleConfirmResolve(){
        this.setState(prevState => ({ confirmResolve: !prevState.confirmResolve }))
    }

    async handleResolveClick(helpRequestId){
        this.setState({ resolving: true, confirmResolve: false });
        await this.props.handleResolveHelpRequest(helpRequestId);
        this.setState({ resolving: false });
    }

    async handleDeleteClick(helpRequestId){
        this.setState({ deleting: true, deletePromptShown: false });
        await this.props.handleDeleteHelpRequest(helpRequestId);
        history.push('/help-requests');
        this.setState({ deleting: false });
    }

    handleHelpRequestDeleteModalTrigger(){
        this.setState(prevState => ({ deletePromptShown: !prevState.deletePromptShown }))
    }

    handleTransferTrigger(){
        this.setState(prevState => ({ transferOpen: !prevState.transferOpen }))
    }

    async onSendQuickReply(message, conversation){
        await this.props.handleSendQuickReply(this.props.helpRequest._id, message, conversation);
    }

    async handleTransfer(queueId){
        await this.props.handleHelpRequestTransfer(this.props.helpRequest._id, queueId);
    }

    handleViewChatClick(){
        this.setState({ loadingConversation: true });
        this.props.handleViewChatClick(this.props.helpRequest.conversation)
    }

    render(){
        const helpRequest = this.props.helpRequest;
        const inSession = (moment(helpRequest.createdAt) > moment().subtract(23.5, 'hours')) ? true : false;
        return (
            <div className={`help_request_item ${this.props.helpRequest.resolved ? 'resolved' : ''}`}>
                <div className="help_request_item_container">
                    {helpRequest.message_data && (
                        <div className="help_request_info">
                            <div className="help_request_info__content">
                                <div className="help_request_info__title">{helpRequest.conversation && helpRequest.conversation.user && helpRequest.conversation.user.user_info && helpRequest.conversation.user.user_info.name ? helpRequest.conversation.user.user_info.name : (helpRequest.conversation ? helpRequest.conversation.conversation_id : 'No ID Found') }</div> <div className="timestamp">Date created: {toTimestamp(helpRequest.createdAt)}</div><QueueBadge queue={helpRequest.queue}/>
                                {this.props.helpRequest.message_data.text && <div className="help_request_info__text"><label>User asked:</label>{this.props.helpRequest.message_data.text}</div>}
                                {this.props.helpRequest.message_data.media && this.props.helpRequest.message_data.media.length > 0 && (
                                    <MessageMedia thumbnail={true} media={this.props.helpRequest.message_data.media[0]} handleMediaClick={this.props.handleMediaClick}/>
                                )}
                                
                                {(!helpRequest.resolved && inSession && helpRequest.conversation) && <QuickMessage onSend={this.onSendQuickReply} label="Quick reply" helpRequest={this.props.helpRequest} placeholder="Enter your answer" conversation={helpRequest.conversation}/>}
                            </div>                        
                            <div className="help_request_actions">
                                <div className="help_request_actions_container">
                                    {!helpRequest.resolved && <button disabled={this.state.resolving} className="btn" onClick={this.handleConfirmResolve}>{!this.state.resolving ? <div className="flex-middle"><HiOutlineCheck/><span>Mark as Resolved</span></div> : <div className="flex-middle">Resolving <Preloader padding="0 0.3rem" size="10"/></div>}</button>}
                                    {!helpRequest.resolved && <button className="btn" onClick={this.handleTransferTrigger}><BsArrowLeftRight/><span>Transfer</span></button>}
                                    {helpRequest.conversation && <button disabled={this.state.loadingConversation} className="btn" onClick={this.handleViewChatClick}><HiOutlineChatBubbleLeftEllipsis/><span>{this.state.loadingConversation ? 'Loading chat...' : 'View Chat'}</span></button>}
                                    {this.state.transferOpen && <ChatTransfer queuePicker={true} title="Transfer help request" allowOffline={true} actionText="Transferring" onSelect={this.handleTransfer} handleTransferToggle={this.handleTransferTrigger} conversation={helpRequest.conversation}/>}
                                </div>
                                <button className="btn flex_end" disabled={this.state.deleting} onClick={this.handleHelpRequestDeleteModalTrigger}>{!this.state.deleting ? <div className="flex-middle"><HiOutlineTrash/></div> : <div className="flex-middle">Deleting <Preloader padding="0 0.3rem" size="10"/></div>}</button>
                            </div> 
                            {helpRequest.resolved && ( 
                                <div className="resolved_by">
                                    <div className="resolved_by__title"><HiCheckCircle/> Resolved by</div>
                                    <div className="resolved_by_inner p-medium">
                                        <div className="flex-middle pb-medium">
                                            {helpRequest.resolved.agent && <AgentProfileAvatar popupEnabled={true} agent={helpRequest.resolved.agent}/>} 
                                            <div className="resolved_by__details">
                                                {helpRequest.resolved.agent ? <div className="resolved_by__details__name">{helpRequest.resolved.agent.first_name[0]}. {helpRequest.resolved.agent.last_name}</div> : <div className="resolved_by__details__name">Agent not found</div>}
                                                <div className="timestamp">Date resolved: {toTimestamp(helpRequest.resolved.updatedAt)}</div>
                                            </div>                                              
                                        </div>
                                        {(this.props.helpRequest.resolved && this.props.helpRequest.resolved.comment && (this.props.helpRequest.resolved.comment.text || this.props.helpRequest.resolved.comment.media.length > 0)) && <div className="help_request_info__text"><label>Resolving agent said:</label>{this.props.helpRequest.resolved.comment.media.length > 0 && <MessageMedia thumbnail={true} media={this.props.helpRequest.resolved.comment.media[0]} handleMediaClick={this.props.handleMediaClick}/>}{this.props.helpRequest.resolved.comment.text}</div>}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {helpRequest.conversation && <Widget title="User Info" component={(
                    <div>
                        <UserInfoList conversation={helpRequest.conversation} maxItems={4}/>
                    </div>
                )}/>}
                <ConfirmModal confirmText="Mark as Resolved" title="Resolve Help Request" description="Please note you are about to mark this help request as resolved. If the text bar is still active, it will no longer be available." isOpen={this.state.confirmResolve} onConfirm={() => { this.handleResolveClick(helpRequest._id) }} onRequestClose={this.handleConfirmResolve}/>
                <ConfirmModal confirmText="Delete" title="Delete Help Request" description="Please note you are about to delete this help request. This action cannot be undone." onRequestClose={this.handleHelpRequestDeleteModalTrigger} isOpen={this.state.deletePromptShown} onConfirm={() => { this.handleDeleteClick(helpRequest._id) }}/>
            </div>
        );
    }
};

export default HelpRequestListItem;