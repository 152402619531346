import React from "react";
import Preloader from '../Preloader';
import { connect } from 'react-redux';
import { getQueues } from '../../services/queues';
import { transferBotChat, transfer } from '../../services/humanHandoff';
import { companyService } from '../../services/company';
import ChatTransferList from "./ChatTransferList";
import ChatTransferSearch from "./ChatTransferSearch";
import onClickOutside from "react-onclickoutside";

class ChatTransfer extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            queues: [],
            agents: [],
            loadingQueues: true,
            showing: this.props.agentPicker ? 'agent' : 'queues',
            searchTerm: '',
            transfering: false,
            actionPerforming: false
        };

        this.handleSwitchViewing = this.handleSwitchViewing.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleTransfer = this.handleTransfer.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }

    componentDidMount(){
        getQueues(this.props.auth.selectedChatbot.token).then(queues => {
            companyService.getCompanyAgents(this.props.auth.selectedCompany, this.props.auth.agent.token).then(agents => {
                this.setState({
                    queues,
                    agents,
                    loadingQueues: false
                });
            });
        });
    }

    handleSearchChange(e){
        this.setState({ searchTerm: e.target.value.toLowerCase().trim() });
    }

    handleSwitchViewing(){
        this.setState(prevState => ({
            showing: prevState.showing === 'queues' ? 'agent' : 'queues'
        }));
    }
    
    handleClickOutside = evt => {
        this.props.handleTransferToggle()
    };

    async handleTransfer(id, type){
        this.setState({ transfering: true })
        await transfer(this.props.auth.selectedChatbot.token, this.props.conversation.conversation_id, type, id, this.props.auth.agent.agent._id);
        await transferBotChat(this.props.auth.selectedChatbot.token, this.props.conversation.conversation_id, this.props.conversation.channel);
        this.setState({ transfering: false })
        this.props.handleTransferToggle()
    }

    async handleAction(id){
        this.setState({ actionPerforming: true })
        await this.props.onSelect(id);
        this.setState({ actionPerforming: false })
        this.props.handleTransferToggle()
    }
    
    render(){
        return (
            <div className="chat_transfer">
                <div className="widget_title">{this.props.agentPicker || this.props.queuePicker ? this.props.title : 'Transfer'}</div>
                <div className="chat_transfer__inner">
                    {!this.state.loadingQueues ? !this.state.transfering && !this.state.actionPerforming ? (
                        <div>
                            <ChatTransferSearch agentPicker={this.props.agentPicker} handleSearchChange={this.handleSearchChange} searchTerm={this.state.searchTerm}/>
                            <ChatTransferList conversation={this.props.conversation} currentAgent={this.props.auth.agent.agent} allowOffline={this.props.allowOffline} queuePicker={this.props.queuePicker} agentPicker={this.props.agentPicker} handleTransfer={this.props.agentPicker || this.props.queuePicker ? this.handleAction : this.handleTransfer} searchTerm={this.state.searchTerm} handleSwitchViewing={this.handleSwitchViewing} showing={this.state.showing} agents={this.state.agents} queues={this.state.queues}/>
                        </div>
                    ) : <Preloader padding="2rem 0px" text={this.state.actionPerforming ? this.props.actionText : 'Transferring'}/> : <Preloader padding="2rem 0px"/>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(onClickOutside(ChatTransfer));