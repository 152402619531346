import React from 'react';
import { connect } from 'react-redux';
import { agentActions } from '../actions/agents';
import { agentService } from '../services/agents';
import CompanyListItem from './CompanyListItem';
import Preloader from './Preloader';
 
class CompanyList extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            companies: undefined,
            loadingCompanies: true,
            selectedCompanyItem: undefined
        };

        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);
    }

    componentDidMount(){
        agentService.getAgentCompanies(this.props.auth.agent.agent._id, this.props.auth.agent.token).then((companies) => {
            this.setState({
                companies,
                loadingCompanies: false
            });
        });
    }

    handleOnClick(companyId){
        this.setState({
            selectedCompanyItem: companyId
        });
    }

    handleOnSelect(){
        this.props.dispatch(agentActions.setCurrentCompany(this.state.selectedCompanyItem));
    }

    render(){
        return (
            <div>
                {this.state.loadingCompanies ? <Preloader/> : (
                    <div>
                        {this.state.companies && this.state.companies.length > 0 ? (
                            <div className="company_list">
                                <div className="company_list__inner">
                                {this.state.companies.map((company) => {
                                    return <CompanyListItem key={company._id} selectedCompanyItem={this.state.selectedCompanyItem} onClick={this.handleOnClick} company={company}/>
                                })}
                                </div>
                                <button onClick={this.handleOnSelect} className="button" disabled={!this.state.selectedCompanyItem}>Select Portal</button>
                            </div>
                            ) : <p>No companies found</p>}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(CompanyList);