import React from 'react';
import { connect } from 'react-redux';
import Preloader from '../Preloader';
import { getQuickReplies } from '../../services/quickReplies'
import QuickReplyListItem from './QuickReplyListItem';
import EditQuickReplyModal from './EditQuickReplyModal';
import ConfirmModal from '../Base/ConfirmModal'
import { updateQuickReplies, deleteQuickReply, newQuickReplies } from '../../services/quickReplies';
import LoadingModal from '../Base/LoadingModal';
import { HiPlusCircle } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';
import { HiOutlineBolt } from 'react-icons/hi2';

class QuickReplyList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            loadingQuickReplies: true,
            quickReplies: [],
            filteredQuickReplies: [],
            selectedQuickReply: undefined,
            quickReplyToDelete: undefined,
            deletingQuickReply: false
        };

        this.onEditClick = this.onEditClick.bind(this);
        this.saveQuickReply = this.saveQuickReply.bind(this);
        this.handleDeleteQuickReplyToggle = this.handleDeleteQuickReplyToggle.bind(this);
        this.handleDeleteQuickReply = this.handleDeleteQuickReply.bind(this);
    }

    async componentDidMount(){
        await this.loadQuickReplies()
    }

    async loadQuickReplies(){
        const quickReplies = await getQuickReplies(this.props.auth.selectedChatbot.token, this.props.auth.agent.agent._id);

        this.setState({ loadingQuickReplies: false, quickReplies });
    }

    componentDidUpdate(prevProps){
        if(prevProps.messageValue !== this.props.messageValue){
            if(this.props.messageValue && this.props.messageValue.length > 0){
                let quickReplies = this.state.quickReplies;
                quickReplies = quickReplies.filter(quickReply => {
                    let filter = false;
    
                    if(quickReply.message_data.text && quickReply.message_data.text.toLowerCase().includes(this.props.messageValue.toLowerCase()) ){ filter = true }
    
                    if(quickReply.message_data.media && quickReply.message_data.media.length > 0 && quickReply.message_data.media[0].name.toLowerCase().includes(this.props.messageValue.toLowerCase()) ){ filter = true }
                    return filter;
                });

                this.setState({ filteredQuickReplies: quickReplies });
            }
        }
    }

    async saveQuickReply(quickReplyId, body){
        if(quickReplyId){
            await updateQuickReplies(this.props.auth.selectedChatbot.token, quickReplyId, body);
        }else{
            await newQuickReplies(this.props.auth.selectedChatbot.token, {...body, owner: this.props.auth.agent.agent._id });
        }
        await this.loadQuickReplies();
    }

    onEditClick(quickReply){
        this.setState({ selectedQuickReply: quickReply })
    }

    handleDeleteQuickReplyToggle(quickReply){
        this.setState({ quickReplyToDelete: quickReply })
    }

    async handleDeleteQuickReply(){
        this.setState({ deletingQuickReply: true, quickReplyToDelete: undefined });
        await deleteQuickReply(this.props.auth.selectedChatbot.token, this.state.quickReplyToDelete._id);
        await this.loadQuickReplies();
        this.setState({ deletingQuickReply: false });
    }

    render(){
        const quickReplies = this.props.messageValue.trim().length > 0 ? this.state.filteredQuickReplies : this.state.quickReplies;
        return (
            <div className="quick_replies">
                {!this.props.editable && <div className="quick_reply_list__title">Quick Replies <NavLink to="/profile" className="btn"><HiOutlineBolt/> Manage Quick Replies</NavLink></div>}
                {(this.props.editable && !this.state.loadingQuickReplies) && (
                    <div className="quick_replies_top_bar">
                        {this.props.showSearch && <input type="text" autoComplete="off" onChange={this.props.handleSearchChange} value={this.props.messageValue} placeholder="Type to search"/>}
                        {this.props.editable && <button onClick={() => { this.onEditClick({ private: true }) }} className="btn"><HiPlusCircle/> New Quick Reply</button>}
                    </div>
                )}
                {!this.state.loadingQuickReplies ? (quickReplies.length > 0 ? (
                    <div className="quick_reply_list">{quickReplies.map(quickReply => <QuickReplyListItem editable={this.props.editable} key={quickReply._id} handleDeleteQuickReplyToggle={this.handleDeleteQuickReplyToggle} currentAgent={this.props.auth.agent.agent} currentConversation={this.props.currentConversation} onSelect={this.props.onSelect} onEdit={this.onEditClick} quickReply={quickReply}/>)}</div>
                ) : <div className="quick_reply_list__error">No quick replies found</div>) : <Preloader/>}
                {this.state.selectedQuickReply && <EditQuickReplyModal saveQuickReply={this.saveQuickReply} isOpen={this.state.selectedQuickReply ? true : false} toggleEdit={() => { this.onEditClick() }} quickReply={this.state.selectedQuickReply}/>}
                {this.state.quickReplyToDelete && <ConfirmModal onConfirm={this.handleDeleteQuickReply} onRequestClose={() => { this.handleDeleteQuickReplyToggle() }} isOpen={this.state.quickReplyToDelete ? true : false} confirmText="Delete" title="Delete Quick Reply" description={<span>You are about to delete this quick reply permantly. Please note this action cannot be undone.<br/><br/>Are you sure?</span>}/>}
                {this.state.deletingQuickReply && <LoadingModal isOpen={this.state.deletingQuickReply} text="Deleting quick reply"/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(QuickReplyList);