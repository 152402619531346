import React from 'react';
import { connect } from 'react-redux';
import { HiChatBubbleLeftRight } from 'react-icons/hi2';
import InternalChatWidget from './InternalChatWidget';
import InternalNotifications from './InternalNotifications';

class InternalChat extends React.Component{
    constructor(props){
        super(props);
        
        this.handleToggleChat = this.handleToggleChat.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);
    }

    handleToggleChat(){
        this.props.dispatch({
            type: 'SET_CHAT_WIDGET_EXPANDED',
            chatWidgetExpanded: !this.props.internalChats.chatWidgetExpanded
        });
    }

    handleNotificationClick(chat){
        this.props.dispatch({
            type: 'SET_SELECTED_CHAT',
            selectedChat: chat
        });

        this.handleToggleChat();
    }

    render(){
        const chats = this.props.internalChats.chats || [];
        const currentAgent = this.props.auth.agent.agent;
        let unread = 0;
        chats.forEach(chat => {
            const notification = chat.notifications.find(notification => notification.agent.toString() === currentAgent._id.toString());
            if(notification && notification.unread){ unread = unread + notification.messages.length; }
        })
        return (
            <div className="internal_chat">
                {!this.props.internalChats.chatWidgetExpanded && <InternalNotifications handleNotificationClick={this.handleNotificationClick}/>}
                {!this.props.internalChats.chatWidgetExpanded && <div className="internal_chat_trigger" onClick={this.handleToggleChat}><HiChatBubbleLeftRight/> Internal Chat {unread > 0 && <div className="notification_badge">{unread}</div>}</div>}
                {this.props.internalChats.chatWidgetExpanded && <InternalChatWidget handleToggleChat={this.handleToggleChat}/>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps)(InternalChat);