import moment from 'moment';
import React from 'react';
import AgentHistoryItem from './AgentHistoryItem';

export default class AgentHistory extends React.Component{
    render(){
        const agentsConnected = this.props.conversation.agents_connected || [];
        let agentHistories = this.props.conversation.agent_history.map((agentHistory) => {
            return agentHistory;
        });

        agentHistories = agentHistories.filter(agentHistory => !agentsConnected.find(agentConnected => agentConnected._id === agentHistory.agent._id))

        let agentHistoriesCombined = [];
        agentHistories.forEach(agentHistory => {
            const combinedAgentHistoryAdded = agentHistoriesCombined.find((combinedAgentHistoryAddedElement) => combinedAgentHistoryAddedElement.id === agentHistory.agent._id );
            if(!combinedAgentHistoryAdded){
                let combinedAgent = agentHistories.filter((agentHistoryChild) => agentHistory.agent._id === agentHistoryChild.agent._id);
                agentHistoriesCombined.push({
                    id: agentHistory.agent._id,
                    combinedAgentHistory: combinedAgent
                });
            }
        });

        agentHistoriesCombined = agentHistoriesCombined.sort((a, b) => {
            const aRecent = a.combinedAgentHistory.reduce((aItem, bItem) => {
                return moment(aItem.MeasureDate).unix() > moment(bItem.MeasureDate).unix() ? aItem : bItem;
            });

            const bRecent = b.combinedAgentHistory.reduce((aItem, bItem) => {
                return moment(aItem.MeasureDate).unix() > moment(bItem.MeasureDate).unix() ? aItem : bItem;
            });

            return moment(bRecent.createdAt).unix() - moment(aRecent.createdAt).unix();
        })

        return(
            <div className="agent_history">
                {agentsConnected.length > 0 && (
                    <div className="agent_history_list">
                        {agentsConnected.map((agent, index) => {
                            return <AgentHistoryItem key={index} connected={true} agent={agent}/>
                        })}
                    </div>
                )}
                <div className="agent_history_list">
                    {agentHistoriesCombined.length > 0 && <div className="widget_title__inner">Agent History</div>}
                    {agentHistoriesCombined.map((agentHistory, index) => {
                        return <AgentHistoryItem key={index} combinedAgentHistory={agentHistory.combinedAgentHistory}/>
                    })}
                </div>
            </div>
        );
    }
}